import React from 'react';
import Banner from './Banner';
import BannerImg from '../../img/management/alert_banner.jpg';
import alertSystem from '../../img/management/alertSystem.gif';
import caseArray from './caseArray';
import alert4 from '../../img/management/alert4.png';
import BannerCase from './BannerCases';


const CaseManagements = () => {
  return (
    <>
      <Banner
        img={BannerImg}
        title={
          <div className='text-center'>
            Cyber Prevention <br /> Crowd Alert System
          </div>
        }
      />
      <div className="my-5 d-flex flex-column justify-content-center align-items-center px-5" >
        <div>
          <h2 className='text-center'> Cyber Prevention <br /> Crowd Alert System</h2>
        </div>
        <div>
          <img src={alertSystem} alt="" style={{ width: '95%' }} />
        </div>
      </div>
      <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3">
        {caseArray.map((caseManagements, index) => (
          <div key={index} className="d-flex flex-column justify-content-center align-items-center px-5 my-5">
            {index % 2 === 0 ?
              <div>
                <div className='mb-3 text-center'>
                  <h2>{caseManagements.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-between gap-3 align-items-center">
                  <div className='w-50 px-3'>
                    <p className='column_2'>{caseManagements.desc}</p>
                  </div>
                  <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={caseManagements.img} className='w-85' alt={caseManagements.title} />
                  </div>
                </div>
            </div> : 
            <div>
                <div className='my-4 text-center'>
                  <h2>{caseManagements.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-center align-items-center">
                <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={caseManagements.img} className='w-85' alt={caseManagements.title} />
                </div>
                <div className='w-50 px-3'>
                    <p className='column_2'>{caseManagements.desc}</p>
                </div>
                </div>
            </div>}
            {index === 2 && <BannerCase img={alert4} />} 
          </div>

        ))}
      </div>
    </>
  )
}

export default CaseManagements;
