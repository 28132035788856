import React, { useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import 'swiper/swiper.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function PDFViewers() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedLink = require(`../docs/${searchParams.get('link').split('/').pop()}`);
  const receivedTitle = searchParams.get('title');
  let adobeDCView
  
    useEffect(() => {
      document.addEventListener("adobe_dc_view_sdk.ready", function()
      {
         adobeDCView = new window.AdobeDC.View({
          clientId: 'c5ccdecf223a404d91d140a08f8690d9',
          divId: 'adobe-dc-view',
        });
  
        adobeDCView.previewFile(
          {
            content: { location: { url: receivedLink } },
            metaData: { fileName: receivedTitle },
          },
          { showAnnotationTools: false, showDownloadPDF: false, showPrintPDF: false }
        );
      })

      const alreadyLoaded = localStorage.getItem('alreadyLoaded');
      if (!alreadyLoaded) {
         localStorage.setItem('alreadyLoaded', true);
         window.location.reload(false);
      }
      localStorage.setItem('alreadyLoaded', false);
  },[]);

  onbeforeunload = function() {
    localStorage.removeItem('alreadyLoaded'); return '';
};
  return (
    <>
      <div style={{ height: '100vh' }} id="adobe-dc-view"></div>
    </>
  );
}

export default PDFViewers;
