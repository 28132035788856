import React from 'react'
import Sidebar from '../Banking/BankingPages/SideMenu'
import Banner from './Banner'
import Content from './Content'
import { Helmet } from 'react-helmet';
import {useLocation} from 'react-router-dom';
import Risk1 from '../../img/riskCompliance/risk1.jpg';
import Risk2 from '../../img/riskCompliance/risk2.jpg';
import Risk3 from '../../img/riskCompliance/risk3.jpg';
import Risk5 from '../../img/riskCompliance/risk5.jpg';
import Risk6 from '../../img/riskCompliance/risk6.jpg';
import Risk7 from '../../img/riskCompliance/risk7.jpg';
import Risk8 from '../../img/riskCompliance/risk8.jpg';
import Risk9 from '../../img/riskCompliance/risk9.jpg';
import Ifrs9Array from './content/ifrs9'
import AmlArray from './content/Aml/aml'
import InFraudArray from './content/fraud'
import taxArray from './content/taxCompliance'
import baselArray from './content/basel'
import baselCapitalArray from './content/baselCapital'
import liquidityArray from './content/liquidity'
import grcArray from './content/grc'
import enterpriseArray from './content/enterprise'
import itArray from './content/it'
import operationArray from './content/operation'

const Feature = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('variable');
    let title, BannerImg, BannerDesc, RiskArray, overview, demo, pdf, fact, paper;    

    switch(receivedVariable){
        case 'IFRS9':
            title = "in'IFRS9"
            BannerImg = Risk1
            BannerDesc = "“in'IFRS 9 will accommodate you to cope with the new tsunami of Compliance, Regulations and Risk Management in 2018”"
            RiskArray = Ifrs9Array
            overview = 'yes'
            demo = 'yes'
            pdf = 'yes'
            fact = 'yes'
            paper = 'yes'
        break;

        case 'Aml':
            title = "in'Aml"
            BannerImg = Risk2
            BannerDesc = "“Big data has not only changed the way that banks address AML, but has also heightened the expectations of the regulators.”"
            RiskArray = AmlArray
            overview = 'yes'
            demo = 'yes'
            pdf = 'yes'
            fact = 'yes'
            paper = 'yes'
        break;

        case 'infraud':
            title = "in'Fraud"
            BannerImg = Risk3
            BannerDesc = "“Rapidly detect and prevent complex fraud schemes to minimize losses, maximize customer trust and reduce reputational risk”"
            RiskArray = InFraudArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'yes'
            paper = 'yes'
        break;

        case 'taxcompliance':
            title = "in'Tax Compliance"
            BannerImg = Risk5
            BannerDesc = "“in' Foreign Account Tax Compliance Act (FATCA) Management”"
            RiskArray = taxArray
            overview = 'no'
            demo = 'yes'
            pdf = 'no'
            fact = 'yes'
            paper = 'no'
        break;

        case 'basel':
            title = "in'Basel III"
            BannerImg = Risk6
            BannerDesc = "in'Basel III solution enables quick compliance covering Basel III guidelines on capital ratios, capital buffer, credit valuation adjustment liquidity and leverage ratios"
            RiskArray = baselArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'baselregulatory':
            title = "in'Basel Regulatory Capital"
            BannerImg = Risk7
            BannerDesc = "in'Basel III solution enables quick compliance covering Basel III guidelines on capital ratios, capital buffer, credit valuation adjustment liquidity and leverage ratios"
            RiskArray = baselCapitalArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'liquidityriskmanagement':
            title = "in'Liquidity Risk Management"
            BannerImg = Risk7
            BannerDesc = ""
            RiskArray = liquidityArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'riskandbusinessprocessconsulting':
            title = "in'GRC - Risk & Business Process"
            BannerImg = Risk9
            BannerDesc = ""
            RiskArray = grcArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'enterpriseincidentmanagement':
            title = "in'Enterprise Incident Management"
            BannerImg = Risk8
            BannerDesc = ""
            RiskArray = enterpriseArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'operationalrisk':
            title = "in'Operational Risk"
            BannerImg = Risk8
            BannerDesc = "in'Operational Risk, a simple to use, comprehensive out-of the-box solution helps financial institutions quickly adopt and practice effective enterprise GRC"
            RiskArray = operationArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;

        case 'itgovernance':
            title = "in'IT Governance"
            BannerImg = Risk9
            BannerDesc = "in'IT Governance offers an advanced and comprehensive IT GRC software solution for streamlining IT GRC processes, effectively managing IT risk, and meeting IT regulatory requirements."
            RiskArray = itArray
            overview = 'no'
            demo = 'no'
            pdf = 'no'
            fact = 'no'
            paper = 'no'
        break;
        default:
        break;
        }

        // const formattedPage = receivedVariable.replace(/-/g, ''); // Remove dashes from the page variable
        // const formattedUrl = `/banking/riskandcompliance/${formattedPage}`;
  
        // window.history.replaceState(null, null, formattedUrl);
    
    return (
        <>
            <Helmet>
                <title>{receivedVariable}</title>
            </Helmet>
            <Banner
                img = {BannerImg}
                desc= {BannerDesc}
                demo = {demo}
                pdf = {pdf}
                fact = {fact}
                paper = {paper}
            />
            <div className='section_divider risk_container my-4'>
                <div className="container">
                    <h4>{title}</h4>
                </div>
            </div>
            <div className='d-flex flex-row container'>
                <div className="main-content overview_content" style={{flex: '4'}}>
                    {overview === 'yes' &&
                        <h6>OVERVIEW</h6>
                    }
                    <Content RiskArray = {RiskArray}/>
                </div>

                <div className="sidebar_section" style={{flex: '1'}}>
                    <Sidebar /> 
                </div>
            </div>
            {receivedVariable === 'Aml' && <>
                {AmlArray.map((item, index) => (
                    <div>
                    {item.transaction.map((trans, transIndex) => (
                        <div key={transIndex}>
                            {trans.paralax === 'yes' && <div className='paralax_trans d-flex align-items-center justify-content-center mb-4' style={{ backgroundImage: `url(${trans.img})`, height: '25rem' }}>
                                <h4 className='text-white w-75 text-center fw-bold' style={{lineHeight: '2.5rem'}}>“VALOORES Financial Crime & Compliance suite of applications helps financial institutions tackle today's tactical risk & compliance problems.”</h4>    
                            </div>}
                        </div>
                    ))}
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className='mb-4' style={{color: '#007ac1'}}>Key Features</h5>
                                <ul className='marker-blue'>
                                    {item.features.map((feat, featIndex) => (
                                        <li key={featIndex}>{feat}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-6">
                               <h5 className='mb-4' style={{color: '#007ac1'}}> Key Benefits</h5>
                                <ul className='marker-blue'>
                                    {item.benefits.map((benef, benefIndex) => (
                                        <li key={benefIndex}>{benef}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                ))}
            </>}
            
        </>
    )
}

export default Feature