import React from 'react';
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade'

const CardSection = (props) => {
  return (
    <>
        <div className="section_divider py-5" style={{background: '#f1f1f1'}}>
            <div className='card_section'>
                <div className='container'>
                    <div className='d-flex flex-column text-center mb-4'>
                        <h3 style={{color: '#136597'}}>VALOORES in'Analytics Solutions</h3>
                        <h6>Choose below from the wide range of options:</h6>
                    </div>
                    <div className='row'>
                        {props.CardArray.map((item, index) => (
                            <div key={index} className='col-md-3 mb-3'>
                                <Fade bottom>
                                    <div className='card_section-content'>
                                        <img src={item.img} style={{height: '6rem'}} alt="banking service" className='w-100'/>
                                        <div className='content_card p-3 d-flex flex-column justify-content-between'>
                                            <div>
                                                <h5>{item.title}</h5>
                                                <p>{item.desc}</p>
                                            </div>
                                            <div>
                                                <Link to = {item.link}>
                                                    <p>
                                                        Read More
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default CardSection;