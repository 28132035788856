

const inAsset = [
  
    
    {
      title: "Overview",
      desc:
      (
        <>
          Working with Tier 1 world class clients provided us with the ability and knowledge to effectively tackle problems we faced, on a sole mission of providing our clients with a satisfying solution that meets at least 90% of their needs with less effort and more than 50% of time reduction.
          <br/>
          <br/>
          This proud tradition did not change with every product we develop, considering the importance of Real Estate & Financial Assets in any economy, our in’ Real Estate & Financial Assets solution is helping medium to large enterprises managing and maintaining their huge real estate & financial assets data in an easy and agile organization while preserving at the same time a high security level.
          <br/>
          <br/>
          The success of our solution is based on several key elements such as the ease of access, user friendliness, comparable data, program security and data flexibility (ability to read data in different formats).

        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: 'By achieving these goals your organization will be able to achieve the following:',
        list: [
          'Reducing the huge amount of time spent on creating new real estate and financial assets data',
          'Minimizing the loss of data found on papers and in archives that can be easily damaged',
          'Managing several sources of information under one umbrella',
          'Organizing the inspection duration and schedules',
        ],
      },
      {
        title: "Key features",
        desc:'in’ Real Estate & Financial Assets enables institutions involved in Real Estate & Financial Assets to accomplish the following Goals:',
        list:
        [
          'Managing the huge real estate and financial assets database',
          'Enabling the easy creation and addition of real estate and financial assets data',
          'Organizing the real estate and financial assets’ external information such as asset value estimation',
          'Allowing several users with different roles to access and edit the information while preserving a high level of security',
        ],
      },
    
  ];
  
  export default inAsset;
  