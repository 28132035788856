import Analytics1 from '../../../../img/analytics/analytics1.jpg'
import Analytics2 from '../../../../img/analytics/analytics2.jpg'
import Analytics3 from '../../../../img/analytics/analytics3.jpg'
import Analytics4 from '../../../../img/analytics/analytics4.jpg'
import Analytics5 from '../../../../img/analytics/analytics5.jpg'
import Analytics6 from '../../../../img/analytics/analytics6.jpg'
import Analytics7 from '../../../../img/analytics/analytics7.jpg'
import Analytics8 from '../../../../img/analytics/analytics8.jpg'
import Analytics9 from '../../../../img/analytics/analytics9.jpg'
import Analytics10 from '../../../../img/analytics/analytics10.jpg'
import Analytics11 from '../../../../img/analytics/analytics11.jpg'
import Analytics12 from '../../../../img/analytics/analytics12.jpg'

const InAnalytics = [
    {
      img: Analytics1,
      title: "in'Via",
      desc: "VALOORES equips your organization with tailored pre-built KPIs and KRIs that align with your strategic objectives. We enable you to establish clear goals from the outset, continuously monitoring progress through sophisticated upstream and downstream workflow notifications. Enhance your performance and ensure accountability with a robust framework designed for success",
      link: "../banking/inAnalytics?variable=inVia",
    },
    {
      img: Analytics2,
      title: "in'Loan Loss Forecasting and Provisioning",
      desc: "VALOORES Financial Services offers comprehensive Loan Loss Forecasting and Provisioning solutions that meet the key requirements of IFRS 9, leveraging a wide range of parameters for precise analysis and reporting",
      link: "../banking/inAnalytics?variable=inLoan",
    },
    {
      img: Analytics3,
      title: "in'Business Analytics",
      desc: "VALOORES Business Analytics supercharges your data and business analysts with the tools to unlock game-changing insights, discover hidden opportunities, and predict the future with confidence. Dive into massive data sets effortlessly and turn raw data into powerful intelligence—fueling smarter decisions, faster growth, and a competitive edge that keeps you ahead of the curve!",
      link: "../banking/inAnalytics?variable=inBusiness",
    },
    {
      img: Analytics4,
      title: "in'Enterprise Financial Performance Analytics",
      desc: "In today’s fast-paced and complex financial landscape, it’s crucial for banks to swiftly grasp risk-adjusted profitability results. VALOORES Enterprise equips financial institutions with advanced tools to analyze performance, optimize profitability, and make informed decisions—ensuring resilience and growth in a dynamic market",
      link: "../banking/inAnalytics?variable=inEntreprise",
    },
    {
      img: Analytics5,
      title: "in'Retail Customer Analytics",
      desc: "Gone are the days of unwavering loyalty to a single bank! Today, it’s the norm for consumers to connect with multiple banks for their mortgages, checking and savings accounts, and lines of credit. With VALOORES, you can navigate this multi-banking landscape effortlessly, gaining the insights and tools you need to enhance customer engagement and drive growth!",
      link: "../banking/inAnalytics?variable=inRetail",
    },
    {
      img: Analytics6,
      title: "in'Enterprise Stress Testing & Capital Planning Analytics",
      desc: "Every year, financial institutions must tackle a vast array of regulations, and the challenge multiplies for those operating in multiple jurisdictions! With VALOORES, you can simplify compliance, ensuring you stay ahead of the game while navigating this complex landscape with confidence!",
      link: "../banking/inAnalytics?variable=inEntPlanning",
    },
    {
      img: Analytics7,
      title: "in'Institutional Performance Analytics",
      desc: "Today's banking customers are a new strain compared to those of the past! With a saturated market and minimal product differentiation, standing out has never been more crucial. VALOORES empowers you to break through the noise and deliver personalized experiences that resonate with today’s dynamic consumers!",
      link: "../banking/inAnalytics?variable=inInstitutional",
    },
    {
      img: Analytics8,
      title: "in'Asset Liability Analytics",
      desc: "The fast-paced and unpredictable landscape of today’s financial markets is driving companies in the Financial Services industry to prioritize the efficient allocation of capital while managing acceptable risk. VALOORES’ In’ Asset Liability Analytics delivers timely, actionable insights to navigate interest rate and liquidity risks, ensuring transparency on critical issues that matter!",
      link: "../banking/inAnalytics?variable=inAsset",
    },
    {
      img: Analytics9,
      title: "in'Analytical Applications Basel III",
      desc: "In the wake of the global financial crisis, Basel III regulations are undeniably comprehensive, yet banks are struggling to implement them effectively. To tackle these challenges, VALOORES’ Analytical Applications for Basel III are built on a unified platform that dismantles traditional silos in risk, performance, and finance. This integrated approach empowers senior management with a holistic view of enterprise-wide risks, enhancing decision-making across the board!",
      link: "../banking/inAnalytics?variable=inAnalyticalApp",
    },
    {
      img: Analytics10,
      title: "in'Model Risk Management",
      desc: "Financial institutions rely on models for risk quantification, fraud detection, and performance management. With regulators demanding transparency and governance, VALOORES’ Model Risk Management enables organizations to establish a centralized repository for model information, ensuring robust oversight through regular risk assessments",
      link: "../banking/inAnalytics?variable=inModelRiskManagement",
    },
    {
      img: Analytics11,
      title: "in'Operational Risk Analytics",
      desc: "VALOORES' Operational Risk Analytics empowers users with dynamic, enterprise-wide reporting for comprehensive operational risk analysis. Our ready-to-deploy suite of pre-built analytics allows institutions to maximize existing investments. By diving deep into loss, risk, and control data, you'll uncover vulnerabilities and opportunities for improvement throughout your organization",
      link: "../banking/inAnalytics?variable=inOperational",
    },
    {
      img: Analytics12,
      title: "in'Pro-Active Debt Recovery",
      desc: "Traditional loan processes take time to yield profits, but client defaults create significant challenges. To combat this, banks must have specialized Collections Departments. Preventing defaults is crucial, and early detection methods allow proactive adjustments to loan terms. VALOORES' Pro-Active Debt Recovery solution leverages advanced machine learning algorithms to predict defaults, enabling timely interventions and minimizing losses.",
      link: "../banking/inAnalytics?variable=inProActive",
    },
  ]

  export default InAnalytics