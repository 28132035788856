const aboutUsArray = [
    {
      title: 'Who We Are',
      links: [
        {
          title: 'Mission',
          link: '/Valoores103A/AboutUs/Overview/',
        },
        {
          title: 'Vision',
          link: '/Valoores103A/AboutUs/Overview/',
        },
        {
          title: 'Values',
          link: '/Valoores103A/AboutUs/Overview/',
        },
        {
          title: 'The Logo',
          link: '/aboutUs/logo',
        },
      ]
    },
    {
      title: 'Our insights',
      links: [
        {
          title: 'Videos',
          link: '',
        },
        {
          title: 'News & Events',
          link: '/Valoores103A/News&Events',
        },
      ]
    },
    {
      title: 'Join Us',
      links: [
        {
          title: 'Careers',
          link: '/hr/career',
        },
        {
          title: 'Academy',
          link: '/hr/academy',
        },
      ]
    },
  ]

  export default aboutUsArray;