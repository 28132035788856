  import Risk from '../../img/banking/icons/icon1.png';
  import Profit from '../../img/banking/icons/icon2.png';
  import Data from '../../img/banking/icons/icon3.png';
  import Marketing from '../../img/banking/icons/icon4.png';
  import Analytics from '../../img/banking/icons/icon5.png';
  import Kernel from '../../img/banking/icons/icon6.png';
  import Payment from '../../img/banking/icons/icon7.png';
  import Asset from '../../img/banking/icons/icon8.png';
  import Cards from '../../img/banking/icons/icon9.png';
  import Crypto from '../../img/banking/icons/icon10.png';
  import Img_Risk from '../../img/banking/imgs/image1.jpg'; 
  import Img_Profit from '../../img/banking/imgs/image2.jpg';
  import Img_Data from '../../img/banking/imgs/image3.jpg';
  import Img_Marketing from '../../img/banking/imgs/image4.jpg';
  import Img_Analytics from '../../img/banking/imgs/image5.jpg';
  import Img_Kernel from '../../img/banking/imgs/image6.jpg';
  import Img_Payment from '../../img/banking/imgs/image7.jpg';
  import Img_Asset from '../../img/banking/imgs/image8.jpg';
  import Img_Cards from '../../img/banking/imgs/image9.jpg';

  const bankingSlides = [
      {
        image: Img_Risk,
        title: "in'Risk Compliance",
        description: "VALOORES in'Risk Compliance helps you reach your goals, to allow your organization to be fully prepared for unpredictable events.",
        header: "in'Risk Compliance",
        link: '/banking/feature?page=risk-and-compliance',
        icon: Risk,
        icon_white: 'white_scale',
      },
      {
        image: Img_Profit,
        title: "in'Profit",
        description: "VALOORES in'Profit drives a company's profitability. Rich, intelligent & compliant to international standards are in'Profit's bread and butter.",
        header: "in'Profit",
        link: '/banking/feature?page=inprofit',
        icon: Profit,
        icon_white: 'white_scale',
      },
      {
        image: Img_Data,
        title: "in'Data Governance",
        description: "With the popularity of implementing business intelligence (BI) applications, it is vital for Banks and Financial Institutions to manage the Master Data Management (MDM) in order to offer a consistent and centralized view of the data across the enterprise's systems.",
        header: "in'Data",
        link: '/banking/feature?page=indatagovernance',
        icon: Data,
        icon_white: 'white_scale',
      },
      {
        image: Img_Marketing,
        title: "in'Marketing Optimization",
        description: "Get the flexibility you need to keep up with evolving customer expectations, demographics, and demand with VALOORES in'Campaign, in'Pricing & in'Deals.",
        header: "in'Marketing",
        link: '/banking/feature?page=marketing-optimization',
        icon: Marketing,
        icon_white: 'white_scale',
      },
      {
        image: Img_Analytics,
        title: "in'Analytics",
        description: "Predictive analytics and forecasting are becoming more essential for organizations due to high competitive markets.",
        header: "Analytics",
        link: '/analytics',
        icon: Analytics,
        icon_white: 'white_scale',
      },

      {
        image: Img_Kernel,
        title: "in'Kernel",
        description: "VALOORES in'Kernel lets you manage the workforce, fortify diversity, automate processes while maintaining task traceability to reinforce accountability.",
        header: "in'Kernel",
        link: '/banking/feature?page=inkernel',
        icon: Kernel,
        icon_white: 'white_scale',
      },
      {
        image: Img_Payment,
        title: "in'Payment Services",
        description: "VALOORES in'Payment is a complete payment and receipt processing solution that enables efficient, reliable and secure financial transactions. It lowers costs and improves control by integrating out-of-the-box with major processors and financial institutions.",
        header: "in'Payment",
        link: '/banking/feature?page=financialservices',
        icon: Payment,
        icon_white: 'white_scale',
      },
      {
        image: Img_Asset,
        title: "in'Asset Management",
        description: "Offers solutions in middle-office and back-office functions enabling key capital market players like asset managers, custodians, broker/dealers, fund administrators to retain competitive advantage in a dynamic market environment.",
        header: "in'Asset",
        link: '/banking/feature?page=inassetmanagement',
        icon: Asset,
        icon_white: 'white_scale',
      },
      {
        image: Img_Cards,
        title: "in'Cards Merchant",
        description: "VALOORES in'Cards Merchant is a single solution for all your card management activities. From forecasting issuance and fulfilment to file management and reporting.",
        header: "in'Cards",
        link: '/banking/feature?page=cardsmerchant',
        icon: Cards,
        icon_white: 'white_scale',
      },
      // {
      //   image: Img_Cards,
      //   title: "in'Crypto",
      //   description: "VALOORES in'Cards Merchant is a single solution for all your card management activities. From forecasting issuance and fulfilment to file management and reporting.",
      //   header: "in'Crypto",
      //   link: '/banking/feature?page=crypto',
      //   icon: Crypto,
      //   icon_white: 'white_scale',
      // },
      
    ];

    export default bankingSlides;
    