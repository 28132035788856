import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import bg_img from '../img/img-01.jpg';
import logoLogin from '../img/full_logoC.png';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

const LoginPage = () => {
  const navigate = useNavigate();
  const SESSION_EXPIRATION_TIME = 1200000; 
  const [logIn, setLogIn] = useState(true);
  const [userValue, setUserValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [isLoginError, setIsLoginError] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    const expirationTime = localStorage.getItem('expirationTime');
    if (loggedInStatus) {
      if (expirationTime && new Date().getTime() < parseInt(expirationTime, 10)) {
        setIsLoggedIn(loggedInStatus === 'true');
        navigate('/admin');
      } else {
        logout();
      }
    }
  }, []);

  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('expirationTime', `${new Date().getTime() + SESSION_EXPIRATION_TIME}`);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expirationTime');
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post('http://localhost:8800/admin_login/fetch', {
  //       data: {
  //         userValue: userValue,
  //         passValue: passValue,
  //       },
  //     });
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }

    // event.preventDefault();

    // if (userValue === 'admin' && passValue === 'admin') {
    //   setLogIn(true);
    //   login();
    //   navigate('/admin');
    // } else {
    //   setIsLoginError(true);
    // }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8800/login', {
        userValue,
        passValue,
      });
  
      if (response.status === 200) {
        setIsLoginError(false);
        login(); 
        navigate('/admin');
      } else {
        setIsLoginError(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      setIsLoginError(true);
    }
  };


  return (
    <div className="auth" style={{backgroundImage: `url(${bg_img})`}}>
      <form className="login_form d-flex align-items-center" onSubmit={handleSubmit}>
      {/* <h1>Login</h1> */}
      <img src={logoLogin} alt="" className='w-75 d-flex' style={{alignSelf: 'top'}}/>
        <input
          className={isLoginError ? 'error_border form-control' : 'form-control'}
          required
          type="text"
          placeholder="username"
          name="username"
          value={userValue}
          onChange={(event) => setUserValue(event.target.value)}
        />
        <input
          className={isLoginError ? 'error_border form-control' : 'form-control'}
          required
          type="password"
          placeholder="password"
          name="password"
          value={passValue}
          onChange={(event) => setPassValue(event.target.value)}
        />
        <button className='btn btn-secondary w-100' type="submit">Login</button>
        {isLoginError && <span className="error_msg">Wrong Username or Password</span>}
        <span>
          Don't have an account?
          <Link target="_blank" to="/admin/Register">
            Register
          </Link>
        </span>
      </form>
    </div>
  );
};

export default LoginPage;
