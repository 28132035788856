import InData1 from '../../../../img/indata/inData1.jpg'
import InData2 from '../../../../img/indata/inData2.jpg'
import InData3 from '../../../../img/indata/inData3.jpg'
import InData4 from '../../../../img/indata/inData4.jpg'
import InData5 from '../../../../img/indata/inData5.jpg'
import InData6 from '../../../../img/indata/inData6.jpg'
import InData7 from '../../../../img/indata/inData7.jpg'
import InData8 from '../../../../img/indata/inData8.jpg'
import InData9 from '../../../../img/indata/inData9.jpg'
import InData10 from '../../../../img/indata/inData10.jpg'
import InData11 from '../../../../img/indata/inData11.jpg'
import InData12 from '../../../../img/indata/inData12.jpg'

const InDataA = [
    {
      img: InData1,
      title: "in'Data Governance",
      desc: "With the popularity of implementing business intelligence (BI) applications, it is vital for Banks and Financial Institutions to manage the Master Data Management (MDM) in order to offer a consistent and centralized...",
      link: "../banking/InDataA?variable=InDataGovernance",
      button: 'yes',
    },
    {
      img: InData2,
      title: "in'KYE",
      desc: "VALOORES in' Channels Management empowers channel users to maximize operational performance by making better decisions faster and to increase profit margins by effectively controlling...",
      link: "../banking/InDataA?variable=InKYE",
      button: 'yes',
    },
    {
      img: InData3,
      title: "in'KYC",
      desc: "Banks and Financial institutions are constantly working to recognize and identify customers. Know your customer (KYC) policies mandated by regulators globally are becoming increasingly important...",
      link: "../banking/InDataA?variable=InKYC",
       button: 'yes',
    },
    {
      img: InData4,
      title: "in'Fraud",
      desc: "VALOORES in' Channels Management empowers channel users to maximize operational performance by making better decisions faster and to increase profit margins by effectively controlling...",
      link: "https://banking.valoores.com/infraud.php",
       button: 'yes',
    },
    {
      img: InData5,
      title: "in'Supplier Management",
      desc: "In an aggressive global market, companies must react efficiently and effectively, to remain competitive, and one of their leverages is to master their Supply Chain process...",
      link: "../banking/InDataA?variable=InSupplierManagement",
       button: 'yes',
    },
    {
      img: InData6,
      title: "in'Contact Center Management",
      desc: "As the primary hub of customer interactions, the contact center is the strategic center of your customer experience strategy. VALOORES Contact Center Experience enables end-to-end management...",
      link: "../banking/InDataA?variable=InContact",
       button: 'yes',
    },
    {
      img: InData7,
      title: "in'Data Foundation",
      desc: "In an effort to effectively address regulatory and competitive pressures, financial institutions are turning to sophisticated analytical systems for business decision making. All business lines including Marketing,...",
      link: "../banking/InDataA?variable=InFoundation",
       button: 'yes',
    },
    {
      img: InData8,
      title: "in'Sourcing",
      desc: "VALOORES in' Sourcing is the enterprise application that improve the effectiveness and efficiency of strategic sourcing. They are the key components of VALOORES Advanced Procurement, the integrated suite that dramatically...",
      link: "../banking/InDataA?variable=InSourcing",
       button: 'yes',
    },
    {
      img: InData9,
      title: "in'Product Information Management",
      desc: "VALOORES in'Product Information Management is designed for financial services who require a central repository to manage vital information. This advanced and innovative solution provides financial services...",
      link: "../banking/InDataA?variable=InProductManager",
       button: 'yes',
    },
    {
      img: InData10,
      title: "in'Organization Structure Management",
      desc: "An effective structure facilitates management and clarifies relationships, roles and responsibilities, levels of authority, and supervisory or reporting lines. By reviewing an organization's structure,...",
      link: "../banking/InDataA?variable=InOrganization",
       button: 'yes',
    },
    {
      img: InData11,
      title: "in'Product Structure Management",
      desc: "A representation of the way in which the parts of a product fit together and interact, organized in levels of detail based on structure. VALOORES in' Product Structure Management is used to manage changes,...",
      link: "../banking/InDataA?variable=InProductManagement",
       button: 'yes',
    },
    {
      img: InData12,
      title: "in'Channels Management",
      desc: "VALOORES in' Channels Management empowers channel users to maximize operational performance by making better decisions faster and to increase profit margins by effectively controlling...",
      link: "../banking/InDataA?variable=InChanel",
       button: 'yes',
    },
  ]

  export default InDataA;