import React from 'react'
import Intro from '../Intro/Intro'
import './Insights.css'
import InsightsArray from './insightsArray'
import { Link } from 'react-router-dom'
import { CiClock2 } from "react-icons/ci";

const formatDate = (dateString) => {
    const [datePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const date = new Date(`${year}-${month}-${day}`); 
    
    const options = { day: '2-digit', month: 'short' }; 
    return date.toLocaleDateString('en-GB', options);
  };

const Insights = () => {
  return (
    <div className='mt-5'>
        <Intro title='Our Insights' />
        <div className='container w-75'>
            {InsightsArray.map((insight, index) => (
                <div className="row primary-bg my-3 pb-3 rounded" style={{height: 'auto'}} key={index} >
                    <div className='col-md-4 p-3  position-relative'>
                        <div className='insight_img' style={{backgroundImage: `url(${insight.img})`}}></div>
                        <div className="date_container position-absolute">
                            <div className='w-50 text-center'>
                                {formatDate(insight.date)}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 text-white p-3'>
                        <div className="upper d-flex flex-column gap-1">
                            <h5>{insight.title}</h5>
                            <div className='d-flex flex-row align-items-center gap-1 mb-3'>
                                <CiClock2 size={15} fill="#fff"/>
                                <p className='m-0' style={{fontSize: '0.8rem'}}>{insight.date}</p>
                            </div>
                            <p>{insight.desc}</p>
                        </div>
                        <div className="lower">
                            <Link className='btn btn-insights'>Read More</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Insights