import React from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '350',
    width: '100%',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      mute: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

const Introduction = () => {
  const videoId = '71NGqxCw4Po';

  return (
    <div className="container mt-5 intro_container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="video_player-container">
            <VideoPlayer videoId={videoId} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="content__title">
            <h2>
              <span>VALOORES in 3 Lines</span>
            </h2>
            <p>
              Enterprise Business Solutions in Financial Services, Cyber Crimes, BI, Analytics, in Advanced Certified Anti-Hacking and State Security Clouds Model. VALOORES is one of the leading providers of Enterprise Business Solutions and BI Vertical Decision Making. We are a World Class Company implementing products and services to Tier1 Corp. Our resources pool is spread over North America (US and Canada), Europe (Eastern & Western), Africa, and the Middle East. VALOORES, established in 1989, operates in 17 countries. &nbsp;
              <Link to="https://demo.valoores.com" target="_blank">Request Demo</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
