import React from 'react'
import './News.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsArray from './newsArray'
import LogoColor from '../../../assets/img/logoColored.png'
import { Link } from 'react-router-dom';
import { HiArrowLongRight } from "react-icons/hi2";
import { Fade } from 'react-awesome-reveal'

const News = () => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

  return (
    <div className='mb-5' style={{marginTop: '5rem'}}>
        <div className='mb-5 text-center'>
            <Fade >
                <h1 className='news__title font--poppins d-flex flex-column flex-lg-row align-items-center gap-3 justify-content-center' style={{color: '#121848'}}>
                    <img src={LogoColor} alt="" />
                    <span>News</span>
                </h1>
            </Fade>
        </div>
        <Slider {...settings}>
                {NewsArray.map((news, index) => (
                    <div key={index} className='d-flex flex-lg-row flex-column flex-sm-column align-items-center justify-content-center w-100'>
                        <div className='w-lg-60 w-100'>
                        <Fade direction='left'>
                            <div className='news_img_container' style={{backgroundImage: `url(${news.img})`}}></div>
                        </Fade>
                        </div>
                        <div className='d-flex flex-column align-items-lg-start align-items-md-center justify-content-center container_news container w-lg-40 w-md-100'>
                            <Fade direction='left' delay={400} cascade>
                                <div className='mb-4'>
                                    {news.title}
                                </div>
                                <Link to={news.link} target='_blank' className='see_news text-dark text-decoration-none text-center text-sm-center text-lg-start  fw-bold'>
                                    See More <HiArrowLongRight size={25}/>
                                </Link>
                            </Fade>
                    </div> 
                    </div> 
                ))}
        </Slider>
    </div>
  )
}

export default News