import React from 'react'

const SectionContainer = ({
    title,
    description,
    image,
    backgroundColor,
    layout
    
}) => {
  return (
    <div className="p-5 my-5" style={{background: backgroundColor}}>
        <div className="container">
            <div className={`row ${layout}`} >
                <div className="col-md-4">
                    <img src={image} className='w-100' alt="" />
                </div>
                <div className="col-md-8">
                    <h4 style={{color: '#001d6e'}}>{title}</h4>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SectionContainer