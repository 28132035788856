import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import slides from './slides';
import Fade from 'react-reveal/Fade';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'scale(2)', right: '5%', color: '#000', zIndex: '5' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: 'scale(2)', left: '4%', color: '#000', zIndex: '5' }}
      onClick={onClick}
    />
  );
}
  
const Carousel = ({ selectedSlide }) => {
    const sliderRef = useRef(null);
  
    useEffect(() => {
      sliderRef.current.slickGoTo(selectedSlide);
    }, [selectedSlide]);
  
    const settings = {
      easing: 'linear',
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      beforeChange: (currentSlide, nextSlide) => {
        const currentSlideElement = document.querySelector(`.slick-slide[data-index="${currentSlide}"]`);
        const fadeElements = currentSlideElement.querySelectorAll('.fade-element');
        fadeElements.forEach((element) => {
          element.style.opacity = '0';
          element.style.transition = 'none';
        });
      },
      afterChange: (currentSlide) => {
        const currentSlideElement = document.querySelector(`.slick-slide[data-index="${currentSlide}"]`);
        const fadeElements = currentSlideElement.querySelectorAll('.fade-element');
        fadeElements.forEach((element) => {
          element.style.opacity = '1';
          element.style.transition = 'opacity 500ms ease-in-out';
        });
      }
    };
  
    return (
      <Slider {...settings} ref={sliderRef}>
        {slides.map((slide, index) => (
          <div className='content' style={{ position: 'relative' }} key={index}>
            <div className="img_content-banking row" style={{ backgroundImage: `url(${slide.image})`, height: '650px' }}>
              <div className="overlay_bg">
                <div style={{lineHeight: '2rem'}} className={`pt-5 text_content col-md-6 ${slide.position}`}>
                  <Fade bottom>
                    {/* <div className="fade-element"> */}
                      <h2 className='text-white'>{slide.title}</h2>
                      <h4 className='text-white'>{slide.description}</h4>
                      <div className='d-flex flex-row gap-8 mt-4'>
                        {slide.button === 'yes' && <span><Link target="_blank" to={slide.link} className='btn btn-primary btn-show'>Learn More</Link></span>}
                      </div>
                    {/* </div> */}
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  };
  
  export default Carousel;
  