import React from 'react'
import Banner from '../components/General/Banner/Banner'
import Intro from '../components/General/Intro/Intro'
import RequestDemo from '../components/General/RequestDemo/RequestDemo'
import Sections from '../components/VFDS/Sections/Sections'
import Compliance from '../components/VFDS/Compliance/Compliance'
import Vfds from '../assets/img/banking.jpg'
import Services from '../components/VFDS/Services/Services'
import Insights from '../components/VFDS/Insights/Insights'
import VfdsLogo from '../assets/img/VFDS-logo-white.png'

const VfdsBannerTitle = (
    <>
        <img src={VfdsLogo} className='vfds_top-banner_width' alt="" />
    </>
)

const VFDS = () => {
  return (
    <div>
        <Banner title={VfdsBannerTitle} desc='The Fusion of Technology and Data' descSize='1rem' img={Vfds} classType='vfdsClass'/>
        <Intro title='A Solution for Every Need…' desc='A holistic and nimble Compliance Solutions Suite catering for all financial institutions: Fintechs, crypto exchanges, virtual assets service providers, payment service providers, E-wallet providers and more…VFDS is adaptable to any financial products, services & customer profiles.' />
        <Sections />
        <Compliance />
        <Services />
        <Insights />
        <RequestDemo />
    </div>
  )
}

export default VFDS