
const InBranch = [
    {
      title: "Overview",
      desc:
      (
        <>
            There are two important informative purposes for measuring branch profitability. Firstly, knowledge of what costs and revenues would be affected if a particular branch were to be closed is crucial. Secondly, evaluating the performance of a branch based on profitability will allow one to also assess the performance of branch managers. These purposes would seem to argue for not assigning assets to a branch, but rather concentrating on core deposits and other activities directly related to the existence of the branch and over which branch management has some control.
            <br/>
            in’ Branch Profitability allows you to manage branch activities which have a direct impact on profitability (revenues and expenses). The solution allows you to determine whether:
            <br/>
            1. The activity has either revenue or expense (or both) associated with it
            <br/>
            2. The revenue or expense is related to a one-shot occurrence or an ongoing activity.
            <br/>
            3. The revenue or expense is based on a fixed dollar amount per item or related to some dollar balance amount.

            </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
         "Gain insightful information of branch performance through effective analytics",
         "Manage branch manager performance through profitability calculation tools",
         "Monitor key profitability-driven activities while evaluating their behavior and nature",
          
        ],
      },
    //   {
    //     title: "features",
    //     desc:'',
    //     list:
    //     ([
    //         "Industry leading Transfer Pricing Methods support base transfer rates",
    //         "TP methods available for ALM Forecasting and Budgeting",
    //         "Option, Liquidity, and Breakage Charges",
    //         "Visibility into the components of net interest margin",
    //         "Shared data model and platform",
    //         "Enterprise class scalability",
    //         "Integration of Risk, Performance Management, Customer Insight, and Financial Compliance",
            
    //     ]),
    // }
  ];
  
  export default InBranch;
  