import React from 'react';
import Framework from '../../img/valooresframework.gif';
import {Link} from 'react-router-dom'

const Compliance = () => {
  return (
    <>
    <div className="compliance_section mt-5">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="compliance__title">
                        <h2>The Global <span>Compliance Pain</span></h2>
                        <p className='mt-3'>
                        Experience the most powerful and flexible tools for Compliance
                        <br /><br />
                        At VALOORES, we take a different approach to data intelligence providing real-time transparency into the risk of Financial Institutions' customers. Specializing in high-risk processes with high volume clients, our global coverage enables us to spot tens of thousands of risk events within millions of structured and unstructured data sources, every day.
                        <br /><br />
                        As institutions improve their tools, VALOORES is often chosen to digitize client onboarding (KYC/KYB), monitor ongoing customer behaviors and financial crime risk, and comply with AML/CFT. Our suite of products can be configured to serve all banking sectors from Tier 1 Financial Institutions to Community Banks focusing on one to two banking segments.
                        <br /><br />
                        “The system is simple to set up and use, and flexible to fine-tune. Using 'shared compliance infrastructure' also allows us to focus our technical resource on other core business activities”
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <Link to = 'https://valoores.com/slider.php' target='_blank'>
                        <img className='w-100' src={Framework} alt="" />
                    </Link>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Compliance