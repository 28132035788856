import React from 'react'
import geospatialParalax from '../../img/banners/geospatialParalax.jpg'


const Paralax = () => {
  return (
    <>
      <div className="geospatial__paralax" style={{backgroundImage: `url(${geospatialParalax})`}}></div>
    </>
  )
}

export default Paralax


