import auchan from '../../../assets/img/partners/auchan.png';
import bhv from '../../../assets/img/partners/bhv.png';
import cvs from '../../../assets/img/partners/cvs.png';
import fnac from '../../../assets/img/partners/fnac.png';
import carrefour from '../../../assets/img/partners/carrefour.png';
import canadiantyre from '../../../assets/img/partners/canadiantyre.png';
import lafayette from '../../../assets/img/partners/lafayette.png';
import target from '../../../assets/img/partners/target.png';
// import csc from '../../../assets/img/partners/csc.png';
import bandq from '../../../assets/img/partners/bandq.png';
import fin from '../../../assets/img/partners/fin.png';
import king from '../../../assets/img/partners/king.png';
import louisdelhaize from '../../../assets/img/partners/louisdelhaize.png';
import mono from '../../../assets/img/partners/mono.png';
import nasco from '../../../assets/img/partners/nasco.png';
import oracle from '../../../assets/img/partners/oracle.png';
import provera from '../../../assets/img/partners/provera.png';
import refinitiv from '../../../assets/img/partners/refinitiv.png';
import thomsonreuters from '../../../assets/img/partners/thomsonreuters.png';
import simply from '../../../assets/img/partners/simply.png';
import valeo from '../../../assets/img/partners/valeo.png';

    const partnersArray = [
        
        {
            image: carrefour,
            link: 'https://www.carrefour.com'
        },
        {
            image: fnac,
            link: 'https://fnac.com/'
        },
        {
            image: cvs,
            link: 'https://www.cvs.com'
        },
       
        {
            image: canadiantyre,
            link: 'https://www.canadiantire.ca/en.html'
        },
        
        {
            image: auchan,
            link: 'https://banking.valoores.com/'
        },
        {
            image: bandq,
            link: 'https://insurance.valoores.com/'
        },
        
        {
            image: bhv,
            link: 'https://insurance.valoores.com/'
        },
        // {
        //     image: csc,
        //     link: 'https://insurance.valoores.com/'
        // },
        {
            image: target,
            link: 'https://www.carrefour.com'
        },
        {
            image: lafayette,
            link: 'https://fnac.com/'
        },
        {
            image: fin,
            link: 'https://fnac.com/'
        },
        {
            image: king,
            link: 'https://fnac.com/'
        },
        {
            image: louisdelhaize,
            link: 'https://fnac.com/'
        },
        {
            image: mono,
            link: 'https://fnac.com/'
        },
        {
            image: nasco,
            link: 'https://fnac.com/'
        },
        {
            image: oracle,
            link: 'https://fnac.com/'
        },
        {
            image: provera,
            link: 'https://fnac.com/'
        },
        {
            image: refinitiv,
            link: 'https://fnac.com/'
        },
        {
            image: simply,
            link: 'https://fnac.com/'
        },
        {
            image: thomsonreuters,
            link: 'https://fnac.com/'
        },
        {
            image: valeo,
            link: 'https://fnac.com/'
        },
    ]


export default partnersArray