import {useState, useEffect} from 'react';
import axios from 'axios';

const Axios = ({path}) => {
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    if (path === '/banking_carousel') {
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/banking_carousel/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/banking_slider'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/banking_slider/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/application'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/banking_application/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else{
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/banking_sections/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }
  }, [path]);

  return fetchedData;
};

export default Axios;