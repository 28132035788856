import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { AiOutlineUser, AiOutlineLineChart } from 'react-icons/ai';
import {IoIosPeople} from 'react-icons/io';
import {GiPublicSpeaker} from 'react-icons/gi';
import {GrContactInfo, GrWorkshop} from 'react-icons/gr';

const SidebarAbout = ({ handleClick }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedVariable = searchParams.get('variable');
  const [activePage, setActivePage] = useState(receivedVariable);
  
  const handleItemClick = (page) => {
    setActivePage(page);
    handleClick(page);
  };


  return (
    <>
      <div className="sidebar_about" style={{display: 'none'}}>  
        <ul>
          <li
            className={activePage === 'Contact' ? 'active_page' : ''}
            onClick={() => handleItemClick('Contact')}
          >
            <GrContactInfo /> Contact Us
          </li>
          <li
            className={activePage === 'Ceo' ? 'active_page' : ''}
            onClick={() => handleItemClick('Ceo')}
          >
            <GiPublicSpeaker /> Word Of The CEO
          </li>
          <li
            className={activePage === 'Mission' ? 'active_page' : ''}
            onClick={() => handleItemClick('Mission')}
          >
            <GrWorkshop /> Mission & Vision
          </li>
          <li
            className={activePage === 'Leaders' ? 'active_page' : ''}
            onClick={() => handleItemClick('Leaders')}
          >
            <IoIosPeople /> Executive Leaders
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarAbout;
