const MediaItems = [
    {
        id: 0,
        title: "VALOORES DTO's Award New Delhi Octobre 2023, with Religious Freedom for Business",
        description: "Be Proud in your Identity as Lebanese and Believe in your Dream to Rebuild your Homeland, Lebanon, with true Examples of Successes Stories from our Peoples.",
        videoUrl: "https://www.youtube.com/embed/Y-B3KEVyzIQ",
      },
    {
        id: 1,
        title: "in'Covid-19 From FEAR to HOPEFrom Survival to OPPORTUNITYDIGITAL Transformation IS the STRATEGY",
        description: "Beyond Fear and Survival, COVID-19 has come to remind us of the Power of Hope; an Opportunity to build and shape a more Sustainable World! Digital Transformation IS the FAST Moving Strategy, to Come out Stronger! It becomes an Obligation, more than an Option!",
        videoUrl: "https://www.youtube.com/embed/3JqI7v8iI4A?si=3PWoCu2FksNsdK5G",
      },
      {
        id: 2,
        title: "Why Covid-19 Changes & Accelerates:Think Digital & Move Fast, instead of, Think Big & Start Small.",
        description: "Being in a FAST strategy was the key to change History, and even back to David and Goliath, David took over Goliath, by his FAST MOVE. So, why COVID-19 changes and accelerates our main subject today; Think Digital and Move FAST, instead of Think Big & Start Small?",
        videoUrl: "https://www.youtube.com/embed/5KkCHj1Khjc?si=HAlIF6RTKnqMMXsQ",
      },
      {
        id: 3,
        title: "From Pain to Gain in'Time of Covid-19 Recovery will be Digital",
        description: "Digital Transformation has already started, but no one imagined there will be such a sudden Covid-19 pandemic. It turned the world upside down and forced us to break with the past and live a coming world.",
        videoUrl: "https://www.youtube.com/embed/nypYj_PkNDs",
      },
    {
        id: 4,
        title: "With COVID-19 in'Financial Regulations, What's Left From IFRS 9, BASEL 3, AML…?",
        description: "COVID-19 has Broken all Chains of Risk and Compliance. Regulators cannot sustain a resilient Financial System with previous norms like IFRS 9, Basel 3, and AML. The Private sector cannot Survive with prior Compliance regulations.",
        videoUrl: "https://www.youtube.com/embed/jdwi_dhJ7Dk",
      },
      {
        id: 5,
        title: "VALOORES - Live Webinar: Why COVID-19 is Accelerating DIGITAL PAYMENT TRANSFORMATION",
        description: "COVID-19 Accelerates the shift we have all been experiencing in Payments. Remote Customer Onboarding has always been important; the Digital Identity is the Key. Instead of the slow march to Digital, “We are seeing years of change in the last few months”.",
        videoUrl: "https://www.youtube.com/embed/9Lm4rCufS28",
      },
      {
        id: 6,
        title: "Cyber COVID Webinar: in'Times of Financial Crimes, Cybersecurity, with All International Regulators",
        description: "A third of the world's population is in corona virus lock down. The lock down increases the scope for criminals to exploit vulnerabilities and commit financial crime. The increased online presence has led to new, and in some cases more naive targets for online fraudsters.",
        videoUrl: "https://www.youtube.com/embed/wv_lvibdiZ0",
      },
    {
        id: 7,
        title: "With COVID-19 in'Financial Regulations, What's Left From IFRS 9, BASEL 3, AML…?",
        description: "COVID-19 has Broken all Chains of Risk and Compliance. Regulators cannot sustain a resilient Financial System with previous norms like IFRS 9, Basel 3, and AML. The Private sector cannot Survive with prior Compliance regulations.",
        videoUrl: "https://www.youtube.com/embed/jdwi_dhJ7Dk",
      },
      {
        id: 8,
        title: "VALOORES - Live Webinar : FATF in Time of COVID-19, related AML / CFT Risks and Policy Responses",
        description: "Criminals are taking advantage of the COVID-19 pandemic, and the gaps in national AML/CFT systems, with resources focused elsewhere, to carry out financial fraud and exploitation scams, including advertising and trafficking in counterfeit medicines, offering fraudulent investment opportunities, and engaging in phishing schemes that prey on virus-related fears.",
        videoUrl: "https://www.youtube.com/embed/Wz4otnW31Go",
      },
      {
        id: 9,
        title: "Kalam Ennas by Marcel Ghanem, Nehme Taouk, Republic for Hope for Lebanon, People Believe and Invest",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/G1RFqLhAvrg",
      },
    {
        id: 10,
        title: "VALOORES IFRS9 Nehme Taouk, Kalam El Nass ESA, Villa Rosa, BDL, Deloitte, Harvard",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/hoHV2SiTDLA",
      },
      {
        id: 11,
        title: "VALOORES MTV Prime Time News Nehme Taouk VALOORES Branches Financial services",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/MBPGdNHAiBk",
      },
      {
        id: 12,
        title: "IFRS9 Summit Dec5, Beirut, Lebanon, ESA, Villa Rosa, BDL, VALOORES, Nehme Taouk, Deloitte, Harvard",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/h8n0W2mbetg",
      },
    {
        id: 13,
        title: "IFRS9 Summit Dec5, Beirut, Lebanon, ESA, Villa Rosa, BDL, VALOORES, Nehme Taouk, Deloitte, Harvard",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/ZUg7fbUWxWA",
      },
      {
        id: 14,
        title: "IFRS9 Summit Dec5, Beirut, Lebanon, ESA, Villa Rosa, BDL, VALOORES, Nehme Taouk, Deloitte, Harvard",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/62B7xazi5Ic",
      },
      {
        id: 15,
        title: "in'GRC is crucial to shield any organization's information and security system",
        description: "The breaches to an organization's information security can damage a good reputation and lead to a loss of confidence among customers, investors and the market at large. Successful companies have learned through experience that to continuously achieve performance...",
        videoUrl: "https://www.youtube.com/embed/dNkQTs9yLi8",
      },
    {
        id: 16,
        title: "Equips multi-jurisdiction financial institutions with the only turn-key solution needed to comply",
        description: "Ongoing regulatory change and complex market dynamics present significant challenges for financial institutions' operating models, analytics and reporting capabilities. As regulators begin to export compliance regulatory requirements downstream to smaller banks...",
        videoUrl: "https://www.youtube.com/embed/N_AU6jsiNMQ",
      },
      {
        id: 17,
        title: "Provides market leading financial solutions, providing clients with financial statement insight",
        description: "VALOORES in'Profit drives a company's profitability. Rich, intelligent & compliant to international standards are in‘Profit's bread and butter.",
        videoUrl: "https://www.youtube.com/embed/mdfYKS5bRio",
      },
      {
        id: 18,
        title: "Caters to all financial needs, providing dynamic business , rule engines, reporting solutions",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/xxNkq8OJxtw",
      },
      {
        id: 19,
        title: "Encompasses all aspects of the Planner process, including profit Planner, budgeting and forecasting",
        description: "VALOORES Financial services' in'Balance Sheet exposes a detailed form of all activities related to subordinates, branches, head office and affiliates, allowing professional decisions relying on viable true presentation of your position. In'Balance Sheet allows a business to save time and money...",
        videoUrl: "https://www.youtube.com/embed/JTFYeoWhjmg",
      },
      {
        id: 20,
        title: "IFRS9 After Summit Beirut, Lebanon, ESA, BDL, Central Bank, VALOORES, Deloitte, Harvard Business",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/NoHs2gzxSTY",
      },
      {
        id: 21,
        title: "Empowers analysts to extract knowledge,gain insight and make predictions while",
        description: "oores in'Analytica, with its native forecasting models, leverages customer-centric products, segmentation / clustering based optimization vehicles, and introduce what-if simulations, rule-based offer mechanism, and ROI-aware decision making...",
        videoUrl: "https://www.youtube.com/embed/04NzK5_gD_w",
      },
      {
        id: 22,
        title: "In'FC-AML provides financial institutions with an enterprise wide solution to achieve",
        description: "Financial institutions are working hard to fight financial crime and bank fraud driven by demands to protect their assets, as well as by regulatory compliance. One area of specific focus is that of Anti-Money Laundering (AML)...",
        videoUrl: "https://www.youtube.com/embed/BQc6a9XEvKc",
      },
      {
        id: 23,
        title: "VALOORES Financial Services providing dynamic business , rule engines, reporting solutions",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/Izr5w5O8aQM",
      },
      {
        id: 24,
        title: "VALOORES is one of the global leading providers of Enterprise Management & Decision Making solutions",
        description: "VALOORES , established in 1989, is one of the leading providers of Enterprise Risk & Performance Management, alongside Decision Making solutions. We are a World Class Company catering solutions and services to Tier 1 enterprises around the globe.",
        videoUrl: "https://www.youtube.com/embed/71NGqxCw4Po",
      },
      {
        id: 25,
        title: "Provides a wide risk and performance management platform along with a rule based engine",
        description: "FRS 9 “Regulatory Tsunami” comes to succeed IAS 39 by introducing new requirements and models for financial institutions. Financial Instruments are now perceived differently while the guidelines are divided under 3 main pillars: Classification & Measurement, Impairment, and Hedge Accounting.",
        videoUrl: "https://www.youtube.com/embed/-8cjAawAoac",
      },
      {
        id: 26,
        title: "Cost-effective solution allowing you to mitigate FATCA risks on time while securing regulatory",
        description: "The Foreign Account Tax Compliance Act (FATCA) enacted in 2010 by the U.S Congress has a wide range of implications for Banks and Financial institutions over the world. A vigorous, cost-effective solution that can be integrated into the bank's IT environment without impacting their day to day operations is required to fill the need imposed by this regulation.",
        videoUrl: "https://www.youtube.com/embed/zb0Flg7m6do",
      },
      {
        id: 27,
        title: "in'Kernel, the core element of the entire suite, provides execution tools allowing users to perform",
        description: "VALOORES in'Kernel lets you manage the workforce, fortify diversity, automate processes while maintaining task traceability to reinforce accountability.",
        videoUrl: "https://www.youtube.com/embed/xnbuBg0UvJU",
      },
      {
        id: 28,
        title: "Covers all insurance needs, from back-end data governance and calculation engine to front-end",
        description: "VALOORES, as a privately held company, has embraced the metamorphosing Banking & Finance business models, to sustain growth, and tackle challenges segregated around governance, risk, compliance, profitability, payments, competition, branding, loyalty, Omni-channel experience, trust and engagement and commoditization...",
        videoUrl: "https://www.youtube.com/embed/6howtwjO2a0",
      },
      {
        id: 29,
        title: "BoB Finance Testimony on VALOORES' Successful Relationship in Systems, GRC, Analytics & BI",
        description: "Established by Bank of Beirut, BoB Finance started operating in 2005 to become one of the largest financial services providers in Lebanon. The 900 locations between standalones and subagents cover the whole Lebanese territory and refer to headquarter where compliance and account approvals are centralized as well...",
        videoUrl: "https://www.youtube.com/embed/dCOoTcjbfzA",
      },
]

export default MediaItems;