import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination,Navigation, Autoplay} from 'swiper/core';
import 'swiper/swiper.css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import Axios from './database/axios.jsx';



// Install Swiper modules
SwiperCore.use([Pagination,Navigation, Autoplay]);

const Media = () => {
    const [slidesPerView, setSlidesPerView] = useState(3);
    const fetchedData = Axios({ path: '/document' });
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setSlidesPerView(1);
        } else if (window.innerWidth < 992) {
          setSlidesPerView(2);
        } else {
          setSlidesPerView(3);
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return (
      
      <div className="media__container" id="media"> 
        <div className="container">
          <div className="row">
            <div className="media__title pt-5 pb-3">
              <h2>
                <span>Valoores </span>Multimedia
              </h2>
            </div>
          </div>
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            Pagination= {3}
            navigation={true}
            className="mySwiper"
          >
            {fetchedData.map((doc) => (
              <SwiperSlide key={doc.id}>
                <div className="card">
                  <div className="card-header">
                    <img src={require(`../../img/${doc.image_path.split('/').pop()}`)} alt="" className="w-100" />
                  </div>
                  <div className="card-body">
                    <h5>{doc.doc_name}</h5>
                    {/* <p>{doc.doc_desc}</p> */}
                    <Link target="_blank"  className="btn btn-primary" 
                    to={require(`../../docs/${doc.doc_path.split('/').pop()}`)}>
                      <li>
                      Read More...
                      </li>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    );
  };
  
  export default Media;