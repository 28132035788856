import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MediaItems from './MediaItems';


const Media = () => {
  const settings = {
    easing: 'linear',
    infinite: true,
    speed: 500,
    loop: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
  };

  return (
    <div>
      <div className="media__container media_objects" id="media"> 
        <div className="container">
          <div className="row">
            <div className="media__title pt-5 pb-3">
              <h2>
                <span>Valoores </span>Multimedia
              </h2>
            </div>
          </div>
        </div>
        <div className='container my-3' style={{maxWidth: '85vw'}}>
          <Slider {...settings}>
            {MediaItems.map((item) => (
              <div key={item.id} className='px-4'>
              <div className="media__card">
                <div className="media__video">
                  <iframe
                    width="100%"
                    height="315"
                    src={item.videoUrl}
                    title={item.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="media__content">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Media;
