import React from 'react'

const Banner = (props) => {
  return (
    <>
        <div className='banner_img text-white d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${props.image})`}}>
          <div className="banner_overlay"></div>
          <div className='banner_content w-75 text-center' style={{ top: `${props.pos}`}}>
            <h1 className='text-white'>{props.title}</h1>
            <p>{props.desc}</p>
          </div>
        </div>
    </>
  )
}

export default Banner