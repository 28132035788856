import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Users() {

  const [deleteId, setDeleteId] = useState(null);

  
  const [fetchedData, setFetchedData] = useState([]);
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [DocumentPath, setDocumentPath] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete('http://localhost:8800/request/deletedrequest', {
        data: {
          delete_ids: deleteId,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/request/requestShow');
        setFetchedData(response.data);
        setIsDataFetched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = (id, name, email, document_path) => {
    setEditId(id);
    setName(name);
    setEmail(email);
    setDocumentPath(document_path);
    setIsAcceptModalOpen(true);
  };

  const handleDelete = (delete_id) => {
    setDeleteId(delete_id);
    setIsDeleteModalOpen(true);
  };
  const emailData = new FormData();
  emailData.append('to', Email);

  const handleAcceptSubmit = async (e) => {
    e.preventDefault();
    const filePath = DocumentPath;
    const parts = filePath.split('/');
    const fileName = parts[parts.length - 1];
    try {
        emailData.set('subject', `Document : ${fileName} Request`);
        emailData.set(
          'text',
          `Dear ${Name}, Kindly note that the document you requested access to is attached below.`
        );
        emailData.set(
          'attachments',
          `${DocumentPath}`
        );
        // Send email
        const response = await axios.post('http://localhost:8800/email/send', emailData);
        console.log(response.data); // Response from the server
        setIsAcceptModalOpen(false); // Close the modal after sending email
    
      const res = await axios.delete('http://localhost:8800/request/deletedrequest', {
        data: {
          delete_ids: editId,
        },  
      });
      console.log('Value deleted:', res.data);

    } catch (error) {
      console.log('error accepting request')
    }
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleAcceptModalOpen = () => {
    setIsAcceptModalOpen(true);
  };

  const handleAcceptModalClose = () => {
    setIsAcceptModalOpen(false);
  };

  return (
    <>
      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Document Requests</h1>
      </div>
      {isDataFetched && (
        <div className="tableStyle">
          <table className="table">
            <thead className="sticky-header">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Profession</th>
                <th>Company</th>
                <th>Doc Requested</th>
                <th>Date</th>
                <th>Accept</th>
                <th>Reject</th>
              </tr>
            </thead>
            <tbody>
              {fetchedData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td className="truncate">{item.name}</td>
                  <td className="truncate">{item.email}</td>
                  <td className="truncate">{item.profession}</td>
                  <td>{item.company}</td>
                  <td>{item.document_path}</td>
                  <td>{item.date}</td>
                  <td>
                    <button className="btn btn-secondary" to="#" onClick={() => handleClick(item.id, item.name, item.email, item.document_path)}>
                      Accept
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-danger" type="button" onClick={() => handleDelete(item.id)}>
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Accept CAROUSEL MODAL */}
      <Modal
      isOpen={isAcceptModalOpen}
      onRequestClose={handleAcceptModalClose}
      contentLabel="Accept Modal"
      >
          <div className="">
              <div className="">
                  <div className="">
                      <form className='Accept_form'>
                          <div>
                              <h5 className="pb-3 px-3">Accept Request</h5>
                          </div>
                          <div className='delete_warning' style={{display: 'flex', justifyContent: 'center'}}>
                            Are You Sure You Want To Accept This Request 
                          </div>
                          <div className="px-4 d-flex justify-content-end gap-3">
                              <button className='btn btn-secondary' onClick={handleAcceptSubmit}>Accept</button>
                              <button className='btn btn-danger' onClick={() => setIsAcceptModalOpen(false)}>Cancel</button>
                          </div> 
                      </form>
                  </div>
              </div>
          </div>
      </Modal>
      {/* Delete SECTION MODAL */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={handleDeleteModalClose}
        contentLabel="Delete Modal"
      >
        
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Reject Request</h5>
                </div>
                  <div className='delete_warning' Style="display: flex; justify-content: center;">
                    Are You Sure You Want To Reject This Request 
                  </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Reject</button>
                  <button className='btn btn-danger' onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Users;
