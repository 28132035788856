

const inEntreprise = [
    {
      title: "Overview",
      desc:
      (
        <>
    In today’s rapidly evolving and complex financial services environment, it is more critical than ever for banks to quickly understand profitability results adjusted for risk. valoores in' Enterprise Financial Performance Analytics is designed to provide timely and actionable financial and management reports across organization, line of business, products and legal entities. In addition to standard income statement and balance sheet reporting, valoores in'Enterprise Financial Performance Analytics delivers management ledger level reports, along with Risk Adjusted Performance Management (RAPM) reporting and scenario analysis for profitability reporting.
        </>
      ),
      list: [''],
    },
    {
      title: "Key Benefits",
      desc:'',
      list:
      [
       ' Utilize insight and intelligence to manage operations and business performance',
       ' Track profitability trends and cost structures based on key dimensions',
       ' Conduct scenario analysis at an aggregated level to gauge profitability variations',
      ],
    },
    {
      title: "Key Features",
      desc:'',
      list:
      [
        'Quickly and accurately identifies most profitable products, lines of businesses, organizations',
        'Easy access to key management reports for better performance tracking and profitability management',
        'Empowers all business users to access actionable profitability insight directly within operational processes',
        'Quickly adapts to changing business needs by leveraging existing technology investments',
        'Stores reporting values in multiple currencies as per the current transfer rates',
      ],
    },
  
  ];
  
  export default inEntreprise;
  