import React from 'react'
import Hero from '../components/Home/Hero/Hero'
import Intro from '../components/Home/Introduction/Intro'
import Solution from '../components/Home/Solution/Solution'
import Industries from '../components/Home/Industries/Industries'
import Partners from '../components/Home/Partners/Partners'
import Counter from '../components/Home/Counter/Counter'
import News from '../components/Home/News/News'
import '../style.css'


const Home = () => {
  return (
    <div>
        <Hero />
        <Intro />
        <div className='w-100 d-flex justify-content-center my-3'></div>
        <Solution />
        <Industries />
        <Partners />
        <Counter />
        <News />
    </div>
  )
}

export default Home