

const inBusiness = [
    {
      title: "Overview",
      desc:
      (
        <>
     valoores in'Business Analytics empowers data and business analysts to extract knowledge, discover new insights and make predictions—working directly with large data volumes in the valoores Database. valoores in' Business Analytics, offers a combination of powerful in-database algorithms and open source R algorithms. in' Business Analytics enables organizations to rapidly build and deploy enterprise analytical applications which are highly scalable and readily integrate with both enterprise business processes and BI environments.
        </>
      ),
      list: [''],
    },
    {
      title: "Solution Architecture",
      desc:
      (
        <>
      VALOORES’ Know Your Customer provides a robust, automated KYC platform for effective customer due diligence and profiling. It ensures dynamic customer data capture for on-boarding through our information retrieval and data validation capabilities, improves seamless workflow for a more efficient and enhanced validation cycle, and supports ongoing customer monitoring in order to identify high-risk cases. VALOORES’ Know Your Customer employs a flexible architecture which easily integrates with any legacy or core system for smooth implementation.
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
         'Powerful and Scalable Architecture',
         'Enables easy integration of analytics into enterprise applications',
         'Low Total Cost of Ownership',
         'Maintains security',
         'Optimized for Exadata',
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
         'Powerful and scalable architecture for performing in-database predictive analytics, data mining and statistics',
         'Easy to use: SQL Developer/valoores Data Miner workflow GUI or any R GUI work directly on database tables/views',
         'Scalable, parallel in-database execution for data mining model build, model apply and associated data preparation and transformations',
         'Integration with open source R algorithms',
         'Functionality accessible via SQL,PL/SQL, R and database APIs',
         'Enterprise-wide—integrated feature of the valoores Database',
         'Seamless support for enterprise analytical applications and BI environments',
        ],
      },
    
  ];
  
  export default inBusiness;
  