import DigitalID from '../../img/banking/icons/icon1.png'
import MachLearn from '../../img/banking/icons/icon5.png';
import Cybersecurity from '../../img/banking/icons/icon3.png';
import Blockchain from '../../img/banking/icons/icon4.png';
import Robotics from '../../img/banking/icons/icon1.png';
import RoboAdvisor from '../../img/banking/icons/icon6.png';
import image1 from '../../img/Digital/01.jpg';
import image2 from '../../img/Digital/02.jpg';
import image3 from '../../img/Digital/03.jpg';
import image4 from '../../img/Digital/04.jpg';
import image5 from '../../img/Digital/05.jpg';
import image6 from '../../img/Digital/06.jpg';


const digitalSlides = [
    {
        icon : DigitalID,
        image: image1,
        description : "VALOORES is disrupting traditional compliance operations, in how to accept and manage clients, by leveraging distributed ledger technology and sharing Digital Identity data. Identity Management involves controls to monitor the identity of individual users of a network, to either allow or limit access to systems and data.",
        title : "Digital ID & ekyc",
        header : "Digital ID & ekyc",
        cardSection : 'yes',
        Link1 : 'https://digital.valoores.com/digitalid.php',
        Link2 : 'https://digital.valoores.com/pdf/digital-id.pdf',
    },
    {
        icon : MachLearn,
        image: image2,
        description : "& Advanced Analytics analyzes large data sets to identify patterns and respond to them. Descriptive, Diagnostic, Predictive, and Prescriptive Analytics are at the heart of the Analytics engine, to research human behavior and plot Behavioral Analytics.",
       Title : "Machine Learning",
        header : "Machine Learning",
        cardSection : 'yes',
    Link1 : 'https://digital.valoores.com/machine-learning.php',
        Link2 : 'https://digital.valoores.com/pdf/machine-learning.pdf',
    },
    {
        icon : Cybersecurity,
        image: image3,
        description : "VALOORES Cybersecurity is specialized in assessing, monitoring, and testing third party vendor protocols and standards, alongside offering security hardening solutions, to address attacks like business email compromise, ransomware, distributed denial of service (DDoS), alongside many different challenges.",
        Title : "Cybersecurity",
        header : "Cybersecurity",
        cardSection : 'yes',
       Link1 : 'https://digital.valoores.com/cybersecurity.php',
        Link2 : 'https://digital.valoores.com/pdf/cybersecurity.pdf',
    },
    {
        icon : Blockchain,
        image: image4,
        description : "VALOORES Blockchain is disruptive and eye-opening to the RegTech spectrum, especially as the industry is continuously finding new areas where this technology can be applied. VALOORES Blockchain saves back office costs by eliminating mistakes, increasing the ledger efficiency, and enhancing data transparency and security.",
        Title : "Blockchain",
        header : "Blockchain",
        cardSection : 'yes',
    Link1 : 'https://digital.valoores.com/blockchain.php',
        Link2 : 'https://digital.valoores.com/pdf/blockchain.pdf',
    },
    {
        icon : Robotics,
        image: image5,
        description : "Financial Services have traditionally relied on considerable back office teams, or even outsourcing, to process and reconcile transactions, may it be using dated technology or even through manual efforts.",
        Title : "Robotics",
        header : "Robotics",
        cardSection : 'yes',
        Link : 'https://digital.valoores.com/robotics.php',
        Link2 : 'https://digital.valoores.com/pdf/robotics.pdf',
    },
    { 
        icon : RoboAdvisor,
        image: image6,
        description : "VALOORES Robo Advisor is transforming the Financial Industry over an easy-to-use platform, by providing automated and tailored advice on Governance, Risk, Compliance, Profitability, and enhanced customer service, with minimal human intervention.",
        Title : "Robo Advisor",
        header : "Robo Advisor",
        cardSection : 'no',
    Link1: 'https://digital.valoores.com/robo-advisor.php',
    Link2 : 'https://digital.valoores.com/pdf/robo-advisor.pdf',
    },

  ];

  export default digitalSlides;