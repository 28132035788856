import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import MainContent from './MainContent';
import './style.scss';

const AdminPage = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState('sections');
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/admin/login');
    } else {
      navigate('/admin'); 
    }
  }, [isLoggedIn, navigate]);

  const handleSidebarClick = (link) => {
    setSelected(link);
  };

  useEffect(() => {
    const expirationTime = localStorage.getItem('expirationTime');
    if (isLoggedIn && expirationTime) {
      const currentTime = new Date().getTime();
      if (currentTime >= parseInt(expirationTime, 10)) {
        logout();
        navigate('/admin/login');
      } else {
        const timeout = setTimeout(() => {
          logout();
          navigate('/admin/login');
        }, parseInt(expirationTime, 10) - currentTime);
        return () => clearTimeout(timeout);
      }
    }
  }, [isLoggedIn, navigate]);

  const logout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expirationTime');
  };

  if (isLoggedIn) {
    return (
      <div className="dashboard">
        <Sidebar className="sidebar" handleClick={handleSidebarClick} />
        <Routes>
          <Route path="/" element={<MainContent selected={selected} />} />
        </Routes>
      </div>
    );
  }
  return null;
};

export default AdminPage;