import { FaApple, FaAndroid, FaWindows, FaGlobe, FaGamepad, FaCloud, FaMobileAlt, FaMusic, FaCamera } from 'react-icons/fa';

const services = [
  { id: 1, title: 'Overview', icon: <FaApple /> },
  { id: 2, title: 'KYX', icon: <FaAndroid /> },
  { id: 3, title: 'Screening', icon: <FaWindows /> },
  { id: 4, title: 'Transaction Monitoring', icon: <FaGlobe /> },
  { id: 5, title: 'Regulatory Reporting', icon: <FaGamepad /> },
  { id: 6, title: 'Customer Risk Assessment', icon: <FaCloud /> },
  { id: 7, title: 'Fraud Risk Management', icon: <FaMobileAlt /> },
  { id: 8, title: 'Payment', icon: <FaMusic /> },
  { id: 9, title: 'IFRS', icon: <FaCamera /> },
];

export default services; 
