import Vfds1 from '../../../assets/img/vfds1.jpg'
import Vfds2 from '../../../assets/img/vfds2.jpg'
import Vfds3 from '../../../assets/img/vfds3.jpg'
import Vfds4 from '../../../assets/img/vfds4.jpg'
import Vfds5 from '../../../assets/img/vfds5.jpg'
import Vfds6 from '../../../assets/img/vfds6.jpg'
import Vfds7 from '../../../assets/img/vfds7.jpg'
import Vfds8 from '../../../assets/img/vfds8.jpg'
import Vfds9 from '../../../assets/img/vfds9.jpg'

const servicesArray = [
    {
        img: Vfds1,
        title: 'KYC',
        desc: 'A powerful, adaptable tool that streamlines customer data collection, identification and verification. Ensures compliance with international and local regulations across all financial services providers, from banks to crypto platforms.',
        link: '',
    },
    {
        img: Vfds2,
        title: 'Transaction Monitoring',
        desc: 'An advanced risk-scoring and AI-driven model enabling detection of suspicious activities in real-time, offering flexible customization of rules and parameters. With AI capabilities, our solution continuously refines risk assessments, reduces false positives and ensures accurate alerts.',
        link: '',
    },
    {
        img: Vfds3,
        title: 'Screening',
        desc: 'Real-time protection with advanced name matching and automated retroactive screening, ensuring compliance with evolving regulations, allowing seamless investigation and analysis of potential hits, integrating easily with any system.',
        link: '',
    },
    {
        img: Vfds4,
        title: 'Customer Risk Assessment',
        desc: 'Dynamic, real-time assessment of AML/CFT risks enabling detection of unusual patterns such as transaction velocity, collusion, and dark web suspicions. A sophisticated logic adaptable to evolving threats, while allowing risk-level overrides to align with regulatory demands.',
        link: '',
    },
    {
        img: Vfds5,
        title: 'Regulatory Reporting',
        desc: 'Offers customizable templates, multi-jurisdictional support and advanced submission models for seamless compliance. With cloud deployment, smart analytics, and strong data governance, our solution enables automation, cost reduction, and powerful reporting.',
        link: '',
    },
    {
        img: Vfds6,
        title: 'Insurance',
        desc: '',
        link: '',
    },
    {
        img: Vfds7,
        title: 'Payment',
        desc: '',
        link: '',
    },
    {
        img: Vfds8,
        title: 'Fraud Risk Assessment',
        desc: '',
        link: '',
    },
    {
        img: Vfds9,
        title: 'FATCA',
        desc: '',
        link: '',
    },
]

export default servicesArray;