

const inVia = [
    {
      title: "Overview",
      desc:
      (
        <>
           The in’Via is a user friendly business intelligence tool, dedicated to executives and business users to empower their everyday business decisions, via a direct access to a library of Key Performance and Risk Indicators. The in’Via includes a Dashboard Builder with Alerts and Tasks, an Application Builder, and a Library of KPRIs.
        </>
      ),
      list: [''],
    },
    {
      title: "Key Benefits",
      desc: '',
      list: [
      'Identify Trends, Discover Opportunities & Anticipate Emerging Risks',
      'Gain Insights on Key Parameters for Better Decision Making',
      'Multi-purpose, Business intelligence dashboard.',
      'Unifying data sources',
      'Avoiding duplicate information',
      'Information Transparency for Higher Traceability',
      'Multilanguage Application Enabling Diversity',
      'Efficient workforce management',
      'Transforming Data to Knowledge',
      ],
    },
    {
      title: "Dashboard Builder",
      desc: '',
      list: [
        'Create Alerts and Tasks',
        'Personalized dashboards',
        'Edit on the fly',
        'Define unique user experience',
        'Manage secured actions and roles',
      ],
    },
    {
      title: "Application Builder",
      desc: '',
      list: [
        'Easily Build and Access KPRIs',
        'Define KPRIs parameters',
        'Add, Edit or Delete, Search & Favorite Functions',
        'Chart Definition & Customize attributes',
      ],
    },
   
    
  ];
  
  export default inVia;
  