import React from 'react';
import {Link} from 'react-router-dom'
import Fade from 'react-reveal/Fade'

const CardSection = (props) => {
  return (
    <>
        <div className='card_section'>
            <div className='container'>
                <div className='row'>
                    {props.CardArray.map((item, index) => (
                        <div key={index} className='col-md-4 mb-3'>
                            <Fade bottom>
                                <div className='card_section-content'>
                                    <img src={item.img} style={{height: '6rem'}} alt="banking service" className='w-100'/>
                                    <div className='content_card p-3 d-flex flex-column justify-content-between'>
                                        <div>
                                            <h5>{item.title}</h5>
                                            <p>{item.desc}</p>
                                        </div>
                                        <div>
                                            {item.button === 'yes' && <Link target='_blank' to = {item.link}>
                                                <p>
                                                    Read More
                                                </p>
                                            </Link>}
                                            {/* <Link target='_blank' to = {item.link}>
                                                <p>
                                                    Read More
                                                </p>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>
  );
};

export default CardSection;