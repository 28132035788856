import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import '../style.scss'

// Set the app element for accessibility
Modal.setAppElement('#root');

function Services() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [serviceData, setServiceData] = useState({
    title: '',
    description: '',
    link: '',
  })
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadResponse = await axios.post('http://localhost:8800/short_cards/upload', formData);
        const imagePath = uploadResponse.data.imagePath;

        const res = await axios.post('http://localhost:8800/short_cards/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          imagePath: imagePath,
        });
      } else {
        console.log('Please select a file.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:8800/short_cards/show');
      setFetchedData(response.data);
      setIsDataFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (value, value2, value3, path, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(path);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, delete_path) => {
    try {
      const response = await axios.delete('http://localhost:8800/short_cards/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue4);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/short_cards/editImage', formData);
        const imagePath = uploadEditResponse.data.imagePath;

        const res = await axios.put('http://localhost:8800/short_cards/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      } else {
        const res = await axios.put('http://localhost:8800/short_cards/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setServiceData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <div>
      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Services</h1>
        <button className="btn btn-primary" onClick={handleAddModalOpen}>
          Add Service
        </button>
      </div>
      {isDataFetched && (
        <div className="tableStyle">
        <table className="table">
          <thead className="sticky-header">
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Link</th>
              <th>Image Path</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {fetchedData.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td className="truncate">{item.description}</td>
                <td className="truncate">{item.link}</td>
                <td className="truncate">{item.image_path}</td>
                <td>
                  <button
                    className="btn btn-secondary"
                    to="#"
                    onClick={() =>
                      handleClick(
                        item.title,
                        item.description,
                        item.link,
                        item.image_path,
                        item.id
                      )
                    }
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() =>
                      handleDelete(
                        item.id,
                        item.related_id,
                        item.image_path
                      )
                    }
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
      {/* EDIT SERVICE MODAL */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleEditModalClose}
        contentLabel="Edit Modal"
      >
          <div className="">
						<div className="">
							<div className="">
								<form className='edit_form'>
									<div>
										<h5 className="pb-3 px-3">Edit Service</h5>
									</div>

									<div className=" px-4">
										<div className="row mb-2">
											<div className="col-md-6">
                        <label htmlFor="cardId" >Service ID</label>
                        <input
                          id="cardId"
                          type="text"
                          disabled
                          readOnly
                          value={editId}
                          placeholder="Service Edit ID"
                          className='form-control my-3'
                        />
											</div>
											<div className="col-md-6">
                        <label htmlFor="cardTitle">Service Title</label>
                        <input
                          id="cardTitle"
                          type="text"
                          value={editValue}
                          onChange={handleInputChange}
                          placeholder="Service Edit Title"
                          className='form-control my-3'
                        />
											</div>
										</div>
										
										<div className="row">
											<div className="col-md-6">
												<div className="mb-4">
                          <label for="cardDesc">Service Description</label>
                          <input
                            id="cardDesc"
                            type="text"
                            value={editValue2}
                            onChange={handleInputChange2}
                            placeholder="Service Edit Description"
                            className='form-control my-3'
                          />
												</div>
											</div>
											<div className="col-md-6">
												<div className="mb-4">
                          <label htmlFor="cardLink">Service Link</label>
                          <input
                            id="cardLink"
                            type="text"
                            value={editValue3}
                            onChange={handleInputChange3}
                            placeholder="Service Edit Link"
                            className='form-control my-3'
                          />
												</div>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-lg-6">
												<div>
                          <label htmlFor="cardImg">Image File</label>
                          <input id="cardImg" type="file" onChange={handleFileEdit} 
                          className='form-control my-3'/>
                          
												</div>
											</div>
										</div>
									</div>

									<div className="px-4 d-flex justify-content-end gap-3">
                      <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                      <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                  </div> 
								</form>
							</div>
						</div>
					</div>
      </Modal>
      {/* ADD SERVICE MODAL */}
      <Modal
      isOpen={isAddModalOpen}
      onRequestClose={() => setIsAddModalOpen(false)}
      contentLabel="Add Modal"
    >
      <div className="">
        <form className="add_form" onSubmit={handleSubmit}>
          <div>
            <h5 className="pb-3 px-3">Add Service</h5>
          </div>

          <div className="px-4">
            <div className="row mb-2">
              <div className="col-md-6">
                <label htmlFor="title">Service Title</label>
                <input
                  id="title"
                  type="text"
                  placeholder="Card Title"
                  className="form-control my-3"
                  value={serviceData.title}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="description">Service Description</label>
                <input
                  id="description"
                  type="text"
                  placeholder="Service Description"
                  className="form-control my-3"
                  value={serviceData.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <label htmlFor="link">Service Link</label>
                  <input
                    id="link"
                    type="text"
                    placeholder="Service Link"
                    className="form-control my-3"
                    value={serviceData.link}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label htmlFor="addCardImg">Image File</label>
                  <input
                    id="addCardImg"
                    type="file"
                    onChange={handleFileChange}
                    className="form-control my-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 d-flex justify-content-end gap-3">
            <button type="submit" className="btn btn-secondary">
              Submit
            </button>
            <button type="button" className="btn btn-danger" onClick={() => setIsAddModalOpen(false)}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
    </div>
  );
}

export default Services;
