const InAssetManagement = [
      {
      title: "",
      desc: 
      (
        <>
          Asset Liability Management processes and responsibility are guided by formal policies that are needed for the smooth operation of any organization. Ensuring standard reports, risk profile evaluations, liquidity risk management, measuring and managing risk return and ‘what-if’ scenario simulations are a must for any Financial institution.
                <br />
                <br />
          Asset Liability Management (ALM) can be defined as a mechanism to address the risk faced by a bank due to a mismatch between assets and liabilities either due to liquidity or changes in interest rates. Liquidity is an institution’s ability to meet its liabilities either by borrowing or converting assets. Apart from liquidity, a bank may also have a mismatch due to changes in interest rates as banks typically tend to borrow short term (fixed or floating) and lend long term (fixed or floating).
                <br />
                <br />
          A comprehensive ALM policy framework focuses on bank profitability and long term viability by targeting the net interest margin (NIM) ratio and Net Economic Value (NEV), subject to balance sheet constraints. Significant among these constraints are maintaining credit quality, meeting liquidity needs and obtaining sufficient capital.
                <br />
                <br />
          An insightful view of ALM is that it simply combines portfolio management techniques (that is, asset, liability and spread management) into a coordinated process. Thus, the central theme of ALM is the coordinated, and not piecemeal, management of a bank’s entire balance sheet.
                <br />
                <br />
          ALM has evolved from the simple idea of maturity-matching of assets and liabilities across various time horizons into a framework that includes sophisticated concepts such as duration matching, variable rate pricing, and the use of static and dynamic simulation.
                <br />
                <br />
          VALOORES ALM provides complete multidimensional analysis of the balance sheet, incorporating interest rate risk, income simulation and market valuation using deterministic and stochastic modeling.
                <br />
                <br />
          It measures and models every loan, deposit, investment, and portfolio individually, using both deterministic and stochastic methods.
                <br />
                <br />
          It’s a consistent framework for gathering data, measuring risks, monitoring changes, and acting on decisions.
                <br />
                <br />
          Actively measuring and managing the interest rate and FX risk in the banking book (asset/liability management).
                <br />
                <br />
        </>
      ),
      list:['']
    },
      {
      title: "HOW CAN ALM BE MANAGED?",
      desc: 
      (
        <>
          Business cycles are becoming aggressive, and global ecosystems and third-party risks are becoming increasingly complex. Regulations are rapidly changing, and compliance enforcement is becoming more stringent. All these factors will continue to create the perfect storm for ALM. In light of these dynamics, adopting an agile ALM framework with a broader perspective is an important step in its management. The framework should set out the broad objectives of the bank’s asset/liability portfolio, as established by the Board. This framework governs all ALM policy constraints and helps address new situations where a policy does not yet exist.
          <br />
          <br />
          Although objectives may differ depending on the circumstances, environment or political dynamics of the bank, the ALM framework had to address the following minimum strategic objectives:
        </>
      ),
      list:['The bank should manage its asset cash flows in relation to its liability cash flows in a manner that contributes adequately to earnings and limits the risk to the financial margin, to the economic value of equity (EVE) (by managing both mark to market and net interest income) and to liquidity.',
            'Product terms, pricing and balance sheet mix must help manage banks’ product demands and the need to protect the equity of the bank.',
            'Most importantly, financial derivatives instruments must only be used to limit interest/profit rate risk and must never be used for speculative investment purposes.']
    },
      {
      title: "Governance and review system for ALM",
      desc: 
      (
        <>
          With Valoores in’ALM, any Bank can establish the required governance structure to manage risks emanating from ALM in accordance with the RAF limits. It also ensures that the bank maintains sufficient liquidity, to continuously review the bank’s liquidity developments, which are reported to the Board on a quarterly basis. A bank’s Board should annually review and approve the strategies, policies and practices related to the management of ALM as part of the Internal Capital and Liquidity Adequacy Assessment Process (ICLAAP). Based on the industry’s best practices, Valoores in’ALM tackles all Basel Pillar 1 and Pillar 2 (including IRRBB) risks, along with Stress Testing, at an enterprise level, and the Treasury middle office function. By taking this approach, it augments the vision of a CRO to provide a holistic view of risks at the enterprise level.
        </>
      ),
      list:['']
    },
      {
      title: "MISMATCH OF ASSETS AND LIABILITIES",
      desc: 
      (
        <>
          Banks manage the risks arising from ALM by matching various assets and liabilities according to the maturity pattern, or by matching the duration, by hedging and by securities. Valoores in’ ALM framework manages the risks related to changes in interest rates, the mix of balance sheet assets and liabilities, the holding of foreign currencies, and the use of derivatives. These risks are managed in a manner that contributes adequately to earnings while limiting risks to the financial margin.
          <br />
          <br />
          Valoores in’ALM helps banks to proactively manage asset–liability risk through an enterprise-wide Risk Appetite Framework, which sets limits on the asset and liability mix, as well as the level of interest rate and foreign currency risk to which the financial institution is willing to be exposed. Valooores in’ALM provides guidelines for the pricing, term and maturity of loans and deposits. The use of derivatives, if any, is also controlled, which should state among other things that derivatives must only be used to limit interest/profit rate risk and must never be used for speculative investment purposes, otherwise there is a potential risk of using them for short-term gains, where the risk versus reward is not justified. The banks should ensure they have sound business and financial practices by having risk and performance measurement techniques, and risk-based compensation practices, as well as risk management procedures that are repeatable, sustainable and can be implemented during stressed scenarios, and that are appropriate to the size and complexity of the bank’s balance sheet and operations.        
        </>
      ),
      list:['']
    },
      {
      title: "KEY RISKS MANAGED BY ALCO",
      desc: 
      (
        <>
          With Valoores in’ALM, Banks will have a holistic view on the liquidity situation and the mean to report the evaluation results to the Board, with adequate feedback to the relevant management frameworks.
          <br />
          <br />
          The following sections describe some of the key risks that are managed by ALCO within Valoores in’ALM. These objectives shall be pursued within the framework of the bank’s policies.    
        </>
      ),
      list:['']
    },
    {
    InterestRateRisk: [
      {
          title: "in'Data Governance",
          desc:'',
          list: [
            {
              subTitle: "",
              subDesc:'',
              subList: [
                "Manage Accounts",
                "Customer KYC (Corporate - Retail - SME - Housing)",
                "Collateral",
                "Payments - Transactions",
                "Definition of Default",
                "Staging and Thresholds",
                "Product Hierarchies",
                "Organizational Structure",
                "Dynamic Structures and Management by Status"
              ]
            }
          ]
        },
        {
          title: "in'Balance Sheet PPF - in'Income Statement",
          desc:'',
          list: [
            {
              subTitle: "Income Statement & Cash Flow industrialization",
              subDesc:'',
              subList: [
                "Consolidation and Drill Down",
                "Posting by BP"
              ]
            },
            {
              subTitle: "in'Balance Sheet consolidation and Production",
              subDesc:'',
              subList: [
                "Bottom Up and Top Down Approach",
                "Historical Data View",
                "Forward Looking Information"
              ]
            }
          ]
        },
        {
          title: "in'Ratios",
          desc:'',
          list: [
            {
              subTitle: "",
              subDesc:'',
              subList: [
                "Corporate and SME customers ratio generation",
                "Automation of Ratio Calculation and Generation",
                "Use of Ratios as Model Parameters in in'Analytics",
                "Bank's Ratios automation and generation"
              ]
            }
          ]
        },
        {
          title: "in'Analytics - Modeling",
          desc:'',
          list: [
            {
              subTitle: "",
              subDesc:'',
              subList: [
                "Multiple Statistical Models",
                "Holistic view over Performance and Historical Data",
                "Data Cleansing and Smoothing",
                "Breakpoint Removal - General Trend",
                "Lift and Model Parameters",
                "Quantification of qualitative information (macroeconomic factors, etc.)",
                "Forecast Execution and ECL automation"
              ]
            }
          ]
        },
        {
          title: "in'Analytics - Benchmarking",
          desc:'',
          list: [
            {
              subTitle: "",
              subDesc:'',
              subList: [
                "Benchmarking against ROC Curves",
                "LDAP and Wald Test",
                "Likelihood Ratios (T/F Negatives - T/F Positives)",
                "Accuracy Ratios (Random - Ideal models)",
                "Error Rate (%) generation - Confidence Intervals (α - β - δ)",
                "Self-Learning - Adaption models"
              ]
            }
          ]
        },
        {
          title: "in'GRC - Rules, Alerts, & Workflows",
          desc:'',
          list: [
            {
              subTitle: "",
              subDesc:'',
              subList: [
                "Simulation Creation",
                "Stress Testing",
                "Business Processes Automation",
                "ECL Posting on B/S through BP",
                "Alerts and Tasks by User and Role",
                "Validation Cycles"
              ]
            }
          ]
        },
        {
          title: "in'Via - Reports and KPRIs",
          desc:'',
          list: [
            {
              subTitle: "Canned Library",
              subDesc:'',
              subList: [
                "KPIs and KRIs",
                "IASB Reporting",
                "BDL Reporting"
              ]
            }
          ]
        }
      ],
      CapitalRisk: [
        {
          introduction: "While adoption is still in its early stages, it is important to understand the key changes to your business model after IFRS 9 altered many features of the previous IAS 39",
          title: "",
          heading: {
            title: "Why was IFRS 9 introduced?",
            titleList: [
                {
                
                    mainTitle: [
                    "New standard responds to the requests from the G20 for a forward-looking approach to loan loss provisioning following the financial crisis.",
                    "It builds on some of the themes outlined by banking supervisors in the Basel Committee on Banking Supervision's Guidance on credit risk and accounting for expected credit losses, issued in December 2015.",
                    "IFRS 9 will be one of the most momentous changes in accounting for many banks - considered by many to be bigger than the initial adoption of IFRS.",
                    "New impairment requirements pose 3 particular challenges."],
                    name: "Banks will need to address these challenges and the demand for transparent disclosures about impairment to maintain the confidence of investors and other stakeholders in their financial reporting.",
                    links: ["Increased complexity for preparers", "A diverse range of approaches and outcomes", "Time and effort to implement"]
                }
            ]
          },
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "The benefits IFRS 9 provides for banks, regulators and investors alike are huge, to list a few:",
                titleList: [
                    {
                    
                        mainTitle: [
                        "Reduced Complexity in Financial Reporting",
                        "Fortified Integrity & Trust in Financial Markets",
                        "Enhanced Global Comparability for Investors",
                        "Balance Sheet Closer to Economic Value",
                        "More Transparency in Income Statement",
                    ],
                        name: "",
                        links: [],
                    },
                ],
            },
            conclusion: "IFRS 9 addresses the accounting for financial instruments. It contains three main topics: classification and measurement of financial instruments, impairment of financial assets and hedge accounting. It will replace the earlier IFRS for financial instruments, IAS 39, when it becomes effective in 2018."
        },
        {
          introduction: "",
          title: "Classification and Measurement",
          heading: {
            title: "Classification and Measurement",
            titleList: '',
          },
        },
        {
          introduction: "",
          title: "Impairment",
          heading: {
            title: "Impairment",
            titleList: ["Change from an incurred loss model to a forward-looking expected loss model", "Effects of possible future credit loss events are considered directly"]
          },
        },
        {
          introduction: "",
          title: "Hedge Accounting",
          heading: {
            title: "Hedge Accounting",
            titleList: ''
          },
        }
      ],
      },
    
  ];
  
  export default InAssetManagement;
  