import Solution from '../../../img/solution.png'
const Ifrs9 = [
    {
      title: "in'IFRS9",
      overview: (
        <>
          IFRS 9 “Regulatory Tsunami” comes to succeed IAS 39 by introducing new requirements and models for financial institutions. Financial Instruments are now perceived differently while the guidelines are divided under 3 main pillars: Classification & Measurement, Impairment, and Hedge Accounting.
          VALOORES adopted IFRS 9 as the flagship product of its compliance and risk solutions focusing on the pain arising from the market from an implementation perspective, as well as the governance aspect of the challenges. While IFRS 9 cannot be considered as “another headache for the risk department”, many still tackle the new regulatory pressures from a risk perspective only.
          <br />
          To be able to “demystify” the IFRS 9 guidelines and apply a smooth transition between IAS39 incurred models and IFRS 9 expected, forward-looking models, one must embrace IFRS 9 as a discipline rather than a set of new rules. VALOORES did exactly that to come up with an end to end solution set and creating the “IFRS 9 Ecosystem” simplifying the roadmap to implementation.
          <br />
          With January '18 around the corner, many banks and financial institutions are ready to invest in IFRS 9 without accurately studying their IFRS 9 needs. IFRS 9 comes as a subjective judgmental compliance and can be subjectively implemented at each entity depending on their needs and how “IFRS 9 ready” the entity is.
        </>
      ),
      benefits: [
        "Reduced Complexity in Financial Statements through avoiding the mistakes that are most often stumbled upon in the market. This leads to a more volatile, solid, and clear Balance Sheet and Income Statement, this will be even more enhanced with VALOORES' vast and detailed coverage of the financial statements.",
        "Increase in integrity and trust of financial institutions in the financial markets they are taking action in.",
        "Easier rebalancing or discontinuity of hedging, this too while respecting the required criteria situated on hedges.",
        "Improved credit quality through improved initial recognition (Impairment) and deterioration.",
        "VALOORES' coverage of the financial statements through it's In' Profit will stimulate the introduction of the standards posed by IASB's IFRS 9 on the market. An easy and simple interface will better assess the regulatory compliances/standards of the IFRS 9.",
        "Credit appraisal and pre-sanction processes: Going forward, financial institutions are expected to tighten their credit appraisal processes. Given the requirement of forward looking expected credit losses, financial institutions are about to look at further strengthening of their underwriting standards and credit appraisal processes.",
        "Closely monitor assets: Financial institutions are expected to closely monitor the “watch-list” category of assets that are likely to slip into the category of “under-performing or credit impaired” to avoid assets being classified into “stage 2 or 3”. In addition, they are expected to make all efforts to ensure that the assets classified into stage 1 as of last reporting date do not migrate to stage 2 or 3 owing to the Lifetime ECL impact.",
        "Capital & business planning: IFRS 9 impairment guidelines have a direct effect on the retained earnings and thus, the bank's capital. Given the capital constraints, it is quite likely that each line of business within an institution be mandated to efficiently utilize the scarce capital resources allocated to them and maximize profits.",
        "Being a crucial part of the risk and finance transformation journey of an institution, these guidelines will also bring about strong coordination between risk and finance teams.",
        "IFRS 9 guidelines are expected to indirectly drive sound documentation of internal credit risk management policies and procedures.",
        "Better transparency to the investors, public and other stakeholders in terms of enhanced market disclosures."
      ],
      conclusion: (
        <>
          In conclusion, IFRS 9 with its forward-looking impairment model is expected to further augment the efficiency of the banking system. It can be concluded that this impairment model has served its main purpose if another financial crisis is averted and thereby the interests of shareholders and general public are safeguarded. Only time can prove the effectiveness of the revised set of guidelines.
          <br /><br />
          Although IFRS 9 is an accounting standard, it is expected to play its part in strengthening the financial institution's credit risk management system in particular and bringing about a sound banking system at large.
        </>
      ),
      features: [
        {
          title: "in'Data Governance",
          list: [
            {
              subTitle: "",
              subList: [
                "Manage Accounts",
                "Customer KYC (Corporate - Retail - SME - Housing)",
                "Collateral",
                "Payments - Transactions",
                "Definition of Default",
                "Staging and Thresholds",
                "Product Hierarchies",
                "Organizational Structure",
                "Dynamic Structures and Management by Status"
              ]
            }
          ]
        },
        {
          title: "in'Balance Sheet PPF - in'Income Statement",
          list: [
            {
              subTitle: "Income Statement & Cash Flow industrialization",
              subList: [
                "Consolidation and Drill Down",
                "Posting by BP"
              ]
            },
            {
              subTitle: "in'Balance Sheet consolidation and Production",
              subList: [
                "Bottom Up and Top Down Approach",
                "Historical Data View",
                "Forward Looking Information"
              ]
            }
          ]
        },
        {
          title: "in'Ratios",
          list: [
            {
              subTitle: "",
              subList: [
                "Corporate and SME customers ratio generation",
                "Automation of Ratio Calculation and Generation",
                "Use of Ratios as Model Parameters in in'Analytics",
                "Bank's Ratios automation and generation"
              ]
            }
          ]
        },
        {
          title: "in'Analytics - Modeling",
          list: [
            {
              subTitle: "",
              subList: [
                "Multiple Statistical Models",
                "Holistic view over Performance and Historical Data",
                "Data Cleansing and Smoothing",
                "Breakpoint Removal - General Trend",
                "Lift and Model Parameters",
                "Quantification of qualitative information (macroeconomic factors, etc.)",
                "Forecast Execution and ECL automation"
              ]
            }
          ]
        },
        {
          title: "in'Analytics - Benchmarking",
          list: [
            {
              subTitle: "",
              subList: [
                "Benchmarking against ROC Curves",
                "LDAP and Wald Test",
                "Likelihood Ratios (T/F Negatives - T/F Positives)",
                "Accuracy Ratios (Random - Ideal models)",
                "Error Rate (%) generation - Confidence Intervals (α - β - δ)",
                "Self-Learning - Adaption models"
              ]
            }
          ]
        },
        {
          title: "in'GRC - Rules, Alerts, & Workflows",
          list: [
            {
              subTitle: "",
              subList: [
                "Simulation Creation",
                "Stress Testing",
                "Business Processes Automation",
                "ECL Posting on B/S through BP",
                "Alerts and Tasks by User and Role",
                "Validation Cycles"
              ]
            }
          ]
        },
        {
          title: "in'Via - Reports and KPRIs",
          list: [
            {
              subTitle: "Canned Library",
              subList: [
                "KPIs and KRIs",
                "IASB Reporting",
                "BDL Reporting"
              ]
            }
          ]
        }
      ],
      caseStudy: [
        {
          introduction: "While adoption is still in its early stages, it is important to understand the key changes to your business model after IFRS 9 altered many features of the previous IAS 39",
          title: "",
          heading: {
            title: "Why was IFRS 9 introduced?",
            titleList: [
                {
                
                    mainTitle: [
                    "New standard responds to the requests from the G20 for a forward-looking approach to loan loss provisioning following the financial crisis.",
                    "It builds on some of the themes outlined by banking supervisors in the Basel Committee on Banking Supervision's Guidance on credit risk and accounting for expected credit losses, issued in December 2015.",
                    "IFRS 9 will be one of the most momentous changes in accounting for many banks - considered by many to be bigger than the initial adoption of IFRS.",
                    "New impairment requirements pose 3 particular challenges."],
                    name: "Banks will need to address these challenges and the demand for transparent disclosures about impairment to maintain the confidence of investors and other stakeholders in their financial reporting.",
                    links: ["Increased complexity for preparers", "A diverse range of approaches and outcomes", "Time and effort to implement"]
                }
            ]
          },
          conclusion: ''
        },
        {
          introduction: "",
          title: "",
          heading: {
            title: "The benefits IFRS 9 provides for banks, regulators and investors alike are huge, to list a few:",
                titleList: [
                    {
                    
                        mainTitle: [
                        "Reduced Complexity in Financial Reporting",
                        "Fortified Integrity & Trust in Financial Markets",
                        "Enhanced Global Comparability for Investors",
                        "Balance Sheet Closer to Economic Value",
                        "More Transparency in Income Statement",
                    ],
                        name: "",
                        links: [],
                    },
                ],
            },
            conclusion: "IFRS 9 addresses the accounting for financial instruments. It contains three main topics: classification and measurement of financial instruments, impairment of financial assets and hedge accounting. It will replace the earlier IFRS for financial instruments, IAS 39, when it becomes effective in 2018."
        },
        {
          introduction: "",
          title: "Classification and Measurement",
          heading: {
            title: "Classification and Measurement",
            titleList: '',
          },
          conclusion: (
            <>
              Broadly, only two classification categories exist, Fair Value and Amortized Cost. Principle-based classification of financial instruments to one of the categories based on a Business Model Test (--{'>'} aimed to sell, collect contractual CFs or both?) and a Cash Flow Test (--{'>'} what are the contractual CF characteristics?) IFRS 9 retained the concept of fair value option from IAS 39, but revised the criteria for financial assets.
              <br /><br />
              Under a fair value option, an asset or liability that would otherwise be reported at amortized cost or FVOCI can use FVPL instead. Under this option, the instrument is reported at FVOCI similar to FVOCI for debt. The gain or loss is never reported in profit and loss, but rather remains in other comprehensive income.
            </>
          )
        },
        {
          introduction: "",
          title: "Impairment",
          heading: {
            title: "Impairment",
            titleList: ["Change from an incurred loss model to a forward-looking expected loss model", "Effects of possible future credit loss events are considered directly"]
          },
          conclusion: (
            <>
              At each reporting date financial instruments are put into one of three “buckets” based on their credit quality and risk
              <br /><br />
              The bucket designation of each instrument determines how much expected credit loss is recognized on the balance sheet (12-months vs. life-time expected credit loss), as well as on what basis interest revenue is calculated (gross vs. net carrying amount)
            </>
          )
        },
        {
          introduction: "",
          title: "Hedge Accounting",
          heading: {
            title: "Hedge Accounting",
            titleList: ''
          },
          conclusion: (
            <>
              IFRS 9 fosters a closer alignment of accounting and risk management
              <br /><br />
              This enables financial statement readers a better understanding of how effective the applied risk management and hedging strategies are.
              <br /><br />
              Hedge Accounting aims to mitigate the impact of the non-controllable risks in the performance of an entity. The hedge accounting requirements in IFRS 9 are optional. If certain eligibility and qualification criteria are met, hedge accounting allows an entity to reflect risk management activities in the financial statements by matching gains or losses on financial hedging instruments with losses or gains on the risk exposures they hedge.
              <br /><br />
              VALOORES' take on IFRS 9 is nothing short of a breakthrough in today's market. We offer a set of solutions which tackles IFRS 9 holistically focusing on the main phases of the standard while tackling Impairment, Classification, Measurement, Hedge Accounting, and Expected Loss Calculation & Provisioning throughout VALOORES' expert solutions in CRM, Credit Scoring, Analytics, Profitability, Data Governance and GRC.
            </>
          )
        }
      ],
      solution: Solution
    }
  ];
  
  export default Ifrs9;
  