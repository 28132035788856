import React from 'react'
import Carousel from './Carousel'
import Introduction from './Introduction'
import Paralax from './Paralax'
import Webinars from './Webinars'
import Services from './Services'

const Academy = () => { 
  return (
    <>
        <Carousel />
        <Introduction />
        <div className="my-5"></div>
        <Paralax />
        <div className="my-5"></div>
        <Webinars />
        <div className="my-5"></div>
        <Services />
        <div className="my-5"></div>
    </>
  )
}

export default Academy