import InProfit1 from '../../../../img/inprofit/inprofit1.jpg'
import InProfit2 from '../../../../img/inprofit/inprofit2.jpg'
import InProfit3 from '../../../../img/inprofit/inprofit3.jpg'
import InProfit4 from '../../../../img/inprofit/inprofit3.jpg'
import InProfit5 from '../../../../img/inprofit/inprofit3.jpg'
import InProfit6 from '../../../../img/inprofit/inprofit6.jpg'
import InProfit7 from '../../../../img/inprofit/inprofit3.jpg'
import InProfit8 from '../../../../img/inprofit/inprofit8.jpg'
import InProfit9 from '../../../../img/inprofit/inprofit9.jpg'
import InProfit10 from '../../../../img/inprofit/inprofit10.jpg'
import InProfit11 from '../../../../img/inprofit/inprofit11.jpg'
import InProfit12 from '../../../../img/inprofit/inprofit12.jpg'

const InProfit = [
    {
      img: InProfit1,
      title: "in'Debt Collection and Recovery",
      desc: "VALOORES in'Debt Collection and Recovery helps Financial Institutions Controlling and Managing Delinquencies of Lending Business through a series of Proactive Actions & Supervision.",
      link: "../banking/inProfit?variable=DebtCollection",
      button: 'yes',
    },
    {
      img: InProfit2,
      title: "in'Balance Sheet",
      desc: "VALOORES Financial services in'Balance Sheet exposes in detailed form all activities related to subordinates, branches, head office and affiliates, allowing professional decisions relying on viable...",
      link: "../banking/inProfit?variable=BalanceSheet",
      button: 'yes',
    },
    {
      img: InProfit3,
      title: "in'Balance Sheet Planning",
      desc: "VALOORES Financial Services in' Balance Sheet Planning – PPF allows product planning managers to simultaneously forecast both new business volumes and the margins. Using the VALOORES Financial Services Analytical Applications...",
      link: "../banking/inProfit?variable=BalanceSheetPlanning",
      button: 'yes',
    },
    {
      img: InProfit4,
      title: "in'Income Statement",
      desc: "in' Income Statement enables the user to drill down to the least leaf level while viewing past and present figures and having the ability to view forecasted positions...",
      link: "../banking/inProfit?variable=IncomeStatement",
      button: 'yes',
    },
    {
      img: InProfit5,
      title: "in'Cash Flow",
      desc: "in' Cashflow shows stakeholders the flow of cash in and out of the business based on current operations results and changes in the balance sheet accounts...",
      link: "../banking/inProfit?variable=InCashFlow",
      button: 'yes',
    },
    {
      img: InProfit9,
      title: "in'Asset Liability Management",
      desc: "in'Asset Liability Management can be defined as a mechanism to address the risk faced by a bank due to a mismatch between assets and liabilities either due to liquidity or changes in interest rates...",
      link: "../banking/inProfit?variable=InAssetManagement",
      button: 'yes',
    },
    {
      img: InProfit6,
      title: "in'Funds Transfer Pricing",
      desc: "VALOORES' in'Profitability Management tackles market challenges by presenting the right tools for measuring profitability, and it also offers a user driven profitability modeling,...",
      link: "../banking/inProfit?variable=InFunds",
      button: 'yes',
    },
    {
      img: InProfit7,
      title: "in'Revenue Management and Billing",
      desc: "Service providers globally rely on in'Revenue Management and Billing to build strong brands and improve profitability.",
      link: "../banking/inProfit?variable=InRevenue",
      button: 'yes',
    },
    // {
    //   img: InProfit8,
    //   title: "in'Funds transfer Pricing",
    //   desc: "in'Funds Transfer Pricing builds on VALOORES history as the industry's first matched maturity funds transfer pricing application, enabling financial institutions to determine the spread earned on assets and liabilities,...",
    //   link: "https://banking.valoores.com/fundstransferpricing.php",
    //   button: 'yes',
    // },
    {
      img: InProfit10,
      title: "in'Wealth Management",
      desc: "“With in'Wealth Management, banks can plan, record, track and manage the overall wealth of a customer across a range of asset classes and instruments.”",
      link: "../banking/inProfit?variable=InWealthManagement",
      button: 'yes',
    },
    {
      img: InProfit11,
      title: "in'Ratios",
      desc: "“in'Ratios measure companies’ operational efficiency, liquidity, stability and profitability, giving investors more relevant information than raw financial data.”",
      link: "../banking/inProfit?variable=InRatios",
      button: 'yes',
    },
    {
      img: InProfit12,
      title: "in'Branch profitability",
      desc: "There are two important informative purposes for measuring branch profitability. Firstly, knowledge of what costs and revenues would be affected if a particular branch were to be closed is crucial....",
      link: "../banking/inProfit?variable=InBranch",
      button: 'yes',
    },
  ]

  export default InProfit