import React from 'react'
import './TheLogo.scss'
import logo from '../../img/full_logoC.png'
import logoBg from '../../img/logo_bg.jpg'
import valoorescompany from '../../img/valoorescompany.jpg'
import logoVideo from '../../img/videos/valooreslogoanimation.mp4'

const TheLogo = () => {
  return (
    <>
      <div className="logo_banner">
          <div className="logo_banner-bg d-flex align-items-center justify-content-center" style={{position: 'relative', backgroundImage: `url(${logoBg})`}}>
              <div className="container">
                <div className="row">
                  <div className="logo_shift d-flex align-items-center justify-content-center" style={{overflowY: 'hidden'}}>
                   <h1 style={{textShadow: '4px 4px #0071c2'}}>The Logo</h1>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <div className="mt-0 p-3" style={{background: '#f4f4f4'}}>
        <div className="container">
          <div className="row">
            <video autoPlay muted loop>
              <source src={logoVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  )
}

export default TheLogo