import JesusRahal from '../img/employees/jesusRahal.png'
import ZiadJreij from '../img/employees/ZiadJreij.png'
import RoodySaykali from '../img/employees/RoodySaykali.png'
import EliasTaouk from '../img/employees/EliasTaouk.png'
import CharbelTarabay from '../img/employees/charbelTarabay.png'
import ElieAsmar from '../img/employees/elieAsmar.png'
import ElissaSabbagh from '../img/employees/elissaSabbagh.png'
import RitaElHashem from '../img/employees/ritaElHashem.png'
import WissamSemaan from '../img/employees/wissamSemaan.png'
import JoeNakhle from '../img/employees/joeNakhle.png'
// import AhmadHakim from '../img/employees/AhmadHakim.png'
import anthonyAbiRizk from '../img/employees/anthonyAbiRizk.png'
import anthonyIrani from '../img/employees/anthonyIrani.png'
import BalawharAlKontar from '../img/employees/BalawharAlKontar.png'
import CarolineThebian from '../img/employees/CarolineThebian.png'
import CelineRahme from '../img/employees/CelineRahme.png'
import charbelTouma from '../img/employees/charbelTouma.png'
import CharbelAtallah from '../img/employees/CharbelAtallah.png'
import ChristineTaouk from '../img/employees/ChristineTaouk.png'
import Christopheryannieh from '../img/employees/Christopheryannieh.png'
import DanyChahine from '../img/employees/DanyChahine.png'
import DibHanna from '../img/employees/DibHanna.png'
import elietawk from '../img/employees/elietawk.png'
import elissamakhoul from '../img/employees/elissamakhoul.png'
import FadyAlAwar from '../img/employees/FadyAlAwar.png'
import GhadyKozma from '../img/employees/GhadyKozma.png'
import GhinwaLozom from '../img/employees/GhinwaLozom.png'
import HabibTawk from '../img/employees/HabibTawk.png'
import IbrahimKhyar from '../img/employees/IbrahimKhyar.png'
// import JackAbdo from '../img/employees/JackAbdo.png'
import JanaZeghondy from '../img/employees/JanaZeghondy.png'
import JeanPierreChalouhy from '../img/employees/JeanPierreChalouhy.png'
import JeanKeyrouz from '../img/employees/JeanKeyrouz.png'
import joeNakhle2 from '../img/employees/joeNakhle2.png'
import JoeElSokhon from '../img/employees/JoeElSokhon.png'
import JoelleElBazi from '../img/employees/JoelleElBazi.png'
import JosephAsslan from '../img/employees/JosephAsslan.png'
import JosephCorban from '../img/employees/JosephCorban.png'
import MalakKanaan from '../img/employees/MalakKanaan.png'
import MarcelinoAtallah from '../img/employees/MarcelinoAtallah.png'
import MarcelinoKeyrouz from '../img/employees/MarcelinoKeyrouz.png'
import marinaneElGhawi from '../img/employees/marinaneElGhawi.png'
import NadineNicolas from '../img/employees/NadineNicolas.png'
import NathalieNicolas from '../img/employees/NathalieNicolas.png'
import nicolasSalloum from '../img/employees/nicolasSalloum.png'
import NourHarb from '../img/employees/NourHarb.png'
import Olivertawk from '../img/employees/Olivertawk.png'
import PamelaAslan from '../img/employees/PamelaAslan.png'
import PamelaAbiKhalil from '../img/employees/PamelaAbiKhalil.png'
import PedroMhanna from '../img/employees/PedroMhanna.png'
import RamyDeeb from '../img/employees/RamyDeeb.png'
import RickAzzi from '../img/employees/RickAzzi.png'
import RonyWeter from '../img/employees/RonyWeter.png'
import wissamRaji from '../img/employees/wissamRaji.png'
import zaherNajib from '../img/employees/zaherNajib.png'
import maroussiaKhoury from '../img/employees/maroussiaKhoury.png'
import LuigiElChami from '../img/employees/LuigiElChami.png'
import DanaChahine from '../img/employees/DanaChahine.png'
import DimitriChami from '../img/employees/DimitriChami.png'
import DianaElAlam from '../img/employees/DianaElAlam.png'
import ZeinaMousallem from '../img/employees/ZeinaMousallem.png'
import vanessaHobeich from '../img/employees/vanessaHobeich.png'
import RachelleElArraj from '../img/employees/RachelleElArraj.png'
import YoussefMelhemTawk from '../img/employees/YoussefMelhemTawk.png'
import michellaKheir from '../img/employees/michellaKheir.png'
import NourFarhat from '../img/employees/nourFarhat.png'
import AntonioJabbour from '../img/employees/AntonioJabbour.png'
import cedricElBazi from '../img/employees/cedricElBazi.png'
import wadiaaGerges from '../img/employees/wadiaaGerges.png'
import riadZeitoun from '../img/employees/riadZeitoun.png'

const EmployeeArray =
[
    {
    category:'Lab',
    title:'Oracle Developer',
    desc: 'Oracle Developer',
    name: 'Balawhar Al-Kontar',
    img: BalawharAlKontar,

    },
    {
    category:'Lab',
    title:'Oracle Developer',
    desc: 'Oracle Developer',
    name: 'Christina Taouk',
    img: ChristineTaouk,

    },
    {
    category:'Lab',
    title:'Integration',
    desc: 'Head of department',
    name: 'Amany El Hindi',
    img: '',

    },
    {
    category:'Lab',
    title:'Integration',
    desc: 'Integration',
    name: 'Antonio Jabbour',
    img: AntonioJabbour,

    },
    {
    category:'Lab',
    title:'Oracle Developer',
    desc: 'Oracle Developer',
    name: 'Mariane Ghawi',
    img: marinaneElGhawi,

    },

    {
    category:'Lab',
    title:'Oracle Developer',
    desc: ' Oracle Developer',
    name: 'Nicolas Salloum',
    img: nicolasSalloum,

    },
    {
    category:'Lab',
    title:'DBA',
    desc: 'DBA',
    name: 'Habib Tawk',
    img: HabibTawk,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Caroline Thebian',
    img: CarolineThebian,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Luigi El Chami',
    img: LuigiElChami,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Pedro Mhanna',
    img: PedroMhanna,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Fady Al Awar',
    img: FadyAlAwar,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Christopher Yannieh',
    img: Christopheryannieh,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Celine Rahme',
    img: CelineRahme,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Charbel Touma',
    img: charbelTouma,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Team Leader',
    name: 'Joseph Asslan',
    img: JosephAsslan,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Dany Chahine',
    img: DanyChahine,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Joe Nakhle',
    img: joeNakhle2,

    },
    {
    category:'Lab',
    title:'Analytics',
    desc: 'Analytics',
    name: 'Riad Zeitoun',
    img: riadZeitoun,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Oliver Tawk',
    img: Olivertawk,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Marcelino Keyrouz',
    img: MarcelinoKeyrouz,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Jana Zeghondy',
    img: JanaZeghondy,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Joseph Corban',
    img: JosephCorban,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Jean Pierre Chalouhy',
    img: JeanPierreChalouhy,

    },
    // {
    // category:'Lab',
    // title:'Software',
    // desc: 'Software Developer',
    // name: 'Jack Abdo',
    // img: JackAbdo,

    // },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Ghady Kozma',
    img: GhadyKozma,

    },
    // {
    //     category:'Lab',
    //     title:'Software',
    //     desc: 'Software Developer',
    //     name: 'Ahmad El Hakim',
    //     img: AhmadHakim,
    // },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Georges Tannous',
    img: '',

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Elie Taouk',
    img: elietawk,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Elissa Makhoul',
    img: elissamakhoul,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Cedric El Bazi',
    img: cedricElBazi,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Charbel Attallah',
    img: CharbelAtallah,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Zaher najib',
    img: zaherNajib,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Rony Weter',
    img: RonyWeter,

    },
    {
    category:'Lab',
    title:'Integration',
    desc: ' ',
    name: 'Rick Azzi',
    img: RickAzzi,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Nathalie Nicolas',
    img: NathalieNicolas,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Software Developer',
    name: 'Nadine Nicolas',
    img: NadineNicolas,

    },
    {
    category:'Lab',
    title:'Software',
    desc: 'Head of Department',
    name: 'Marcelino Attallah',
    img: MarcelinoAtallah,

    },
    {
    category:'Lab',
    title:'ISE',
    desc: 'Head Of Department',
    name: 'Charbel Tarabay',
    img: CharbelTarabay,

    },
    {
    category:'Lab',
    title:'ISE',
    desc: 'Senior ISE',
    name: 'Roody Saykali',
    img: RoodySaykali,

    },
    {
        category:'Lab',
        title:'ISE',
        desc: '',
        name: 'Anthony Abi Rizk',
        img: anthonyAbiRizk,
    },
    {
    category:'Lab',
    title:'ISE',
    desc: 'ISE',
    name: 'Anthony Irani',
    img: anthonyIrani,

    },
    {
    category:'Lab',
    title:'ISE',
    desc: 'ISE',
    name: 'Jesus Rahal',
    img: JesusRahal,

    },
    {
    category:'Marketing',
    title:'Business Developement',
    desc: 'Head Of Department',
    name: 'Elias Hamid Taouk',
    img: EliasTaouk,

    },
    {
    category:'Marketing',
    title:'Multi-Channel Communication',
    desc: 'Team Leader',
    name: 'Joyce Hamid',
    img: '',

    },
    {
    category:'Marketing',
    title:'Multi-Channel Communication',
    desc: 'Web Developer',
    name: 'Elie Asmar',
    img: ElieAsmar,

    },
    {
    category:'Marketing',
    title:'Multi-Channel Communication',
    desc: 'Web Developer',
    name: 'Rita El Hachem',
    img: RitaElHashem,

    },
    {
    category:'Marketing',
    title:'Multi-Channel Communication',
    desc: 'Video Editor',
    name: 'Elissa Sabagh',
    img: ElissaSabbagh,

    },
    {
    category:'Marketing',
    title:'Multi-Channel Communication',
    desc: 'Web Developer',
    name: 'Wissam Semaan',
    img: WissamSemaan,

    },
    {
    category:'Marketing',
    title:'Multi-Channel Communication',
    desc: 'Web Developer',
    name: 'Joe Nakhle',
    img: JoeNakhle,

    },
    {
    category:'Marketing',
    title:'Business Developement',
    desc: 'Senior',
    name: 'Pamela Aslan',
    img: PamelaAslan,

    },
    {
    category:'Marketing',
    title:'Sales',
    desc: '',
    name: 'Ghinwa Lozom',
    img: GhinwaLozom,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Team Leader',
    name: 'Pamela Abi khalil',
    img: PamelaAbiKhalil,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Joe El Sokhon',
    img: JoeElSokhon,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Team Leader',
    name: 'Youssef Melhem',
    img: YoussefMelhemTawk,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Team Leader',
    name: 'Rachelle Arraj',
    img: RachelleElArraj,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Ibrahim hiyar',
    img: IbrahimKhyar,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Team Leader',
    name: 'Georges Jaalouki',
    img: '',

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Marina Badawi',
    img: '',

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Michela Kheir',
    img: michellaKheir,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Nour Farhat',
    img: NourFarhat,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Nour Harb',
    img: NourHarb,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Dib Hanna',
    img: DibHanna,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Jean Keyrouz',
    img: JeanKeyrouz,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Senior',
    name: 'Malak kanaan',
    img: MalakKanaan,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Team Leader',
    name: 'Joelle El Bazzi',
    img: JoelleElBazi,

    },
    {
    category:'Marketing',
    title:'Business Coordinator',
    desc: 'Business Coordinator',
    name: 'Ramy Deeb',
    img: RamyDeeb,

    },
    {
    category:'People/Hr',
    title:'Academy',
    desc: 'Head Of Department',
    name: 'Maroussia Khoury',
    img: maroussiaKhoury,

    },
    {
    category:'People/Hr',
    title:'Recruitment',
    desc: 'Recruitment',
    name: 'Ziad Jreij',
    img: ZiadJreij,

    },
    {
    category:'Admin',
    title:'Admin',
    desc: 'Admin',
    name: 'Diana Taouk',
    img: DianaElAlam,

    },
    {
    category:'Admin',
    title:'Admin',
    desc: 'Admin',
    name: 'Wadiaa Gerges',
    img: wadiaaGerges,

    },
    {
    category:'Admin',
    title:'Admin',
    desc: 'Admin',
    name: 'Zeina moussalem',
    img: ZeinaMousallem,

    },
    {
    category:'Admin',
    title:'Finance',
    desc: 'Finance',
    name: 'Vanessa hobeiche',
    img: vanessaHobeich,

    },
    {
    category:'Advisory',
    title:'Board',
    desc: 'Head Of Business Delivery',
    name: 'Dana Chahine',
    img: DanaChahine,

    },
    {
    category:'Advisory',
    title:'Consultant',
    desc: 'Senior',
    name: 'wissam Raji',
    img: wissamRaji,

    },
    // {
    // category:'Advisory',
    // title:'Consultant',
    // desc: '',
    // name: 'Tony Kiwan',
    // img: '',

    // },
    // {
    // category:'Advisory',
    // title:'Consultant',
    // desc: '',
    // name: 'Marc',
    // img: '',

    // },
    {
    category:'Partners',
    title:'Partner',
    desc: 'Managing Partner',
    name: 'Dimitri Chami',
    img: DimitriChami,

    },
]
export default EmployeeArray