import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import partnersArray from './partnersArray';
import './Partners.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Partners = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      };
      

  return (
    <div className=''>
        <div className='p-3 w-100' style={{background: '#051C2C'}}>
            <h1 className='font--poppins text-white text-center' style={{fontSize: '2.5rem'}}>Trusted By Leaders</h1>
        </div>
        <Slider {...settings}>
            {partnersArray.map((partner, index) => (
               <div key={index} className='container mt-4'>
                    <Link to={partner.link} target='_blank'>
                        <img src={partner.image} className='partner_img w-75' alt="" />
                    </Link>
               </div> 
            ))}
        </Slider>
    </div>
  )
}

export default Partners