import Img1 from '../../img/Bi/img1.jpg'
import Img2 from '../../img/Bi/img2.jpg'
import Img3 from '../../img/Bi/img3.jpg'

  const BiArray = [
    {
      img: Img1,
      title: 'Key Benefits',
      desc: (
        <ul>
          <li>Identify Trends, Discover Opportunities & Anticipate Emerging Risks</li>
            <li>Gain Insights on Key Parameters for Better Decision Making</li>
            <li>Multi-purpose, Business intelligence dashboard.</li>
            <li>Unifying data sources</li>
            <li>Avoiding duplicate information</li>
            <li>Information Transparency for Higher Traceability</li>
            <li>Multilanguage Application Enabling Diversity</li>
            <li>Efficient workforce management</li>
            <li>Transforming Data to Knowledge</li>
        </ul>
      ),
    },
    {
      img: Img2,
      title: 'Dashboard Builder',
      desc: (
        <ul>
          <li>Create Alerts and Tasks</li>
          <li>Personalized dashboards</li>
          <li>Edit on the fly</li>
          <li>Define unique user experience</li>
          <li>Manage secured actions and roles</li>
        </ul>
      ),
    },
    {
      img: Img3,
      title: 'Application Builder',
      desc: (
        <ul>
           <li>Easily Build and Access KPRIs</li>
            <li>Define KPRIs parameters</li>
            <li>Add, Edit or Delete, Search & Favorite Functions</li>
            <li>Chart Definition & Customize attributes</li>
        </ul>
      ),
    },
  ]

  export default BiArray

