
const inRetail = [
    {
      title: "Overview",
      desc:
      (
        <>
          Gone are the days when consumers were significantly loyal to just one bank. Nowadays, it is customary for consumers to engage with multiple banks for their mortgage, checking/savings accounts, lines of credit, and so on. valoores in' Retail Customer Analytics helps retail banking institutions ensure that marketing dollars are invested wisely and the customers with the most potential to be profitable are targeted through the right channels.
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
         ' Proactively gain insight into understanding the target customer wants and needs',
         ' Enable successful and informed marketing decisions with the ability to plan, manage, and track marketing investments',
         ' Enhance wallet-share through better cross-sell targeting and increased customer engagement',
         ' Drive marketing efficiency and optimization through relevant and customized communications',
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
          'Understand customer trends across key focus areas such as sales, balances, and engagement',
          'Optimize campaigns with metrics including responses, opt-outs, and customer distribution across target segments',
          'Utilize sophisticated predictive modeling techniques to identify the likely behavior of customers',
          'Ensure targeted marketing with pre-built cross-sell/up-sell scores along with channel and product propensity scores',
          'Utilize list scoring and customer segmentation to present relevant products to the customer',
          'Optimize investments through detailed channel analysis including bank branches, on-line, phone',
          'Monitor campaign profitability across customer segments and channels with campaign performance tracking',
            
        ],
      },
      
  ];
  
  export default inRetail;
  