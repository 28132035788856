

const InKYE = [
    {
      title: "Overview",
      desc:
      (
        <>
        Banks and Financial institutions are constantly working to recognize and identify customers. Know your customer (KYE) policies mandated by regulators globally are becoming increasingly important to prevent identity theft, fraud, money laundering and terrorist financing. Banks and Institutions are looking to their KYE controls to not only meet regulatory requirements, but also to cut down their fraud losses and improve overall customer relationships across their products and channels.
        <br/>
        VALOORES Know Your Customer allows financial institutions to meet regulatory requirements, improves customer relationships, and provides cost effective measures to identify and prevent financial crimes.
        VALOORES Know Your Customer offers an efficient and cost effective way for Banks & institutions to conduct enhanced due diligence processes using the underlying case management platform’s investigation capabilities.
        Additionally, KYE related information also helps relationship managers to maintain a profitable and healthy relationship with their customer base. By providing access to KYE information organizations can empower front line relationship managers with a 360o view of the customer profile. VALOORES Know Your Customer solution plays a critical role in achieving these objectives.
        <br/>
        VALOORES solution is designed to interrelate with the following Lines:
        <br/>
        <ul>
            <li>Real Time Risk Rating Interface: This is used to enhance the account and customer on-boarding process</li>
            <li>Customer and Account Reference Data Interface: this is used to capture the customer and account information for further processing of risk assessments</li>
            <li>Watch List Interface: The watch list is used to screen customers against internal and external watch lists uploaded or requested for the banks</li>
            <li>AML and Fraud Alert Interface: This is used to initiate and accelerated re-review of the customer as well as used as a factor that contributes to the overall risk score</li>
        </ul>
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
            "Facilitates Customer and Account On-Boarding Processes",
           " Access to comprehensive business data to improve decision making",
            "Ability to review risk parameter attributes which contributed to the risk score",
            "Easy to use and intuitive user interface to review and record investigation",
            "Support for risk score override",
            "User initiated due diligence of components such as watch list scanning",
            "Support for Manual Case Creation with user provided risk score",
            "Comprehensive audit history to understand past customer history and the risk assessment output",
            "Integration with third party providers of negative news, ID verification services reduces time to investigate a customer",
            "Role based access to information helps cross-jurisdiction deployments",
            "Continuous monitoring of the customer population to ensure up-to-date assessment of risk",
            "Accelerated re-review risk scoring to escalate a customer profile when a triggering activity occurs based on alert generation or through change in customer information",
           " Standard risk scoring models to cover standard risk scoring parameters like geography, demographics, watch list profile, identity verification, negative news etc.",
           " Support for different businesses like retail, corporate, wholesale, etc.",
            "Real-time risk rating for facilitating account/customer on-boarding processes",
            "Multi-country, multi-jurisdiction support",
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
            "Comprehensive and extensible risk scoring function",
            "Continuous customer monitoring for risk throughout the life of the customer relationship",
            "Real-time and batch interfaces to integrate with other systems",
           " An advanced, enhanced due diligence process to enable quality investigations without increasing costs",
           " Meet Local and global AML/CFT regulatory requirements with standard risk scoring models",
           " Employ efficient due diligence processes and enable multi-jurisdiction, multi country customer risk scoring using a single instance",
           " Improve account and customer on-boarding processes",
           " Reduce customer risk exposure on the front lines and enhance customer relationships",
           " Achieve end to end compliance by leveraging core with VALOORES applications",
           " Utilize standard interfaces to exchange information with third party systems",
            
        ],
      },
      {
        title: "",
        desc:
      (
        <>
       VALOORES Know Your Customer utilizes sophisticated information exchange formats to easily maintain and share data across existing systems, enabling financial institutions to perform real-time checks against their new customer. These checks help the customer/account on-boarding process by identifying the risk exposure of the customer without compromising the customer experience.
       
       
        </>
      ),
      list : [''],
        
    }
  ];
  
  export default InKYE;
  