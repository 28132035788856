import geosp1 from '../../img/management/geospatial1.jpg'
import geosp2 from '../../img/management/geospatial2.jpg'
import geosp3 from '../../img/management/geospatial3.jpg'
import geosp4 from '../../img/management/geospatial4.jpg'
const GeospatialArray=[
    {
        title:'CDR Data',
        desc:[
            (
            <div>
               CDR Data includes information about you such as your name and contact details, as well as detailed information about your use of a specific product or service. Call Detail Record (CDR) is the detailed record of all the telephonic calls that pass through a telephone exchange or any other telecommunications equipment.
               Customer Data
                    <ul className='list-disk'>
                        <li>Your name, occupation and contact details</li>
                        <li>Information you provided when acquiring a product, or relating to your eligibility to acquire that product</li>
                        <li>Details if you operate a business, such as your business’ name, ABN and ACN, the type of business, date of establishment and organization types</li>
                       
                    </ul>
                </div>
            )
        ],
        img: geosp1,
        
    },
    {
        title:'Telecom Data',
        desc:[
            (
            <div>
               Transaction data
                    <ul className='list-disk'>
                        <li>Incoming and outgoing transactions and the amounts</li>
                        <li>Dates</li>
                        <li>Descriptions of transactions</li>
                        <li>Who you may have sent money to and received money from</li>
                       
                    </ul>
                </div>
            )
        ],
        img: geosp2,

    },
    {
        title:'Account Data',
        desc:[
            (
            <div>
               
                    <ul className='list-disk'>
                    <li>Your account number, name and postal address</li>
                    <li>Your account type</li>
                    <li>Your account balance</li>
                    <li>Interest rates, fees and discounts</li>
                    <li>Transaction data</li>
                    <li>Incoming and outgoing transactions and the amounts</li>
                    <li>Dates</li>
                    <li>Descriptions of transactions</li>
                    <li>Who you may have sent money to and received money from</li>
                    <li>Direct debits and scheduled payments</li>
        
                    </ul>
                </div>
            )
        ],
        img:geosp3,
        
    },
    {
        title:'',
        desc:[
            (
            <div>
               What is SDK data?
                    <ul className='list-disk'>
                    <li>SDK stands for software development kit. Also known as a devkit</li>
                    <li>The SDK is a set of software-building tools for a specific platform, including the building blocks, debuggers and, often, a framework or group of code libraries such as a set of routines specific to an operating system</li>
                    
                    </ul>
                    What does a SDK include?
                    <ul className='list-disk'>
                    <li>SDKs include documentation, application programming interfaces (APIs), code samples, libraries and processes, as well as guides that developers can use and integrate into their apps. Developers can use SDKs to build and maintain applications without having to write everything from scratch.</li>
                    </ul>
                </div>
            )
        ],
        img: geosp4 ,
    }
]
export default GeospatialArray