

const inOperational = [
    {
      title: "Overview",
      desc:
      (
        <>
      valoores in' Operational Risk Analytics enables business-user driven reporting through true enterprise-wide operational risk analysis with a comprehensive and readily deployable suite of pre-built analytics, allowing institutions to capitalize on their present investments. Through better, in-depth analysis of loss, risk and control data, see areas of weakness and scope for improvement in the firm.
        </>
      ),
      list: [''],
    },
    {
      title: "key Benefits",
      desc:'',
      list: 
      [
        'Ensure accuracy and consistency across the enterprise through analysis and reports using common financial services industry language and metrics',
        'Determine trends based on risks by business units, locations and any other operational risk dimension across time periods',
        'Achieve desired transparency and auditability in reports and dashboards',
        'Alert senior management to evolving situations to prevent future crises',
      ],
    },
    {
      title: "key Features",
      desc:'',
      list: 
      [
        'Pre-built dashboards, charts and reports covering all operational risk disciplines',
        'Produce highly formatted reports with drill-down capabilities',
        'Unified data repository, pre-built for operational risk and compliance reporting',
        'Ability to analyze across all functional areas and enable time-series analytics',
        'Build extra ad-hoc and on-demand reports and charts to meet specific business-user needs',
      ],
    }
  
  ];
  
  export default inOperational;
  