import React from 'react'
import ItemArray from './itemArray'
import TechArray from './techArray'
import { FaArrowRightLong } from "react-icons/fa6";
import { Fade } from 'react-awesome-reveal'

import './Industries.css'
import { Link } from 'react-router-dom';

const Industries = () => {
  return (
    <>
        <div>
            <div className="d-flex flex-column gap-4 align-items-lg-center align-items-md-center align-items-center justify-content-between px-4 pb-4">
                {/* Title Section */}
                <div className="mb-lg-0 w-100" style={{textAlign: 'center' }}>
                <Fade direction='up'>
                    <h1 className='font--poppins fw-bold ind_title '>Industries</h1>
                </Fade>
                </div>
                    
                {/* List Items Section */}
                <div className="d-flex flex-row w-100 justify-content-center">
                    {/* <div className='row'> */}
                    <Fade direction='none' cascade className='w-100'>
                        {ItemArray.map((item, index) => (
                            <div key={index} className='col-lg-12 col-md-3 d-flex flex-row align-items-center justify-content-md-start justify-content-center gap-3 mb-3 '>
                                <h1 className='border-seperator font--alatsi' style={{ color: '#7B86A3' }}>{index + 1}</h1>
                                <h5 className='w-75 text-start'>{item}</h5>
                            </div>
                        ))}
                    </Fade>
                    {/* </div> */}
                </div>
            </div>

            <div style={{background: '#D9D9D966'}}>
                {TechArray.map((tech, idx) => (
                    <div className={`d-flex text-center text-lg-start ${idx === 0 ? 'flex-lg-row flex-md-column' : ' flex-lg-row-reverse flex-md-column' }`} key={idx}>
                        <div className="col-lg-6 col-md-12" style={{paddingRight: '0', backgroundImage: `url(${tech.img})`, height: '25rem', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                            {/* <img src={tech.img} className='w-100' alt="" /> */}
                        </div>
                        <div className={`industry_tech my-5 col-lg-6 col-md-12 px-4 d-flex flex-column justify-content-center align-items-lg-start align-items-md-center`}>
                            <Fade direction='up'>
                                <h1 className='industry_tech-title font--poppins text-lg-start text-md-center' style={{marginBottom: '2rem'}}>{tech.title}</h1>
                            </Fade> 
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <div className='industry_tech-list d-flex flex-column align-items-lg-start align-items-md-center justify-content-center'>
                                <Fade direction='left' cascade>
                                    {tech.list.map((techItem, techIdx) => (
                                        <div key={techIdx}>
                                            <Link className='text-decoration-none text-dark'>
                                                <h4 className='tech_list-item fw-light text-lg-start text-md-center font--poppins d-flex align-items-center gap-2 justify-content-start'>{techItem} <FaArrowRightLong size={25} fill="#4F66E2" /></h4>
                                            </Link>
                                        </div>
                                    ))}
                                </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}

export default Industries