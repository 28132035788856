import anal1 from '../../img/management/analytics1.jpg'
import anal2 from '../../img/management/analytics2.jpg'
import anal3 from '../../img/management/analytics3.jpg'
import anal4 from '../../img/management/analytics4.jpg'
import anal5 from '../../img/management/analytics5.jpg'
import anal6 from '../../img/management/analytics6.jpg'
import anal7 from '../../img/management/analytics7.jpg'
import anal8 from '../../img/management/analytics8.jpg'
import anal9 from '../../img/management/analytics9.jpg'
import anal10 from '../../img/management/analytics10.png'
import anal11 from '../../img/management/analytics11.png'
import anal12 from '../../img/management/analytics12.jpg'
import anal13 from '../../img/management/analytics13.jpg'
import anal14 from '../../img/management/analytics14.jpg'
import anal15 from '../../img/management/analytics15.jpg'

const AnalyticsArray=[
    {
        title:'"Analytics and Machine learning"',
        desc:'VCIS Geospatial Scientific Approach and Diverse Data Sources Are Producing Digital footprints, Trajectory Patterns, location behavioral models and Efficient Investigations for criminal and enforcement phenomenon.A good analytics engine is the modern crystal ball of any competitive business. Analytics can derive insights from raw data, evaluate historical trends, identify correlations, develop recommender systems, run what-if simulations, develop,  models, and support forward-looking business. At VALOORES, we believe that analytics is not a service to sell, but rather a culture to build. Actionable data production lies at the heart of our multi-service homegrown products to offer a complete coverage of the pain-gain business life cycle, and support our clients during this period of intense cultural reform in banking, insurance, retail, healthcare, governance, and education.',
        img: anal1,
        
    },
    {
        title:'',
        desc:'We live in an era where machine learning and Artificial Intelligence is helping change all our traditional methods in order to extract more valuable insights We are now able to detect and prevent around the clock with the capacity of understanding the Big Data we are exposed to; In’VCIS Analytics bring top components to ease the work. Unlike many other information visualizations, a map connects data to the real world, clearly showing how location relates to other data features, the geo location hits data are something we interact with, feel, live and relate to. Analytics here plays a dual role enabling users to create insightful geographical indication analysis instantly without a lot of learning and provides the depth of analytical capabilities needed for predictive analysis, Location, Trajectories, Areas of Interest, Points of Interests and other optimizations through location. It’s the best of both worlds. With location analytics, you can automatically turn data into location-based insights, communicate it with business users, analysts, scientists, investigators and developers or end users. This improved information management enhances collaboration across teams and helps everyone in the business make more informed decisions, often leading to reduced costs and increased revenue.',
        img: anal2,

    },
    {
        title:'Data Mining and Knowledge Discovery in Databases',
        desc:'All data science fields are connected with data mining as it constitutes the core set of practices within data science. Instead of mining data itself, the discipline is about creating algorithms to extract valuable insights from large and possibly unstructured data. The basic problem of data mining is to map available data and convert it into digestible patterns. Machine Learning There’s a difference between data mining and very popular machine learning.Still, machine learning is about creating algorithms to extract valuable insights, it’s heavily focused on continuous use in dynamically changing environments and emphasizes on adjustments, retraining, and updating of algorithms based on previous experiences. The goal of machine learning is to constantly adapt to new data and discover new patterns or rules in it. Sometimes it can be realized without human guidance and explicit reprogramming.',
        img:anal3,
        
    },
    {
        title:'',
        desc:[
            (
            <div>
             1. Locations Predictions <br/>
             We deal and manage coordinates data and relative info All the data is linked to geo hits, devices and People (People if data is available and right access is provided usually by government entities) The consolidation and aggregation of hits using our analytics tool enable to forecast a trajectory of the devices and where it can supposedly repetition be in the next, hours, days, weeks of months based on previous data and previous movements that occurred throughout a frequency of time and solid Activities differ between normal days, working hours, weekends and travel dates where the forecast engine we use take more than 250 + Factors to predict an accurate location of the device or the person in any forward investigation or search The Location based prediction have multiple outcomes, coordinates (X, y and Z), Areas, movement, Traffic, Activities and trajectories
                   
                </div>
            )
        ],
        img: anal4 ,
    },
    {
        title:'',
        desc:[
            (
            <div>
             2. POI <br/>
             Point and people of Interest, when you want to understand the link between 2 or more people, their meeting frequency, Areas they visited together or separately. X1 is a geo hit of a device that has appeared in three different places for a selected period of time. We request a POI of Devices interested or linked to X1 in those 3 areas (Another request also can be requested for different or additional Areas) C1 is a geo hit of a device that has appeared in one of the three pales that X1 has visited and at the same time for 2 hours within a 5meter radius Distance (The Distance is an attribute within our AI Engine that you can configure based on the need and based on the search I am doing and can vary from 2 meters to 500 meters)
                   
                </div>
            )
        ],
        img: anal5 ,
    },
    {
        title:'',
        desc:'C2 is a geo hit of a device that has appeared with X1 for two times the same places and for more than one hour within a 5-meter radius distance. The link between those geo hits is clear through the frequency Areas visited and the time spent there so we can say that X1, C2, C1 are a POI Group and their POI Points based on the most frequent Visited area will be flagged as a priority meaning 2,3 or more POI Will appear but based on the most significant Area Relating those three devices to X1. POI has 2 different requirements one for areas and one for close devices And both requirement can meet with the top priority POI indicating the best POI Result (All Elements of the search are configurable within the builder used) POI is used for multiple purposes as well 1.Analyze and understand the traveling habits of the Devices. 2.Identify time and speed taken to travel from one POI to another. 3.Identify routes frequently taken by your audience between two POI. 4.Uncover correlations between Areas and Devices and other variables you have configured previously',
        img: anal6,
    },
    {
        title:'',
        desc:[
            (
            <div>
            3. Classifications <br/>
            Working with huge data related to Geo hits and devices and their visualization on the map, push us to classify not only the devices but the places they are visiting meaning the Map itself should have types, classification, segmentations and even controls for a better and wider investigation or research going on. Areas have multiple classifications like:
                    <ul className='list-disk'>
                    <li>Restricted</li>
                    <li>Crowded</li>
                    <li>Agriculture</li>
                    <li>Borders</li>
                    <li>Transportation</li>
                    <li>Suburbs</li>
                    <li>Downtown</li>
                    <li>Sports fields (Sporting grounds)</li>
                    <li>Industrial</li>
                    <li>Drug hub</li>
                    <li>Crime Hub</li>
                    <li>Poor Area</li>
                    <li>Rich Area</li>
                    <li>Political Area</li>
                    <li>Downtown</li>
                    <li>Uptown</li>
                    <li>Village</li>
                    <li>Touristic Site</li>
                    <li>Categories and sub- categories of areas and logged detailed info about points and Areas (Food Court , MAcdonals , 24/7 place , Has a POS , ATM , Pharmacy , Mall...)</li>
                    </ul>
                </div>
            )
        ],
        img: anal7 ,
    },
    {
        title:'',
        desc:[
            (
            <div>
             4. Device Classifications <br/>
             Groupings:It is commonly related to Areas but this is where group, segment and categorize devices based on their visits to places or areas Grouping has 3 types AOI Returned hits Anonymous Saved location and know devices Only device Classification based on visits to areas. All These have pre-configured Into 2 directions Area and Device Area Classifications based on visits and activities Activity Types Good and Bad …. Crimes , Robbery , Terrorist Hiking , Gym , Swimming DBRE Our engine and Where you set up all these rules
                   
                </div>
            )
        ],
        img: anal8 ,
    },
    {
        title:'Data evolution with Valoores',
        desc:'The data revolution entails a new perception paradigm. The adjustment requires a process to capitalize on the benefits while avoiding missed opportunities and hazardous blind spots. This involves data management, data processing, and actionable data production. At valoores, we believe that analytics is not a service to sell, but rather a culture to build. Actionable data production lies at the heart of our multi-service homegrown products to offer a complete coverage of the pain-gain business life cycle, and support our clients during this period of intense cultural reform in banking, insurance, retail, healthcare, governance, and education. A good analytics engine is the modern crystal ball of any competitive business. Analytics can derive insights from raw data, evaluate historical trends, identify correlations, develop recommender systems, run what-if simulations, develop forecasting models, and support forward-looking business.',
        img: anal9 ,
    },
    {
        title:'',
        desc:'Quantitative methods work because of sound mathematical and logical reasons. However, regardless of the sophistication level of the algorithms themselves, the output of the analytics engine, whether descriptive or predictive, is only as good as the data it takes an input. In fact, when talking about algorithms that can “learn” from the enterprise’s experiences with its clients, it is imperative to question whether the data these algorithms are learning from, reflects faithfully these experiences.This translates to GIGO, that is “Garbage In, Garbage Out”. The data quality might be compromised for example in the input and output layers, within migration processes, or simply in the phase of data entry. In addition, a comprehensive understanding of the transformations performed within these layers is substantial for the development and efficiency of any analytics engine. In fact, the lifecycle stages of any business analytics project are interdependent, as depicted in Figure 2. In this white paper, we discuss metrics for data quality assessment.',
        img: anal10 ,
    },
    {
        title:'',
        desc:[
            (
            <div>
              Business analytics is all about deriving knowledge from possibly large volumes of data or generally “difficult” data. This process combines several disciplines such as statistics, machine learning, information science, database management, and data visualization – areas that have only become recognized and accessible due to the technological developments in our times.
                    <ul className='list-disk'>
                    <li>Business analytics is all about deriving knowledge from possibly large volumes of data or generally “difficult” data. This process combines several disciplines such as statistics, machine learning, information science, database management, and data visualization – areas that have only become recognized and accessible due to the technological developments in our times.</li>
                    
                    </ul>
                    Applications of Analytics
                    <ul className='list-disk'>
                    <li>According the journey from hindsight to insight to foresight consists of the following stages:</li>
                    <li>Descriptive analytics which presents what happened and identifies past successes and failures.</li>
                    <li>Diagnostic analytics which allows the drill-down to the root cause(s) of problems.</li>
                    <li>Predictive analytics which calculates the likelihood of future events, and is usually based on the analysis of past trends.</li>
                    </ul>
                </div>
            )
        ],
        img: anal11 ,
    },
    {
        title:'Machine learning workflow by steps',
        desc:'The core of any machine learning execution is a mathematical model, which describes how an algorithm processes new data after being trained with a subset of historic data. The goal of training is to develop a model capable of formulating a target value (attribute), some unknown value of each data object. While this sounds complicated, it really isn’t. You need to predict whether customers of your e-commerce store will make a purchase or leave. These predictions buy or leave are the target attributes that we are looking for. To train a model in doing this type of predictions you “feed” an algorithm with a dataset that stores different records of customer behaviors and the results (whether customers left or made a purchase). By learning from this historic data a model will be able to make predictions on future data.',
        img: anal12 ,
    },
    {
        title:'',
        desc:'Generally, the workflow follows these simple steps: 1. Collect data: Use your digital infrastructure and other sources to gather as many useful records as possible and unite them into a dataset. 2. Prepare data: Prepare your data to be processed in the best possible way. Data preprocessing and cleaning procedures can be quite sophisticated, but usually, they aim at filling the missing values and correcting other flaws in data, like different representations of the same values in a column (e.g. December 14, 2016 and 12.14.2016 won’t be treated the same by the algorithm).3. Split data. Separate subsets of data to train a model and further evaluate how it performs against new data. 4. Train a model. Use a subset of historic data to let the algorithm recognize the patterns in it. 5. Test and validate a model. Evaluate the performance of a model using testing and validation subsets of historic data and understand how accurate the prediction is. 6. Deploy a model. Embed the tested model into your decision-making framework as a part of an analytics solution or let users leverage its capabilities (e.g. better target your product recommendations). 7. Iterate. Collect new data after using the model to incrementally improve it.',
        img: anal13 ,
    },
    {
        title:'We have multiple ways to retrieve coordinates from the telecom world:',
        desc:'1. Already exist: Commonly expressed in latitude and longitude (Lat/Long), the geographic positions of a location are called coordinates. These data can be procured from satellite-based mapping services or collated using GPS-enabled devices (such as smartphones, fitness trackers, tablets, etc.). Using multiple coordinates, it is possible to create a larger virtual perimeter for a real-world geographic area.2. Triangular 3 location trajectory: A cell phone signal may be picked up by three or more cell towers enabling the triangulation to work. So when a triangulation happens – with the point of overlap of three signals, it is possible to estimate the location of the cell phone based on its distance from the three towers.',
        img: anal14 ,
    },
    {
        title:'',
        desc:'3. Smart multiple coordinates. “line of coordinates” Line is A set of ordered co-ordinates that represent the shape of geographic features too narrow to be displayed as an area at the given scale (contours, street centerlines, or streams), or linear features with no area (county boundary lines). A lines is synonymous with an arc. 4. Correlation between telecom and meta data: ICIS Combine Telecom Data with Meta Data in a way where the law enforcement can rely on to access new information insights, answers the investigator questions that previously remain unanswered, streamlines and saves time. If you’re looking for another way to increase security and cut costs, connecting uncountable data and global information to create a better world, in’Datacrowd the key.',
        img: anal15 ,
    },
]
export default AnalyticsArray