
const InWealthManagement = [
    {
      title: "Overview",
      desc:
      (
        <>
            VALOORES in'Wealth Management transforms the way wealth management service providers serve and empower their affluent and high net-worth customers. In' Wealth Management offers a comprehensive, web-enabled and integrated application for managing the entire wealth management business cycle. With In' Wealth Management, banks can plan, record, track and manage the overall wealth of a customer across a range of asset classes and instruments. This results in increased financial advisor productivity and improved customer relationships.
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: '',
        list: [
          "Set transfer rates for a wide range of financial instruments",
          "Actively incorporate risk into decision making",
          "Flexibly assign transfer rates to individual customer relationships",
          "Develop a deeper understanding of option and liquidity costs",
          "Identify break events and compute economic loss",
          "Price loan commitments using forward rates and calculate rate lock option costs",
          "Easy-to-use interfaces and business logic requires minimal maintenance",
          "Publish web based daily FTP rate cards for standard products.",
        ],
      },
      {
        title: "Key Features",
        desc:'',
        list:
        ([
            "Industry leading Transfer Pricing Methods support base transfer rates",
            "TP methods available for ALM Forecasting and Budgeting",
            "Option, Liquidity, and Breakage Charges",
            "Visibility into the components of net interest margin",
            "Shared data model and platform",
            "Enterprise class scalability",
            "Integration of Risk, Performance Management, Customer Insight, and Financial Compliance",
        ]),
    }
  ];
  
  export default InWealthManagement;
  