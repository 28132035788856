import React , { useState} from 'react'
import {BsChevronUp} from 'react-icons/bs'
import {AiOutlineMinus} from 'react-icons/ai'
import $ from 'jquery';



const ContactUs = () => {
    
    const [isContactOpen, setIsContactOpen] = useState(false)
    const [isEmptyError, setIsEmptyError] = useState(false);
    
    
    const check = () => {
        const contact_name = $('#contact_email').val();
        const contact_email = $('#contact_email').val();
        const contact_phone = $('#contact_email').val();
        const contact_message = $('#contact_message').val();
        
        if (contact_name === '' || contact_email === '' || contact_phone === '' || contact_message === '') {
            setIsEmptyError(true);
            $('#contact_email').val()
        } else {
            
        }   
    }   
    

    const handleOpenContact = () => {
        setIsContactOpen(prevState => !prevState);
    }
    
    
    
    const ContactMenu = () => {
        return(
            <div className='contact__container-form contact_popup'>
                <div className='contact__container-form_head'>
                <div className="contact__container-form_title d-flex align-items-center">
                    <div>
                        <h5>Contact us</h5>
                    </div>
                    <AiOutlineMinus onClick={handleOpenContact} className='close_contact-modal' style={{cursor: 'pointer'}}/>
                </div>
                
                <div className="p-3 d-flex flex-column gap-3">
                    <p className='text-dark mt-4'>There are no online agents available right now to take your call. Please leave a message and we will reply by email.</p>

                    <div className="d-flex flex-column gap-2">
                    <input
                        type="text"
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Name"
                        id="contact_name"
                        // value={nameValue}
                        // onChange={handleNameChange}
                    />

                    <input
                        type="text"
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Email"
                        id="contact_email"
                        // value='defaultValue'
                        // onChange={handleEmailChange}
                    />

                    <input
                        type="text"
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Phone Number (optional)"
                        id="contact_phone"
                        // value={contactValue}
                        // onChange={handleContactChange}
                    />

                    <textarea
                        className={isEmptyError ? 'error_border form-control' : 'form-control'}
                        placeholder="Type your message"
                        id="contact_message"
                        // value={messageValue}
                        // onChange={handleMessageChange}
                    ></textarea>

                    </div>
                    {isEmptyError && <span className='err-msg'>Please Fill All Fields</span>}
                    <div className='d-flex flex-row justify-content-end'>
                        <button id='send_btn' className="btn btn-primary" onClick={check}>Send</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    
    return (
    <>
        <div className="contact_us" onClick={handleOpenContact}>
            Contact Us 
            <BsChevronUp />
        </div>   
        <div>
            {isContactOpen && <ContactMenu />}
        </div>
    </>
  )
}

export default ContactUs