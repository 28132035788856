
const InContact = [
    {
      title: "",
      desc:
      (
        <>
      VALOORES in'Contact Center Management is the strategic nerve center of your customer experience strategy. It enables end-to-end management of the customer experience by unifying customer data, contextual knowledge, and interaction channels. Your agents are equipped to consistently deliver better customer experiences across channels.
      <br/>
      <ul>
        <li>Offset your high operational expenses by automating more customer transactions</li>
        <li>Create a dynamic knowledge base by gathering information from multiple sources, organizing that information, then making it accessible in the form of actionable knowledge</li>
        <li>Handle all customer interaction in a unified manner by providing agents with all relevant previous exchanges regardless of channel or department</li>
        <li>Tap into the knowledge you have collected about your customers and engage proactively with tailored and timely service</li>
      </ul>
        </>
      ),
      list: [''],
    },
   
  ];
  
  export default InContact;
  