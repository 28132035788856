import React from 'react'
import Banner from './Banner'
import BannerImg from '../../img/cvsBanner.png'

const CVS = () => {
  return (
    <>
        <Banner
            img = {BannerImg}
            title = "Incorporating Location Intelligence within Retail Organizations"
        />
    </>
  )
}

export default CVS