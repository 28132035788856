import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Test = () => {
  const handleTest = () => {
    const data = { key: 'value' };

    axios.post('https://valoores.com/test.php', data)
      .then((response) => {
        console.log('Success:', response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <div style={{height: '100vh', marginTop: '10rem'}}>

        <Link className="btn btn-primary" onClick={handleTest}>
            Click Me
        </Link>
      </div>
    </>
  );
};

export default Test;
