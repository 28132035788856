import React from 'react'
import Banner from '../Banner'
import BannerImg from '../../img/management/geospatial_banner.jpg';
import Geospatial1Img from '../../img/management/geospatial.gif';
import GeospatialArray from './GeospatialArray';
import ParalaxCase from './ParalaxCase'; 


const CaseGeospatial = () => {
  return (
    <>
    <Banner
        img={BannerImg}
        title={
        <div className='text-center'>
            VCIS Geospatial <br /> Metadata Sources
        </div>
    }
    />
   <div className="my-5 d-flex flex-column justify-content-center align-items-center px-5" >
   <div>
          <h2 className='text-center'>Data Type Sources</h2>
        </div>
        <div>
          <img src={Geospatial1Img} alt="" style={{ width: '95%' }} />
        </div>
        </div>
      <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3">
        {GeospatialArray.map((GeospatialArray, index) => (
          <div key={index} className="d-flex flex-column justify-content-center align-items-center">
            {index % 2 === 0 ?
              <div className='px-5 my-5'>
                <div className='mb-3 text-center'>
                  <h2>{GeospatialArray.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-between gap-3 align-items-center">
                  <div className='w-50 px-3'>
                    <p class='column_2'>{GeospatialArray.desc}</p>
                  </div>
                  <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={GeospatialArray.img} className='w-85' alt={GeospatialArray.title} />
                  </div>
                </div>
            </div> : 
            <div className='px-5 my-5'>
                <div className='my-4 text-center'>
                  <h2>{GeospatialArray.title}</h2>
                </div>
                <div className=" d-flex flex-row justify-content-center align-items-center">
                <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                    <img src={GeospatialArray.img} className='w-85' alt={GeospatialArray.title} />
                </div>
                <div className='w-50 px-3'>
                    <p class='column_2'>{GeospatialArray.desc}</p>
                </div>
                </div>
            </div>}
            {index === 0 && <ParalaxCase img={BannerImg} style={{backgroundAttachment: 'fixed'}} />} 
          </div>

        ))}

    </div>
    
</>
  )
}

export default CaseGeospatial
