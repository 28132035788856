import React, { useEffect, useState } from 'react';
import './Sections.css';
import SectionArray from './sectionsArray';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sections = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // Adding space between slides
        centerMode: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };

    return (
        <div style={{ background: '#001f3d' }} className='py-5 industryPage'>
            <div className='container'>
                <Slider {...settings}>
                    {SectionArray.map((section, index) => (
                        <div key={index} className='section-slide'>
                            <div 
                                style={{
                                    backgroundImage: `url(${section.img})`,
                                    height: '15rem',
                                    width: '100%',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover',
                                    borderRadius: '10px 10px 0 0'
                                }} 
                            />
                            <div className="text_content-Consultancy p-3">
                                <h5>{section.title}</h5>
                                <p>{section.desc}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Sections;
