import React from 'react'
import Fade from 'react-reveal/Fade'

const CareerBanner = (props) => {
  return (
    <>
    <div>
        <div class='career_banner' style={{backgroundImage: `url('${props.img}')`}}></div>
        <div className="overlay_bg-career"></div>
        <div className='hr_content' style={{top : `${props.top}`}}>
          <Fade left>
            <h1 style={{textShadow: '5px 5px 7px #08394a'}}>We Are <span className='fw-bold'>HIRING!</span></h1>
          </Fade>
        </div>
    </div>
    </>
  )
}

export default CareerBanner