import React from 'react';
import bgServices from '../../img/insurance/paralax-1.jpg'
import InsuranceSlides from './InsuranceSlides'
import './insurance.css'
import { Link } from 'react-router-dom';


const InsuranceServices = () => {

  return (
    <div className='services_bg  d-flex flex-column align-items-center py-5' style={{backgroundImage: `url(${bgServices})`}}>
      <div className='services_content'>
        <h2 className='text-white'>VALOORES in'Insurance Services</h2>
      </div>
      <div className="services_cards d-flex flex-row flex-wrap align-items-center justify-content-center gap-2 px-5">
        {InsuranceSlides.map((insurance, index) => (
          <Link target='_blank' to={insurance.Link1} className="service_cards-card w-18 text-center py-3 d-flex flex-column align-items-center gap-3" key={index}>
            <img src={insurance.icon} style={{width: '80px', height: '80px'}} alt="" />
            <p className='text-white'>{insurance.Title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default InsuranceServices;
