import React from 'react'
import {useLocation} from 'react-router-dom';
import Banner from './BankingPages/Banner'
import CardSection from './BankingPages/CardSection'
import Overview from './BankingPages/Overview'
import SideMenu from './BankingPages/SideMenu'
import RiskComplianceArray from './BankingPages/content/RiskCompliance'
import InProfitArray from './BankingPages/content/InProfit'
import InDataArray from './BankingPages/content/InDataA'
import InMarketingArray from './BankingPages/content/InMarketing'
import InKernelArray from './BankingPages/content/InKernel'
import InPaymentArray from './BankingPages/content/InPayment'
import InAssetArray from './BankingPages/content/InAsset'
import InCardArray from './BankingPages/content/InCard'
import InCryptoArray from './BankingPages/content/InCrypto'
import Risk from '../../img/banking/imgs/image1.jpg'
import Profit from '../../img/banking/imgs/image2.jpg'
import DataG from '../../img/banking/imgs/image3.jpg'
import Marketing from '../../img/banking/imgs/image4.jpg'
import Kernel from '../../img/banking/imgs/image6.jpg'
import Payment from '../../img/banking/imgs/image7.jpg'
import Asset from '../../img/banking/imgs/image8.jpg'
import Card from '../../img/banking/imgs/image9.jpg'
import CryptoCard from '../../img/banking/imgs/image9.jpg'
import { Helmet } from 'react-helmet';

const Feature = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const receivedVariable = searchParams.get('page');
    let BannerImg, BannerDesc, FeatureArray, cardTitle, cardSection;

    switch (receivedVariable) {
        case 'risk-and-compliance':
            BannerImg = Risk;
            BannerDesc = "VALOORES in'Risk Compliance helps you reach your goals, to allow your organization to be fully prepared for unpredictable events.";
            FeatureArray= RiskComplianceArray;
            cardTitle = "in'Risk Compliance";
            cardSection = 'yes'
          break;

        case 'inprofit':
          BannerImg = Profit;
          BannerDesc = "VALOORES in'Profit drives a company's profitability. Rich, intelligent & compliant to standards are in'Profit's bread and butter.";
          FeatureArray= InProfitArray;
          cardTitle = "in'Profit";
          cardSection = 'yes'
          break;

        case 'indatagovernance':
          BannerImg = DataG;
          BannerDesc = "“VALOORES in'Data Governance is designed to centralize, cleanse, govern & share the data across the organization's systems, & to synchronize it downstream the different applications & platforms.”";
          FeatureArray= InDataArray
          cardTitle = "in'Data Governance";
          cardSection = 'yes'
          break;

        case 'marketing-optimization':
          BannerImg = Marketing;
          BannerDesc = "With VALOORES in'Marketing Optimization get the flexibility you need to keep up with evolving customer expectations, demographics, and demand.";
          FeatureArray= InMarketingArray
          cardTitle = "in'Marketing Optimization";
          cardSection = 'yes'
          break;

        case 'inkernel':
          BannerImg = Kernel;
          BannerDesc = "VALOORES in'Kernel lets you manage the workforce, fortify diversity, automate processes while task traceability to reinforce accountability.";
          FeatureArray= InKernelArray
          cardTitle = "in'Kernel";
          cardSection = 'yes'
          break;

        case 'financialservices':
          BannerImg = Payment;
          BannerDesc = "“in'Payment is a complete payment and receipt processing solution that enables efficient, reliable and secure financial transactions. It lowers costs and improves control by integrating out-of-the-box with major processors and financial institutions.”";
          FeatureArray= InPaymentArray
          cardTitle = "in'Payment Management";
          cardSection = 'no'
          break;
  
        case 'inassetmanagement':
          BannerImg = Asset;
          BannerDesc = "Offers solutions in middle-office and back-office functions enabling key capital market players like asset managers, custodians, broker/dealers, fund administrators to retain competitive advantage in a dynamic market environment.";
          FeatureArray= InAssetArray
          cardTitle = "in'Asset Management";
          cardSection = 'yes'
          break;

          case 'cardsmerchant':
          BannerImg = Card;
          BannerDesc = "“in'Cards Merchant solution enables banks to track customers with multiple accounts and cards of different types. It also supports the running of loyalty programs to promote card usage and drive retention.”"; 
          FeatureArray= InCardArray;
          cardTitle = "in'Cards Merchant";
          cardSection = 'no'
          break;

          case 'crypto':
          BannerImg = CryptoCard;
          BannerDesc = "“in'Cards Merchant solution enables banks to track customers with multiple accounts and cards of different types. It also supports the running of loyalty programs to promote card usage and drive retention.”"; 
          FeatureArray= InCryptoArray;
          cardTitle = "in'Crypto";
          cardSection = 'no'
          break;

        default:
            BannerImg = Marketing;
            FeatureArray= InMarketingArray
        break;
      }

      // const formattedPage = receivedVariable.replace(/-/g, ''); 
      // const formattedUrl = `/banking/${formattedPage}`;

      // window.history.replaceState(null, null, formattedUrl);

  return (
    <>
        <Helmet>
          <title>{cardTitle}</title>
        </Helmet>
        <Banner
          img = {BannerImg}
          desc= {BannerDesc}
        />
      <div className='section_divider feature_content d-flex flex-column pt-3 px-5 mb-5'>
        <h4 className='mt-3 mb-4'>{cardTitle}</h4>
        <div className='d-flex flex-row gap-5'>
          <div style={{flex: '4'}}>
          {cardSection === 'yes' ? (
              <CardSection 
              CardArray = {FeatureArray}
            />
            ) : (<Overview 
              CardArray = {FeatureArray}
            />
            )
          }
          </div>
          <div style={{flex: '1'}}>
            <SideMenu />
          </div>
        </div>
      </div>
    </>
  )
}

export default Feature