import React from 'react'
import SectionsArray from './sectionsArray'
import './Sections.css'
import { Link } from 'react-router-dom'

const Sections = () => {

  return (
    <div className='industry_bigcontainer' style={{background: '#001f3d'}}>
      <Link style={{textDecoration: 'none', color: '#051C2CF5'}}>
        <div className="card_section-container flex-wrap d-flex flex-column flex-lg-row flex-md-column align-items-center justify-content-center gap-4 ">
          {SectionsArray.map((section, index) => (
            <div key={index} className='card_sections rounded'>
              <div className='section_banner pb-3' style={{backgroundImage: `url(${section.img})`}}></div>
              <div className="section_text-content p-3 d-flex flex-column gap-3">
                <h4 className={`${index === 2 && 'p-3'}`}>{section.title}</h4>
                <p>{section.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </Link>
    </div>
  )
}

export default Sections