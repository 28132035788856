const InPayment = [
    {
      overview: "VALOORES in' Payment is a complete payment and receipt processing solution that enables efficient, reliable and secure financial transactions. It lowers costs and improves control by integrating out-of-the-box with major processors and financial institutions.",
      benefits: [
        ["Improve visibility and agility with centralized payments" , "Reduce costs with streamlined processes", "Maintain complete control over payment data and instruments"]
      ],
      features: [
        ["Central payment engine", "Configurable formatting framework", "Flexible validation model", "Secure payment data repository", "Seamless electronic payment transmission", "Flexible support for various Business Payment Models", "Dashboards for monitoring payment processes"]
      ]
    },
  ] 

  export default InPayment