import React from 'react';
import { Link } from 'react-router-dom';

const SectionContainer = ({
  title,
  description,
  backgroundColor,
  layout,
  link,
  action
}) => {

  const Section1 = () => {
    return (
    <div className={`row ${layout} `}>
    
      <div className="col-md-12">
        <h4 style={{ color: '#001d6e' }}>{title}</h4>
        <p>{description}</p>
        {link && (
          <Link to={link} target="_blank" rel="noopener noreferrer" style={{ borderRadius: '25px', textDecoration: 'none' }} className='btn btn-primary text-white'>Learn More
          </Link>
        )}
      </div>
    </div>
    )
  }

  const Section2 = () => {
    return (
    <div className=''>
      <h4 className='pb-4 text-center' style={{ color: '#001d6e' }}>{title}</h4>
      <div style={{columnCount: '2'}}>
        <p>{description}</p>
       
      </div>
      </div>
    )
  }
  

  return (
    <div className="p-5 my-5" style={{ background: backgroundColor }}>
      <div className="container">
       {action === 'section2' ? <Section2 /> : <Section1 />}
      </div>
    </div>
  );
}

export default SectionContainer;
