import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import ReactModal from 'react-modal';
import Axios from '../../Components/indexTrial/database/banking_axios';
import ReactHtmlParser from 'react-html-parser';

const VideoPlayer = ({ videoId }) => {

  
  const opts = {
    height: '320',
    width: '100%',
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

const Application = () => {
  const fetchedApps = Axios({ path: '/application' });
  const fetchedData = Axios({ path: '/section' });
  
  const [isOpen, setIsOpen] = useState({});

  const openModal = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const closeModal = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  return (
    <>
    {fetchedData.map((section) => (
        <>
          {section.type === 'application' && (
            <div className="container app_container my-5">
              <div className={section.img_container}>
                {ReactHtmlParser(section.title)}
                {ReactHtmlParser(section.description)}
              </div>
              <div className={section.text_container}>
                {fetchedApps.map((items) => (
                  <div key={items.id} class="px-3">
                    <img src={require(`../../icons/${items.icon_path.split('/').pop()}`)} alt="" style={{width: '60px', height:'60px'}} />
                    <h4 className="mt-4">{items.title}</h4>
                    <p className="mt-4 text-start">{items.description}</p>
                    <span>
                      <div className='links_app d-flex flex-column gap-3'>
                          <Link onClick={() => openModal(items.id)}> Watch Video </Link>
                          <Link to={items.more} >Read More </Link>
                          <Link to={items.quick}>Quick Demo </Link>
                          <Link to={items.demo}>Request Demo </Link>
                      </div>
                      <ReactModal
                        className="d-flex align-items-center justify-content-center flex-column-reverse"
                        isOpen={isOpen[items.id]}
                        onRequestClose={() => closeModal(items.id)}
                        contentLabel="Example Modal"
                        appElement={document.getElementById('root')}
                      >
                        <VideoPlayer videoId={items.video} />
                        <button onClick={() => closeModal(items.id)}>X</button>
                      </ReactModal>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default Application;
