import React from 'react';

const Overview = (props) => {
  return (
    <div className='overview_content mb-5'>
      <h6>Overview</h6>
      {props.CardArray.map((inner, index) => (
        <div key={index}>
          <div>
            <p>{inner.overview}</p>
          </div>
          <h6>Key Benefits</h6>
          <ul className='m-0 mb-4 px-3'>
            {inner.benefits[0].map((item, innerIndex) => (
              <li key={innerIndex}>{item}</li>
            ))}
          </ul>
          <h6>Key Features</h6>
          <ul className='m-0 px-3'>
            {inner.features[0].map((item, innerIndex) => (
              <li key={innerIndex}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Overview;
