import React from 'react'
import Banner from '../components/General/Banner/Banner'
import Intro from '../components/General/Intro/Intro'
import Industry from '../assets/img/solutionsBanner.jpg'
import RequestDemo from '../components/General/RequestDemo/RequestDemo'
// import ValooresWLogo from '../../assets/img/logoWhite.png'
import ValooresWLogo from '../assets/img/logoWhite.png'
import Sections from '../components/Solutions/Sections/Sections'

const Solutions = () => {
  const bannertitle = (
    <>
      <img src={ValooresWLogo} style={{width: '20%'}} alt="" />
    </>
  )

  const descSolution = (
    <>
      "We can't solve problems by using the same kind of thinking we used when we created them. The whole of science is nothing more than a refinement of everyday thinking"<br/> ~ Albert Einstein
    </>
  )
  return (
    
    <div>
        <Banner title={bannertitle} desc='Solutions' descSize='4.5rem' img={Industry} classType = 'solutionClass'/>
        <Intro title='' desc={descSolution}/>
        <Sections />
        <RequestDemo />
    </div>
  )
}

export default Solutions;