
const InRatios = [
    {
      title: "Overview",
      desc:
      (
        <>
            Financial ratios are tools used to assess the relative strength of companies by performing simple calculations on items on income statements, balance sheets and cash flow statements. Ratios measure companies’ operational efficiency, liquidity, stability and profitability, giving investors more relevant information than raw financial data. Investors and analysts can gain profitable advantages in the stock market by using the widely popular technique of ratio analysis. Financial ratios can be used to analyze trends and to compare the firm’s financials to those of other firms. In some cases, ratio analysis can predict future bankruptcy.
            </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
            "Comparison: Financial ratios provide a standardized method with which to compare companies and industries",
            "Stock Valuation: The common language and understanding of ratios helps investors and analysts to evaluate and communicate the strengths and weaknesses of individual companies or industries",
            "Planning and Performance: Ratios can provide guidance to entrepreneurs when creating business plans or preparing presentations for lenders and investors",
          
        ],
      },
      {
        title: "Key Features",
        desc:'',
        list:
        ([
            "Full support for end to end revenue management lifecycle from pricing to billing through collections",
            "On-demand billing, multi-parameter-based pricing, transaction feed management and data ingestion",
            "Highly scalable, available and extensible",
            "Sophisticated pricing, rating, product bundling, discounting, promotions and collections capability",
            "Single source of truth across all geographies and LOBs",
            
        ]),
    }
  ];
  
  export default InRatios;
  