import React from 'react'
import FinancialImg from '../../img/ai/financialImg.jpg'
import Fade from 'react-reveal/Fade';
    

const financial_list = [
    {
        item: 
        <>
            <span className='fw-bold'>Personalized customer support:</span> AI-powered chatbots offer prompt and tailored solutions, improving customer experience.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Enhanced security: </span>AI algorithms detect and prevent fraud by analyzing real-time data.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Optimized decision-making: </span> Predictive analytics leverage data to inform risk assessment and investment strategies.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Expanded credit access:</span> AI-driven credit scoring evaluates creditworthiness comprehensively, increasing access to credit for individuals and businesses.
        </>
    },
]
const Company = () => {
  return (
    <>
        <div className="container my-5">
            <div className="row">
                <Fade left>
                    <div className="col-md-6">
                        <div className='mb-3'>
                            <h1 className='mb-3'>AI Revolutionizes Financial Services</h1>
                            <p>AI integration in financial services revolutionizes operations, providing personalized customer support, robust fraud detection, and optimized decision-making through predictive analytics and AI-driven credit scoring.</p>
                        </div>
                        <ul className='p-0' style={{listStyle: "none"}}>
                        {financial_list.map((list, index) => (
                            <Fade>
                                <li className='mb-2' key={index}>{list.item}</li>
                            </Fade>
                        ))}
                        </ul>
                    </div>
                </Fade>
                <Fade right>
                    <div className="col-md-6 retail_bg" style={{backgroundImage : `url(${FinancialImg})`}}></div>
                </Fade>
            </div>
        </div>
    </>
  )
}

export default Company