import Academy1 from '../../../img/academy/academy1.jpg';
import Academy2 from '../../../img/academy/academy2.jpg';
import Academy3 from '../../../img/academy/academy3.jpg';
import Academy4 from '../../../img/academy/academy4.jpg';
import Academy5 from '../../../img/academy/academy5.jpg';
import Academy6 from '../../../img/academy/academy6.jpg';
import Academy7 from '../../../img/academy/academy7.jpg';
import Academy8 from '../../../img/academy/academy8.jpg';
import Academy9 from '../../../img/academy/academy9.jpg';
import Academy10 from '../../../img/academy/academy10.jpg';
import Academy11 from '../../../img/academy/academy11.jpg';
import Academy12 from '../../../img/academy/academy12.jpg';

const Slides = [
    {
      image: Academy1,
      title: "VALOORES Academy develops an effective proactive strategic training management approach",
      description: "",
      header: "VALOORES in'Analytics",
      link: '/banking/feature?page=risk-and-compliance',
      top: '30%',
      button: 'yes',
    },
    {
      image: Academy2,
      title: "VALOORES Academy offers learning solutions focused on building job-related skills for continual professional developments", 
      description: '',
      header: "Descriptive Analytics",
      link: '/banking/feature?page=inprofit',
      top: '20%',
      button: 'no',
    },
    {
      image: Academy3,
      title: "VALOORES Academy develops an effective, proactive & strategic training management approach",
      description: '',
      header: "Diagnostic Analytics",
      link: '/banking/feature?page=indatagovernance',
      top: '25%',
      button: 'no',
    },
    {
      image: Academy4,
      title: "Communication & trust are two main ingredients for a successful RELATIONSHIP ",
      description: '~ VALOORES Academy Team',
      header: "Predictive Analytics",
      link: '/banking/feature?page=marketing-optimization',
      top: '20%',
      button: 'no',
    },
    {
      image: Academy5,
      title: "VALOORES Academy enhances flexibility & adaptability that are key management skills to develop a sustainable competitive advantage",
      description: '',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '20%',
      button: 'no',
    },
    {
      image: Academy6,
      title: "Vision is our greatest strength ... Guiding our path with verve to success ",
      description: '~ VALOORES Academy Team',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '25%',
      button: 'no',
    },
    {
      image: Academy7,
      title: "VALOORES Academy meets the demand of direct, hands-on training on the motions of VALOORES systems",
      description: '',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '25%',
      button: 'no',
    },
    {
      image: Academy8,
      title: "Imagination & innovation are the core of our solutions to embrace the world, stimulate growth, and  advancement ",
      description: '~ VALOORES Academy Team',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '19%',
      button: 'no',
    },
    {
      image: Academy9,
      title: "VALOORES Academy provides education in the business philosophy of VALOORES",
      description:'',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '31%',
      button: 'no',
    },
    {
      image: Academy10,
      title: "We can't solve problems by using the same kind of thinking we used when we created them ~ Albert Einstein",
      description: '~ Albert Einstein',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '20%',
      button: 'no',
    },
    {
      image: Academy11,
      title: "VALOORES Academy brings direct connections between the value employees' offer and VALOORES’ bottom line through competency based human resource management",
      description: '',
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '14%',
      button: 'no',
    },
    {
      image: Academy12,
      title: "Prescriptive Analytics",
      description: 
      (
        <>
          Advise on: “What is the best course of action?”<br />
          Simulate multiple futures and assess their outcomes
        </>
      ),
      header: "Prescriptive Analytics",
      link: '/banking/feature?page=analytics',
      top: '20%',
      button: 'no',
    },
  ];

  export default Slides;