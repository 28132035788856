const taxCompliance = [
    {
        title: "in'FATCA",
        section: [
            {
                title: "OVERVIEW",
                desc: "The Foreign Account Tax Compliance Act (FATCA) enacted in 2010 by the U.S Congress has a wide range of implications for Banks and Financial institutions over the world. A vigorous, cost-effective solution that can be integrated into the bank's IT environment without impacting their day to day operations is required to fill the need imposed by this regulation. VALOORES in' Foreign Account Tax Compliance Act (FATCA) Management solution helps you mitigate the risk accompanied with FATCA on time and at lower risk:",
                list: [
                    "Pre-configured account categorization rules and indicia checks",
                    "Existing account review rules",
                    "Due diligence cases based on industry leading best practice",
                    "Withholding account/transaction definitions",
                    "Various format for reports, including Excel, PDF, text, and XML",
                ]
            },
            {
                title: "KEY BENEFITS",
                desc: "",
                list: [
                    "Lowers implementation risk by utilizing compliance architecture",
                    "Reduces lead time by leveraging existing onboarding system",
                    "Provides real-time feedback to existing on-boarding systems",
                    "Manages FATCA list and withholding list from a single repository",
                    "Provides a 360-degree view of the customer from FATCA perspective",
                ]
            },
            {
                title: "KEY FEATURES",
                desc: "",
                list: [
                    "Pre-configured account categorization rules and indicia checks",
                    "Existing account review rules",
                    "Due diligence cases based on US compliance best practices",
                    "Full audit trail, configurable rules, and common data model",
                    "Full work flow for creation and filing of electronic reports to the US or local regulators",
                    "Withholding account/transaction definitions",
                    "Export reports on various formats such as PDF, Text file and XML",
                ]
            },
        ],

    },
    {
        title: "in'CRS",
        section: [
            {
                title: "OVERVIEW",
                desc: 
                (
                    <>
                        The Common Reporting Standard (CRS), formally referred to as the Standard for Automatic Exchange of Financial Account Information, is an information standard for the automatic exchange of information (AEoI), developed in the context of the Organization for Economic Co-operation and Development (OECD). The legal basis for exchange of data is the Convention on Mutual Administrative Assistance in Tax Matters and the idea is based on the USA Foreign Account Tax Compliance Act (FATCA) implementation agreements.
                        <br/>
                        Starting July 2015, 53 jurisdictions signed an agreement to automatically exchange information. This agreement specifies the details of what information will be exchanged and when, as set out in the Standard.
                        <br/>
                        There are still more than 100 countries which will not participate in the automatic information exchange. Many of those that have not signed are small countries. Among the large countries, USA has not signed the treaty.
                    </>
                ),
                list: ['']
            },
            {
                title: "KEY BENEFITS",
                desc: "",
                list: [
                    "Reduce compliance costs: Use compliance work already performed for FATCA to ensure a standardized, cost effective model for a bilateral automatic exchange of tax information, rather than building multiple platforms.",
                    "Outsourcing: Advisory and consulting services allow you to work with a subject matter expert to define your firm's procedures and tax documentation, reporting and withholding requirements.",
                    "Provides compliance flexibility: Solution for due diligence and reporting compliance with CRS, FATCA and Crown Dependencies and Overseas Territories (CDOT) requirements and local Intergovernmental Agreement (IGA) reporting, once local competent authorities issue guidance.",
                    "Expertise: Benefit from our history of working with tax authorities and tools that provide confidence for tax compliance and audit readiness.",
                ]
            },
            {
                title: "KEY FEATURES",
                desc: "",   
                list: [
                    "The Common Reporting Standard (CRS) requires financial institutions in a jurisdiction to report to their tax administration the financial accounts held by non-resident individuals and entities or, certain entities controlled by non-resident individuals.",
                    "Tax administrations are expected to share the financial account information with other jurisdictions committed to the CRS through formalized arrangements.",
                    "The CRA would give to each foreign jurisdiction with which it has formalized a CRS partnership information on account holders who reside in that jurisdiction.",
                    "To improve country's ability to protect its tax base, the CRA would receive information on financial accounts held by residents outside of the country from its CRS partners.",
                ]
            },
        ],

    }
]

export default taxCompliance