import Card1 from '../../img/gov/imgs/card1.jpg';
import Card2 from '../../img/gov/imgs/card2.jpg';
import Card3 from '../../img/gov/imgs/card3.jpg';
import Card4 from '../../img/gov/imgs/card4.jpg';
import Card5 from '../../img/gov/imgs/card5.jpg';
import Card6 from '../../img/gov/imgs/card6.jpg';
import Card7 from '../../img/gov/imgs/card7.jpg';
import Card8 from '../../img/gov/imgs/card8.jpg';

const CardsArray = [
    {
        img :  Card1,
        title: "in'Governance",
        desc: "With VALOORES' in'Governance, government agencies will be able to harness the power of its analytics based engine and business intelligence (BI) tools in order to determine the progress of government initiatives, track services employed, and consumer behavior in the context of political, social, and economic development."
    },
    {
        img :  Card2,
        title: "in'Data Governance",
        desc: "With the use of businesses intelligence (BI) applications, it is vital for government agencies to manage the Master Data Management (MDM) in order to offer a consistent and centralized view of the data across the enterprise's systems. VALOORES MDM solution is designed to centralize, cleanse, govern and share data across the organization's systems, and to synchronize it downstream with the different applications and platforms."
    },
    {
        img :  Card3,
        title: "in'Analytica",
        desc: "Prescriptive and predictive analytics play a vital role in governments' capacity to understand and predict consumer behavior and needs, and functions as an optimal way to track strategic KPI's and KRI's."
    },
    {
        img :  Card4,
        title: "in'KYC",
        desc: "Government agencies are constantly working to deliver basic services to citizens in order to improve livelihoods and standards of living. Deeply understanding their citizens and identifying growing trends and changing demographics, is critical for governments in carrying out this basic function. VALOORES' Know Your Citizen allows public sector agencies to easily access, collect, and process user data, essential for providing public services and goods."
    },
    {
        img :  Card5,
        title: "in'Fraud",
        desc: "VALOORES in'Fraud harnesses the power of predictive analytics and network link analysis combined with our rules based engine in order to uncover suspicious behavior and stop fraudulent transactions from happening. This service allows users to focus their attention on cases worth investigating through a dynamic and smart analytics alert system, utilize case management tools for further investigation, and uncover big data linkages between various individuals and organizations."
    },
    {
        img :  Card6,
        title: "Dynamic Dashboards & Reports",
        desc: "Government operations and the need to deliver services have never been so complex given the growing organizational structures and bureaucratic reporting needs. VALOORES dashboard tools & reports equip agencies with the capability to tackle these workflow and reporting challenges by automating basic functions, instituting report structuring mechanisms, and streamlining workflow."
    },
    {
        img :  Card7,
        title: "Communication & Digital Portals",
        desc: "VALOORES recognizes the governance challenges facing agencies in mandating and communicating state wide initiatives to the public. Bridging the gap between the public and private sectors through efficient services delivery and effective communication tools through the digitization and automation."
    },
    {
        img :  Card8,
        title: "in'Profit for Government",
        desc: "VALOORES in'Profit drives a government's balance sheets and financial and fiscal planning. Rich, intelligent & compliant to international standards are in'Profit's bread and butter. Agencies can conduct public planning financials, performance and compliance management, and help achieve sustainable government spending in order to successfully deliver services and program citizens need."
    },
]

export default CardsArray