import React from 'react';
import { useLocation } from 'react-router-dom';
import Banner1  from '../../img/insurance/overview/banner1.jpg'
import Banner2  from '../../img/insurance/overview/banner2.jpg'
import Banner3  from '../../img/insurance/overview/banner3.jpg'
import Banner4  from '../../img/insurance/overview/banner4.jpg'
import Banner5  from '../../img/insurance/overview/banner5.jpg'
import Banner6  from '../../img/insurance/overview/banner6.jpg'
import Banner7  from '../../img/insurance/overview/banner7.jpg'
import Banner8  from '../../img/insurance/overview/banner8.jpg'
import Banner9  from '../../img/insurance/overview/banner9.jpg'
import Banner10  from '../../img/insurance/overview/banner10.jpg'
import Sidebar from '../../admin/sidebar';

const Overview = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedVariable = searchParams.get('page');
  let banner, overview, keyBenefits, keyFeatures;

  switch (receivedVariable) {
    case 'claimsManagement':
      banner = Banner1
      overview = "VALOORES in’ Claims Management delivers an enterprise wide claims platform that helps carriers dramatically improve claims efficiency and lower claims adjusting costs while improving customer satisfaction. It accomplishes this by cutting claims resolution times, improving adjuster productivity, ensuring consistent claims adjusting and smoothing the hand-offs between different claims constituents."
      keyFeatures = (
        <ul>
          <li>Flexible and Efficient Claims Business Processes on a next generation Insurance Technology Platform</li>
          <li>Solution for all internal and external claim constituents across a variety of channels— call center, Internet self-service, and third-party service providers</li>
          <li>Integrated ability to reduce claims fraud through a combination of retroactive and predictive modeling tools</li>
          <li>Fully integrated analytics and dashboards</li>
        </ul>
      )
      keyBenefits = (
        <ul>
          <li>Comprehensive claim management across the entire claim life cycle—includes intake, coverage determination, assignments, file management, recoveries, and settlement</li>
          <li>End-to-end, Web-based claims system that supports full straight-through processing through business process automation</li>
          <li>Embedded Rules Engine supporting rules-based processes to drive best practice enforcement and compliance</li>
          <li>Task Based User Interface to simplify complex tasks and ensure best practice enforcement</li>
        </ul>
      )
      break;
    case 'DataFoundation':
      banner = Banner2
      overview = "The basis of the VALOORES in'Insurance in’Data Foundation is the data model. Unlike other industry data model offerings, VALOORES’ data model is physicalized, pre-built, and readily deployable. The data model provides a comprehensive set of entities and attributes that can capture data from multiple source systems and business processes (such as policy, finance, claims and actuarial systems) across insurance, including Life, Non-Life, Health and Re-Insurance. The VALOORES in'Insurance solution allows insurers to capture data at the most granular event level covering both financial and non-financial events, and also provides detailed definitions for a comprehensive set of asset instruments. Additionally, it has detailed structures for policies, actuarial information, economic scenarios and reference data related to product, organization and chart of accounts."
      keyFeatures = (
        <ul>
          <li>Physicalized, proven, unified and comprehensive data model ready for timely deployment</li>
          <li>Includes pre-built data quality checks contextualized to end use</li>
          <li>Combines results from multiple business areas to easily and securely support cross-functional analytics throughout the enterprise</li>
          <li>Specialized Finance & Risk marts to drive line of business control and performance</li>
          <li>Key management reports and dashboards that provide actionable information to executives</li>
        </ul>
      )
      keyBenefits = (
        <ul>
          <li>Reduce implementation times with readily deployable solution</li>
          <li>Extend advanced analytics with unprecedented ease</li>
          <li>Simplify ETL and achieve the shortest possible load times</li>
          <li>Provide the fastest possible query performance across all business domains</li>
          <li>Eliminate inconsistencies across ledgers, books and marts for confident and auditable reporting</li>
          <li>Track audit trails consistently and transparently across all analytical domains</li>
        </ul>
      )
      break;
    case 'revenueManagement':
      banner = Banner3
      overview = "Providing the best customer service is essential to developing and maintaining long-term customer relationships and satisfying the agents and brokers that manage those relationships. VALOORES’ Insurance Revenue Management and Billing solution delivers a best-in-class solution that is functionally rich, easily upgradeable, and highly efficient for insurance companies of all sizes and lines of business. Built-in business process management tools provide the reliability necessary to ensure precision billing. Managing receivables with brokers and agents is critical for an insurer’s success in maintaining good relationships and improving service to producers. Reduced manual intervention and automated discrepancy resolution rules help improve turn-around time in bill and/or statement finalization. VALOORES’ billing solution comes with advanced business rules governing account current reconciliation processing and automated discrepancy resolution."
      keyFeatures = (
        <ul>
          <li>Streamline and automate operations.</li>
          <li>Web portals for customers and agents to access services directly.</li>
          <li>Automate reconciliation of Accounts Current, reducing the manual effort involved.</li>
        
        </ul>
      )
      keyBenefits = (
        <ul>
          <li>Support multiple lines of business, products and services.</li>
          <li>Support multiple distribution channels, including captive agents, independent agents/brokers and wholesalers.</li>
          <li>Manage high-volume billing and improve customer relationships.</li>
        
        </ul>
      )
      break;
    case 'CalculationEngine':
      banner = Banner4
      overview = "VALOORES in'Insurance Calculation Engine (VICE) is a rules-based product configuration tool that enables carriers to achieve agile business practices without the need to acquire a full policy administration solution. VICE helps carriers to externalize rules and calculations across multiple administration systems and provides business users with a centralized place for defining those rules. It is a single platform that can support multiple application functionalities and support multiple lines of businesses. It is being used to provide calculation services for other business applications, serving as a centralized point from which to manage all of the business enterprise requirements. At the heart of the VALOORES in'Insurance Calculation Engine is a highly advanced product and rules engine that enables straight-through processing throughout the entire life insurance and annuity cycle. VICE supports many different types of products such as Term, Whole Life, Universal Life (including indexed and variable), and different types of Annuity products."
      keyFeatures = (
        <ul>
          <li>Enterprise-wide risk based monitoring, investigations, and reporting for suspicious activities.</li>
          <li>Industry-leading, comprehensive transparent behavior detection library.</li>
          <li>Robust case management streamlines analysis, resolution and regulatory reporting in a single uniﬁed platform.</li>
          <li>Constant investment in innovation to address regulatory changes and IT challenges.</li>
        
        </ul>
      )
      keyBenefits = (
        <ul>
          <li>Accelerate product development and speed time to market for differentiated life insurance and annuity products globally through rapid rules-driven product configuration</li>
          <li>Empower business and technical analysts to collaboratively make changes using business rules without the need to customize the system’s core code or database structure</li>
          <li>Reduce risk, while better managing the business to support compliance and optimize performance through a single platform</li>
          <li>Rapidly support evolving business and regulatory demands through a highly flexible, extensible, and open architecture that integrates with existing IT infrastructure.</li>
        
        </ul>
      )
      break;
    case 'policyAdministration':
      banner = Banner5
      overview = "VALOORES in'Insurance Policy Administration for Life and Annuity is a highly-flexible, rules-based policy administration solution that supports policy issue, billing, collections, policy processing, and claims in a single system. Leading insurance companies worldwide use VALOORES in'Insurance Policy Administration for Life and Annuity to accelerate product development and reduce time to market, improve operational efficiency through centralized policy management and record keeping, and better service their customers and sales channels. The system’s support of SOA, business process management (BPM), and master data management (MDM) technologies allows insurers to consolidate tangled business processes and siloed customer data into cost-effective, transparent, and highly effective business processes. VALOORES offers proven tools and migration methods, along with experienced, highly trained insurance domain and technical personnel and a strong partner network, to help minimize the risk and ease conversions from legacy systems."
      keyFeatures = (
        <ul>
          <li>Highly flexible, rules-based configuration, separate from system’s core code, speeds creation and launch of life insurance and annuity products.</li>
          <li>Product cloning capabilities promote reuse of rules and decrease development time for new products.</li>
          <li>Transaction-level testing through business rules supports quality assurance by improving accuracy and providing audit trail for compliance.</li>
          <li>Release management tool provides visibility, control and management of rules migrations and standards.</li>
          <li>Multi-language and multi-currency support in single instance of system helps regional and global carriers comply with localization requirements.</li>
        
        </ul>
      )
      keyBenefits = (
        <ul>
          <li>Leverage a single system to support multiple lines of business and products for improved operational efficiency and reduced total cost of ownership.</li>
          <li>Bring new products to market faster through collaborative product development by your business and IT resources.</li>
          <li>Configure changes using business rules, without the need to customize or recompile system’s core code.</li>
          <li>Increase product development flexibility using configurable business rules to create complex features and add riders.</li>
          
        
        </ul>
      )
      break;
    case 'EntrepriseRating':
      banner = Banner6
      overview = "Accurate rates mean getting the right products to the right people at the right time. With VALOORES in'Insurance in’Enterprise Rating—the adaptive, centralized rating solution for product management and premium calculation — you can quickly react to market changes and effectively manage the policy lifecycle. With VALOORES in'Insurance in’Enterprise Rating, you can augment your legacy systems and centralize rating to a single system. Additionally, because it is Web-based and uses XML and Web services, the VALOORES in'Insurance is flexible and scalable to change and grow with your business with proven run-time and design-time integrations. The VALOORES in'Insurance in’Enterprise Rating solution is a simple, fast, and accurate rating system designed for business users. VALOORES in'Insurance delivers the following benefits:"
      keyFeatures = (
        <ul>
          <li>Improved rating accuracy and reduced risk with a single, centralized rating engine</li>
          <li>Flexible, scalable architecture enables launch and distribution of new products, lines of business, and states</li>
          <li>Versioning allows more flexibility by facilitating the management of multiple product versions supporting various channels, effective dates, or other criteria</li>
          <li>Supports rate table import from all major data stores</li>
          <li>Auditability and reports support governance and regulatory compliance</li>
          <li>Rating worksheet provides a detailed view of the premium calculations</li>
        
        </ul>
      )
      keyBenefits = (
        <ul>
        <li>Reduces total cost of ownership by creating a single rating engine</li>
        <li>Enhances distribution management by easily integrating with other systems and enabling straight-through processing</li>
        <li>Improves risk management through multivariate pricing and rate analysis capabilities</li>
        <li>Clear visibility into the rating process for more effective risk management and revenue optimization</li>
        <li>Fast, precise rules engine allows carriers to automate underwriting decisions</li>
        <li>Provide regulators and stakeholders a comprehensive view of ﬁnancial activity and customer risk</li>
          
        
        </ul>
      )
      break;
    case 'CRMInsurance':
      banner = Banner7
      overview = "VALOORES’ In’CRM Insurance Solution addresses the distribution needs in the Insurance industry. We provide partner profiling features that are capable of capturing all information in a common environment. Our solution equips channel managers with the ability to perform joint business planning and marketing campaigns as well as analysis of partner performance, thus allowing a carrier to understand its channel better and increase sales and profits across channels. Our solution provides a continuous process for agents to track referrals and leads. Starting with the contact profile, agents are able to view all referrals made by a particular client. As agents log each referral, new leads are created instantaneously, which ensures that referrals do not get lost, thus improving follow-up rates and referral effectiveness."
      keyFeatures = (
        <ul>
  <li>Referral Management
    <ul>
      <li>Automatic lead creation</li>
      <li>Ability to view all referrals made by a particular client</li>
    </ul>
  </li>
  <li>Household Management
    <ul>
      <li>Single household view</li>
      <li>Household, financial, and investment risk profile tracking</li>
    </ul>
  </li>
  <li>Portfolio Tracking
    <ul>
      <li>Consolidated book of business tracking</li>
      <li>Portfolio hierarchy</li>
    </ul>
  </li>
  <li>Lead and Opportunity Management
    <ul>
      <li>Easy lead creation, assignment and qualification</li>
      <li>Lead and opportunity revenue tracking</li>
    </ul>
  </li>
  <li>Contact Management
    <ul>
      <li>Single view of client book of business</li>
      <li>Financial and investment risk profiles</li>
    </ul>
  </li>
</ul>

      )
      keyBenefits = (
        <ul>
        <li>Increase the visibility across your channels and improve channel revenue</li>
        <li>Increase cross sell and up sell opportunities</li>
        <li>Allows for great improvement in customer retention and customer satisfaction</li>
        <li>Improves the agent’s effectiveness in addition to their retention</li>
        <li>Improve your overall business closing ratio</li>
        <li>Decrease your overall expenses and costs</li>
        <li>Improve your Return On Investment</li>
          
        
        </ul>
      )
      break;
      case 'DataExchange':
        banner = Banner8
        overview = "VALOORES’ in’Data Exchange revolutionizes data distribution in the insurance industry. With in’Data Exchange, real-time data is securely exchanged among carriers, producers, and third party service providers through a single touch point, therefore improving the ease of doing business and enabling straight-through processing. VALOORES in’Data Exchange is one of the largest data exchange services for life and non-life insurance companies worldwide and uses a robust network of carriers, producers and service providers."
        keyFeatures = (
          <ul>
            <li>Supports automation of back-office processes to avoid costly and time-consuming data re-entry in multiple systems and reduce errors</li>
            <li>Provides easy access for producers via online insurance portal</li>
            <li>Enables routing of pending case status and commission statements</li>
            <li>Provides support for direct data connections</li>
            <li>Enables integration with various agency management systems</li>
          
          </ul>
        )
        keyBenefits = (
          <ul>
        <li>Quickly and interactively create and assemble compliant documents in structured, interactive and on-demand formats for delivery</li>
        <li>Empower business users to create and customize content faster and minimize reliance on IT</li>
        <li>Service customers globally with our integrated in’Multi-language solution</li>
        <li>Shorten future document preparation by reusing electronically captured and stored data</li>
        <li>Reduce delays and processing time through automating routing and workflow</li>
        <li>Speed up service and ease doing business for agents</li>
          
          </ul>
        )
        break;
      case 'DocumentManager':
        banner = Banner9
        overview = "Policies – endorsements – renewals - Claims correspondence - marketing campaigns – bills - statements. As an insurer, these communications are critical to your business. With VALOORES in’Document Manager, you can manage all of them with a single powerful solution. in’Document Manager enables insurers to dynamically create, manage, publish and deliver adaptive enterprise content throughout the insurance business lifecycle."
        keyFeatures = (
          <ul>
            <li>In’Document Manager dashboards, analytics, reporting and administrative console improves visibility and control of document production</li>
            <li>Rules-based configurability, content library and embedded workflow accelerate collaboration when creating correspondence</li>
            <li>Powerful recipient handling enables one-to-one and one-to-many delivery options</li>
            <li>Conversion tools allow ease of migration from legacy systems, while helping preserve data and documents</li>
          
          </ul>
        )
        keyBenefits = (
          <ul>
       <li>Quickly and interactively create and assemble compliant documents in structured, interactive and on-demand formats for delivery</li>
       <li>Empower business users to create and customize content faster and minimize reliance on IT</li>
       <li>Service customers globally with our integrated in’Multi-language solution</li>
       <li>Shorten future document preparation by reusing electronically captured and stored data</li>
       <li>Reduce delays and processing time through automating routing and workflow</li>
       <li>Speed up service and ease doing business for agents</li>
          
          </ul>
        )
        break;
      case 'GRCInsurance':
        banner = Banner10
        overview = "One of the biggest bottlenecks in getting new insurance products to market is the process of preparing and submitting filings to regulatory bodies. The longer it takes to prepare and receive approval on a new product, the more revenue opportunities are lost. VALOORES In’GRC Insurance speeds up the creation of regulatory filings and enables significant cost savings. Preparing and submitting a filing to regulatory bodies has traditionally been a slow, cumbersome process, relying heavily on paper, file folders, and manuals filled with regulations."
        keyFeatures = (
          <ul>
            <li>Provides end-to-end automation of state filings</li>
            <li>Electronically submits documents directly to Department of Insurance via seamless integration with SERFF</li>
            <li>Includes up-to-date filing requirements</li>
            <li>Manages filings in a central repository</li>
            <li>Seamless market conduct process</li>
          
          </ul>
        )
        keyBenefits = (
          <ul>
       <li>Increased efficiency and decreased time-to-market</li>
       <li>Increased revenue opportunities</li>
       <li>Increased product integrity</li>
       <li>Streamlined market conduct audits of state filings</li>
       <li>Increased product integrity, through a wide range of immediate, accurate management reports and status updates</li>
          
          </ul>
        )
        break;
  }

  return (
    <div className='overview_content mb-5'>
      <div className="banner_bg mb-5" style={{backgroundImage : `url(${banner})`}}></div>
      <div className="main_content container">
          <h6>Overview</h6>
            {overview}
          <h6 className='mt-4'>Key Features</h6>
            {keyFeatures}
          <h6 className='mt-4'>Key Benefits</h6>
            {keyBenefits}
      </div>
    </div>
  );
};

export default Overview;
