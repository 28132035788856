import React from 'react'
import './ServicesGroup.css';
import { Link } from 'react-router-dom';


const Banner = (
  {
    selectedService,
    setSelectedService,
    handleServiceClick,
    selectedServiceId,
    services
  }
) => {
  
  return (
    <>
    <div
        className="services-container d-flex align-items-center justify-content-center"
        style={{
          height: '650px',
          backgroundImage: `url(${selectedService.backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }} >
        <div className="background-overlay" ></div>
        <h1 className="text-white text-center w-50" style={{ fontSize: '3rem' }}>
            {selectedService.herotext}
        </h1>
      </div>
      <div className="services-container_menu d-flex bg-dark-menu justify-content-center z-3 position-relative" style={{ width: '100%', height: '10rem' }}>
        <div className="d-flex justify-content-center flex-wrap">
          {services.map((service) => (
            <Link
              key={service.id}
              smooth={true}
            duration={500}
              className={`btn bg-dark-menu d-flex flex-column align-items-center justify-content-center rounded-0 h-100 fs-4 ${
                selectedServiceId === service.id ? 'active' : ''
              }`}
              style={{
                borderRight: '2px solid #000',
                ...(service.id === 1 && { borderLeft: '2px solid #000' }),
              }}
              onClick={() => handleServiceClick(service.id)}
            >
              {service.icon}
              <h5 className="mt-3" style={{width: '8rem', fontSize: '1rem'}}>{service.title}</h5>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default Banner