import React from 'react'
import Banner from './Ai/Banner'
import Intro from './Ai/Intro'
import Retail from './Ai/Retail'
import Financial from './Ai/Financial'
import LawEnforcement from './Ai/LawEnforcement'

const Ai = () => {
  return (
    <>
       <Banner />
       <Intro />
       <div className="container p-0">
        <hr className='mb-5 mt-3' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
       </div>
       <Retail />
       <div className="container p-0">
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
       </div>
       <Financial />
       {/* <div className="container p-0">
        <hr className='my-5' style={{opacity: '0.5', height: '0.3rem', backgroundColor: '#000'}}/>
       </div> */}
       {/* <LawEnforcement /> */}
    </>
  )
}

export default Ai