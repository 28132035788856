

const inProActive = [
    {
      title: "Overview",
      desc:
      (
        <>
        With VALOORES powerful analytics and intuitive web interface, banks’ personnel can create insightful reports that help making data-driven decisions that ultimately have a positive impact on their bottom line.
        <br />
        <br />
        Our flexible and scalable data warehouse infrastructure addresses the following reporting needs for the financial analysis:
        <br />
        <br />
        <ul>
          <li>Profitability including: Transaction Analysis, Insurance Product Analysis, Customer Profitability, Location Profitability, Product Profitability…</li>
          <li>Customer Relationship Marketing including Customer Interaction Analysis, Customer Behavior, Customer Loyalty…</li>
          <li>Risk including Interest Rate Risk Analysis, Credit Risk Assessment, Insurance Risk Profile, Liquidity Risk…</li>
        </ul>
        <br />
        <br />
        Asset and Liability Management including Credit Loss Provision, Interest Rate Sensitivity, Income Analysis… VALOORES with its fully integrated information technology provides the adequate business intelligence software to report, analyze, and monitor the vast corporate data allowing the decision makers to make better business decisions and to better target performance goals.
        <h3 style={{color:'#007ac1', fontWeight: 'bold', marginBottom: '1rem', fontSize: '16px'}}>MARKET CHALLENGE</h3>
        Many reporting tools exist in the market , but can they all satisfy your basic needs ? Are they all similar? With our tool’s capabilities we look beyond that.
        <br />
        While technology’s evolution users are no longer confined by their office pc , web-based reports are accessible via any web-enabled device. Gather consolidated data that creates and generates reports, a comprehensive Business Intelligence tool that can be run manually or automatically without any need for a technical and/or programming background.
        <br />
        To help your company overcome the need to create, modify existing reports to build a better future we have identified the following goals that have been ranked as top priorities to eliminate the obstacles.
        <br />
        <br />
        <b style={{color:'#666'}}>Obstacles to eliminate:</b>
        <ul>
          <li>Consolidating information</li>
          <li>Understanding data and tables relation</li>
          <li>Complex process to create reports</li>
          <li>Interactive reports</li>
          <li>Ranking reports</li>
        </ul>
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
          'Allow a transparent visibility over the whole application data model',
          'Enhance control over the queries performance',
          'Give an intelligent report optimization module',
          'Highly-performing import / export modules',
          'Enhance interaction with other applications and generation of large-sized reports in multiple formats',
          'Simple Access to all Services’ Data',
          'No need to have a technical profile',
          'Multi-formats',
        ],
      },
      {
        title: "Key features",
        desc:
        (
            <>
              <ul>
                <li>
                Configuration:
                  <ul>
                    <li>Create queries by simple drag and drop</li>
                    <li>Draw your design and add charts and pies.</li>
                  </ul>
                </li>
                <li>
                Management:
                  <ul>
                    <li>Reports for decision making</li>
                    <li>Forecasting</li>
                    <li>Filter data</li>
                    <li>Drill-down Reports</li>
                  </ul>
                </li>
                <li>
                Administration:
                  <ul>
                    <li>All parameters are configured in application.</li>
                  </ul>
                </li>
              </ul>
            </>
        ),
        list:[''],
      },
    
  ];
  
  export default inProActive;
  