  import Risk from '../../img/gov/icons/icon1.png';
  import Data from '../../img/gov/icons/icon3.png';
  import Analytics from '../../img/gov/icons/icon5.png';
  import Risk_white from '../../img/gov/icons/icon1_white.png';
  import Data_white from '../../img/gov/icons/icon3_white.png';
  import Analytics_white from '../../img/gov/icons/icon5_white.png';
  import Img_Risk from '../../img/gov/imgs/image1.jpg';
  import Img_Data from '../../img/gov/imgs/image3.jpg';
  import Img_Analytics from '../../img/gov/imgs/image5.jpg';

  const govSlides = [
      {
        image: Img_Risk,
        title: "The Power of VALOORES in'Government",
        description: "Using Valoores solutions, we provide user friendly, multi-language dashboard and portals with interactive visualizations, easy workflow functionality and business intelligence tools with drill down capabilities.",
        header: "Visual",
        link: '/banking/feature?page=risk-and-compliance',
        icon: Risk,
        icon_white: Risk_white,
        button: 'yes',
      },
      {
        image: Img_Analytics,
        title: "The Power of VALOORES in'Government",
        description: "Stakeholder user accounts workflow and processes are streamlined in order to render information and services accessible and dynamic under a single window option.",
        header: "Render",
        link: '/analytics',
        icon: Analytics,
        icon_white: Analytics_white,
        button: 'no',
      },
      {
        image: Img_Data,
        title: "The Power of VALOORES in'Government",
        description: "Our Solution is grounded on a sound data governance and data sourcing process by collecting and optimizing data from multiple data sources and adopting a scalable and dynamic data modeling approach to accommodate tailored countries’ requirements and needs.",
        header: "Data",
        link: '/banking/feature?page=indatagovernance',
        icon: Data,
        icon_white: Data_white,
        button: 'no',
      },
    ];

    export default govSlides;