
const InCashFlow = [
    {
      title: "Overview",
      desc:
      (
        <>
            In order to be successful both now and in the future, Banks must manage their financial resources carefully. That’s why accurate cash flow planning has emerged as a top priority for today’s CFOs.
            <br />
            However, it has also become one of the most challenging as well due to executing mid and long term strategies by finding investors and access to credit.
            <br />
            VALOORES reduces the time and costs involved in managing financial planning at divisional, corporate and group levels. The highly automated software unifies all interrelated processes starting from strategic and operational planning and extending beyond to the financial close and Reporting including the cash impact at each step along the way.        </>
      ),
      list: [''],
    },
    {
        title: "benefits",
        desc: '  "VALOORES provides a highly collaborative environment supporting Finance and Management Control functions on all enterprise levels."',
        list: [
          "Creating accurate cash flow and balance sheet models and continuous updates based on actuals",
          "Running 'what-if' scenarios or audit trails on existing systems",
          "Defining models to calculate DSO, DPO, days outstanding, days expired, collections and other key performance indicators and determining which method you should use to calculate these KPIs",
          "Managing complex financial planning and balance sheet models by project",
          "Defining Income/Expenses Interest and Taxes according to the cash impact",
          
        ],
      },
      {
        title: "features",
        desc:'',
        list:
        ([
         "Financial and Asset Planning",
         "Direct and Indirect Cash Flow Reporting",
         "Credit/Debit Reporting and Analysis",
         "Cash Flow Management by Project",
         "Manage and control overall performance",
         "Comply with changing regulations and new governance requirements",
        ]),
    }
  ];
  
  export default InCashFlow;
  