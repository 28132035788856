import React from 'react';
import Carousel from './CustomCarousel';
import { Helmet } from 'react-helmet';

import Paralax from './Paralax';
import Application from '../../Pages/Banking/Application';
import Sections from './Sections'
import dashboardImg from '../../img/dashboardParalax.jpg';
import Introduction from './Introduction';
import Di3 from '../../img/Digital/hederahashgraph.jpg';
import Di2 from '../../img/Digital/framework01.png';
import SectionContainer from './SectionContainer';


const insurance = () => {
  const secondSectionD = (
    <>
     VALOORES has continuously provided solutions for the constantly changing insurance business models, to sustain growth and tackle challenges segregated around governance, risk, compliance, profitability, payments, competition, branding, loyalty, Omni-channel experience, trust, and engagement. VALOORES in'Insurance solutions allow clients to transform their insurance business and engage a new generation of consumers, deploy new products, expand into new lines, and increase operational efficiency with modern, flexible, rules-based applications for life, group, and health insurers. VALOORES offers a wide array of solutions in the insurance industry which are capable of tackling the financial obstacles encountered from day to day operations to end of year reports. Business Intelligence is shifted from a luxury to a need, which is why VALOORES’ in’Via puzzles out Governance, Compliance, Profitability and Financial drawbacks and pains
     under one Dynamic, Friendly and Multi-device Dashboard with unlimited drill downs using multiple reporting tools. Clients will be able to effectively manage, track, and automate Compliance, Risk, and Finance Initiatives. VALOORES Applications enable insurance companies to manage risk, reduce data quality issues, improve capital allocation, and manage compliance across different lines of business with ease and confidence. VALOORES’ Insurance solutions are conceived to co-exist with the Insurances’ already existing ecosystem to effectively address multi-directional financials, and secure strategies & objectives. With VALOORES’ insurance technology and applications, clients are given the capability of empowering users and increasing productivity in the workplace. Operations are streamlined and employees are empowered with access to the data, applications, and features they need to do their jobs more effectively.
    </>
  );

  const thirdSectionD = (
    <>
      VALOORES’ platform delivers complete control over every step of the Regulatory Reporting and Analysis Processes, which are key considerations
     
      Testimonials <br/>
    “VALOORES sustained our growth and business evolution to retain customers and anticipate the market’s future needs. in’Via’s dynamic platform governed our actionable decision making and accentuated our elastic operations and reactivity.”
    </>
  );

  

  return (
    <>
      <Helmet>
        <title>Business Intelligence</title>
      </Helmet>
      <Carousel />
      <Introduction />
      {/* <SectionContainer 
        mediaUrl="https://www.youtube.com/embed/71NGqxCw4Po?&autoplay=1"
        mediaType="video"
        backgroundColor=''
        layout=''
        action = 'section1'
      /> */}
        <Sections />
      <SectionContainer 
        title="VALOORES in’Digital Transformation"
        description={secondSectionD}
        // image={Di2}
        backgroundColor='#f1f1f1'
        layout=''
        link='https://digital.valoores.com/pdf/digital-transformation.pdf'
        action = 'section2'
      />
     
       <Paralax 
        img={dashboardImg} 
        title="Powerful Dashboard Visualizations" 
        link='https://Bi.valoores.com/portalbi.php' 
        button='Learn More' 
        laptop='true' 
        height='50rem' 
        top='0%' 
      />
      <SectionContainer 
        title="VALOORES Embraces Hedera Hashgraph for its Regulatory Compliance Life Cycle"
        description={thirdSectionD}
        // image={Di3}
        backgroundColor=''
        layout='flex-row-reverse'
        link='https://digital.valoores.com/pdf/digital-transformation.pdf'
        action = 'section2'
      />
     
     
     
    </>
  );
};

export default insurance;
