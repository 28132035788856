import {useState, useEffect} from 'react';
import axios from 'axios';

const Axios = ({path}) => {
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    if (path === '/solutions') {
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/main_cards/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/services'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/short_cards/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/clients'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/clients/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/counter'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/counter/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/carousel'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/carousel/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else if(path === '/document'){
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/documents/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }else{
      const fetchData = async () => {
        try {
          const response = await axios.get('http://localhost:8800/sections/show')
          setFetchedData(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    }
  }, [path]);

  return fetchedData;
};

export default Axios;
