import React from 'react'
import {Link} from 'react-router-dom'
import laptop from '../../img/laptop.png'
import valooresdashboard from '../../img/valooresdashboard.gif'
import Axios from '../../Components/indexTrial/database/banking_axios';
import ReactHtmlParser from 'react-html-parser';

const Paralax = (props) => {
    const fetchedParalax = Axios({ path: '/banking_sections' });

  return (
    <>
        {fetchedParalax.map((slide) => (
            <>
                {slide.type === `${props.type}` &&
                    <div className='dashboard_container mt-5' style={{background: `url(${require(`../../img/${slide.image_path.split('/').pop()}`)})`, height: `${props.height}`}}>
                        <div className="container d-flex flex-column align-items-center justify-content-center" style={{top: `${props.top}`}}>
                            <div className={slide.text_container}>
                            {ReactHtmlParser(slide.title)}
                                <div className='d-flex flex-row gap-3 justify-content-center'>
                                    <Link to={slide.link} className='mt-3 btn btn-primary btn-show' target='_blank' >
                                        {slide.description}
                                    </Link>
                                    {/* <button className='btn btn-primary btn-show'>Request Demo</button> */}
                                </div>
                            </div>
                            {props.laptop === 'true' ? 
                                <div className={slide.img_container} style={{ backgroundImage: `url(${laptop})`}}>
                                    <img src={valooresdashboard} alt="dashboard" />
                                </div>
                            : ''} 
                        </div>
                    </div>
                }
            </>
        ))}
    </>
  )
}

export default Paralax