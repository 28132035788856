

const InSourcing = [
    {
      title: "Overview",
      desc:
      (
        <>
      VALOORES in' Sourcing is the enterprise application that improve the effectiveness and efficiency of strategic sourcing. They are the key components of VALOORES Advanced Procurement, the integrated suite that dramatically cuts all supply management costs. Cut Costs with Online Collaboration and Negotiation because strategic sourcing is traditionally time-consuming and complex, many organizations are not able to source all of their spending for maximum savings.
      <br/>
      VALOORES in' Sourcing increases the sourcing bandwidth of procurement professionals so they can exploit many more savings opportunities and capture more value from each. Online collaboration and negotiation makes it easy for participants from multiple organizations to exchange information, conduct bid and auction processes, and create and implement agreements. Professional buyers, business experts, and suppliers exchange information online for a more agile and successful sourcing process. The application also dramatically reduces sourcing cycle time and creates a complete audit trail of supplier commitments. With VALOORES in' Sourcing, your organization can find and exploit saving opportunities that were previously untouched.

        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
            "Improves the effectiveness and efficiency of strategic sourcing.",
            "Dramatically cuts all supply management costs.",
            "Enables buyers to source more of the organization’s spend at lower total cost",
            "Creates immediate savings through rapid deployment",
            "Ensures long-term savings with consistent execution and compliance",
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
            "Two Stage evaluation of RFP",
            "Support for Two Stage RFQ surrogate bids",
            "Automatic knockout of suppliers from shortlist",
            "Support for contracts with complex payment terms",
            "Collaboration team member messaging",
            "Staggered closing of lines",
            "Catalog negotiation",
            "Very large auction support",
            "Flexible document styles",
            "Multi-round price enforcement",
            "Position hierarchy approvals",
            "Multi-Org Access Control",
            "Supplier scorecard",
            "Sourcing dashboard",
            "Professional Buyer’s Work Center",
            "Prospective supplier registration",
            "Supplier response PDF",
            
        ],
      },
    
  ];
  
  export default InSourcing;
  