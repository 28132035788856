import React from 'react'
import Banner from '../components/General/Banner/Banner'
import Intro from '../components/General/Intro/Intro'
import RequestDemo from '../components/General/RequestDemo/RequestDemo'
import ConsultancyBanner from '../assets/img/consultancyBanner.jpg'
import Sections from '../components/Consultancy/Sections/Sections'


const Consultancy = () => {
    const consultancyTitle = 
    (
        <span>Precision, Expertise, & <br/> Strategic Insight</span>
    )
  return (
    <div>
        <Banner title={consultancyTitle} img={ConsultancyBanner} classType='consultancyClass' overlay="off"/>
        <Intro addLine='false' title='VALOORES Consulting' desc="Our services encompass Financial Crime Detection, Crowd Intelligence Systems, and advanced technology integration, empowering organizations to optimize performance, enhance compliance, and drive innovation. Leverage our expertise in AI, machine learning, and data analytics to turn complex data into actionable strategies and maintain a competitive edge in today's dynamic market!" colorShade='#fff'/>
        <Sections />
        <RequestDemo />
    </div>
  )
}

export default Consultancy