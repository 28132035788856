import InAsset1 from '../../../../img/inasset/InAsset1.jpg'
import InAsset2 from '../../../../img/inasset/InAsset2.jpg'

const InAsset = [
    {
      img: InAsset1,
      title: "in'Real Estate & Financial Assets",
      desc: "Working with Tier 1 world class clients provided us with the ability and knowledge to effectively tackle problems we faced, on a sole mission of providing our clients with a satisfying...",
      link: "../banking/InAsset?variable=inRealEstate",
      button:"yes",
    },
    {
      img: InAsset2,
      title: "in'Capital Market",
      desc: "in'Capital Market offers solutions in middle-office and back-office functions enabling key capital market players like asset managers, custodians, broker/dealers, fund administrators to retain...",
      link: "../banking/InAsset?variable=inCapital",
      button:"yes",
    },
  ]

  export default InAsset