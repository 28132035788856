import React from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import abl from '../../img/clients/abl.png';
// import ambank from '../../img/clients/ambank.png';
// import arabia_insurance from '../../img/clients/arabia_insurance.png';
import atak from '../../img/clients/atak.png';
import auchan from '../../img/clients/auchan.png';
// import bandq from '../../img/clients/bandq.png';
// import bankaudi from '../../img/clients/bankaudi.png';
import bdl from '../../img/clients/bdl.png';
// import bemo from '../../img/clients/bemo.png';
import bhv from '../../img/clients/bhv.png';
import bob from '../../img/clients/bob.png';
import bobfinance from '../../img/clients/bobfinance.png';
import cvs from '../../img/clients/cvs.png';
import qib from '../../img/clients/qib.png';
import kafalat from '../../img/clients/kafalat.png';
import fnac from '../../img/clients/fnac.png';
import carrefour from '../../img/clients/carrefour.png';
import canadiantyre from '../../img/clients/canadiantyre.png';
import lafayette from '../../img/clients/lafayette.png';
import target from '../../img/clients/target.png';
import fransa from '../../img/clients/fransa.png';
import blf from '../../img/clients/blf.png';
import csc from '../../img/clients/csc.png';
import bandq from '../../img/clients/bandq.png';
import fin from '../../img/clients/fin.png';
import king from '../../img/clients/king.png';
import louisdelhaize from '../../img/clients/louisdelhaize.png';
import mono from '../../img/clients/mono.png';
import nasco from '../../img/clients/nasco.png';
import oken from '../../img/clients/oken.png';
import oracle from '../../img/clients/oracle.png';
import provera from '../../img/clients/provera.png';
import refinitiv from '../../img/clients/refinitiv.png';
import thomsonreuters from '../../img/clients/thomsonreuters.png';
import simply from '../../img/clients/simply.png';
import valeo from '../../img/clients/valeo.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Clients = () => {
    const client_list = [
        
        {
            image: carrefour,
            link: 'https://www.carrefour.com'
        },
        // {
        //     image: qib,
        //     link: 'https://www.qib.com.qa'
        // },
        // {
        //     image: bob,
        //     link: 'https://insurance.valoores.com/'
        // },
        // {
        //     image: kafalat,
        //     link: 'https://kafalat.com.lb/'
        // },
        {
            image: fnac,
            link: 'https://fnac.com/'
        },
        {
            image: cvs,
            link: 'https://www.cvs.com'
        },
        // {
        //     image: bdl,
        //     link: 'https://insurance.valoores.com/'
        // },
        // {
        //     image: bobfinance,
        //     link: 'https://insurance.valoores.com/'
        // },
        {
            image: canadiantyre,
            link: 'https://www.canadiantire.ca/en.html'
        },
        // {
        //     image: fransa,
        //     link: 'https://www.fransabank.com/'
        // },
        // {
        //     image: blf,
        //     link: 'https://www.eblf.com/'
        // },
        // {
        //     image: abl,
        //     link: 'https://banking.valoores.com/'
        // },
        // {
        //     image: ambank,
        //     link: 'https://insurance.valoores.com/'
        // },
        // {
        //     image: arabia_insurance,
        //     link: 'https://insurance.valoores.com/'
        // },
        // {
        //     image: atak,
        //     link: 'https://insurance.valoores.com/'
        // },
        {
            image: auchan,
            link: 'https://banking.valoores.com/'
        },
        {
            image: bandq,
            link: 'https://insurance.valoores.com/'
        },
        // {
        //     image: bankaudi,
        //     link: 'https://banking.valoores.com/'
        // },
        // {
        //     image: bemo,
        //     link: 'https://insurance.valoores.com/'
        // },
        {
            image: bhv,
            link: 'https://insurance.valoores.com/'
        },
        {
            image: csc,
            link: 'https://insurance.valoores.com/'
        },
        {
            image: target,
            link: 'https://www.carrefour.com'
        },
        {
            image: lafayette,
            link: 'https://fnac.com/'
        },
        {
            image: bandq,
            link: 'https://fnac.com/'
        },
        {
            image: fin,
            link: 'https://fnac.com/'
        },
        {
            image: king,
            link: 'https://fnac.com/'
        },
        {
            image: louisdelhaize,
            link: 'https://fnac.com/'
        },
        {
            image: mono,
            link: 'https://fnac.com/'
        },
        {
            image: nasco,
            link: 'https://fnac.com/'
        },
        // {
        //     image: oken,
        //     link: 'https://fnac.com/'
        // },
        {
            image: oracle,
            link: 'https://fnac.com/'
        },
        {
            image: provera,
            link: 'https://fnac.com/'
        },
        {
            image: refinitiv,
            link: 'https://fnac.com/'
        },
        {
            image: simply,
            link: 'https://fnac.com/'
        },
        {
            image: thomsonreuters,
            link: 'https://fnac.com/'
        },
        {
            image: valeo,
            link: 'https://fnac.com/'
        },
    ]

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", transform: 'scale(2)', color: '#001d6e!important', zIndex: '5' }}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", transform: 'scale(2)', color: '#001d6e!important', zIndex: '5' }}
            onClick={onClick}
          />
        );
      }

    const settings = {
        easing: 'linear',
        // fade: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 5,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

  return (
    <>
    
        <div className="clients__container" id='partners_scroll'>
            <div className="container">
                <div className="row">
                    <div className="clients__title">
                        <h2>Sharing with you <span>our list of partners</span></h2>
                    </div>
                    </div>
                    <Slider {...settings}>
                        {client_list.map((client, index) => (
                            <div className="" key={index}>
                                <img src={client.image} alt="" className='w-75' style={{filter: 'grayscale(1)'}}/>
                                {/* <Link className='d-flex justify-content-center' target="_blank"  to={client.link}>
                                    <img src={client.image} alt="" className='w-75' style={{filter: 'grayscale(1)'}}/>
                                </Link> */}
                            </div>
                        ))}
                    </Slider>
            </div>
        </div>
    </>
  )
}

export default Clients