import ClaimsManagement from '../../img/insurance/icons/claims-management-icon.png'
import DataFoundation from '../../img/insurance/icons/datafoundation.png';
import RevenueManagement from '../../img/insurance/icons/revenuemanagementandbilling.png';
import CalculationEngine from '../../img/insurance/icons/calculationengine.png';
import PolicyAdministration from '../../img/insurance/icons/policyadministrationforlifeandannuity.png';
import EntrepriseRating from '../../img/insurance/icons/valoores-insurance-enterprise-rating.png';
import CRMInsurance from '../../img/insurance/icons/crm.png';
import DataExchange from '../../img/insurance/icons/dataexchange.png';
import DocumentManager from '../../img/insurance/icons/documentmanager.png';
import GRCInsurance from '../../img/insurance/icons/grcinsurance.png';



const insuranceSlide = [
    {
        icon : ClaimsManagement,
        Title : "in'Claims Management",
        header : "in'Claims Management",
        cardSection : 'yes',
        Link1 : '/insurance/overview?page=claimsManagement',
        // Link1 : 'https://insurance.valoores.com/claimsmanagement.php',
        
    },
    {
        icon : DataFoundation,
        Title : "in'Data Foundation",
        header : "in'Data Foundation",
        cardSection : 'yes',
        Link1 : '/insurance/overview?page=DataFoundation',
        
    },
    {
        icon : RevenueManagement,
        Title : "in'Revenue Management",
        header : "in'Revenue Management",
        cardSection : 'yes',
        Link1 : '/insurance/overview?page=revenueManagement',
        
    },
    {
        icon : CalculationEngine,
        Title : "in'Calculation Engine",
        header : "in'Calculation Engine",
        cardSection : 'yes',
        Link1 : '/insurance/overview?page=CalculationEngine',
        
    },
    {
        icon : PolicyAdministration,
        Title : "in'Policy Administration",
        header : "in'Policy Administration",
        cardSection : 'yes',
        Link1 : '/insurance/overview?page=policyAdministration',
       
    },
    { 
        icon : EntrepriseRating,
        Title : "in'Enterprise Rating",
        header : "in'Enterprise Rating",
        cardSection : 'no',
        Link1: '/insurance/overview?page=EntrepriseRating',
    
    },
    { 
        icon : CRMInsurance,
        Title : "in'CRM Insurance",
        header : "in'CRM Insurance",
        cardSection : 'no',
        Link1: '/insurance/overview?page=CRMInsurance',
    
    },
    { 
        icon : DataExchange,
        Title : "in'Data Exchange",
        header : "in'Data Exchange",
        cardSection : 'no',
        Link1: '/insurance/overview?page=DataExchange',
    
    },
    { 
        icon : DocumentManager,
        Title : "in'Document Manager",
        header : "in'Document Manager",
        cardSection : 'no',
        Link1: '/insurance/overview?page=DocumentManager',
    
    },
    { 
        icon : GRCInsurance,
        Title : "in'GRC Insurance",
        header : "in'GRC Insurance",
        cardSection : 'no',
        Link1: '/insurance/overview?page=GRCInsurance',
    
    },

  ];

  export default insuranceSlide;