

const inAsset = [
  
    {
      title: " ",
      desc:
      (
        <>
         The highly volatile and liquid nature of today’s financial markets is forcing companies across the Financial Services industry to place renewed emphasis on the most efficient use of capital, balanced with acceptable risk. valoores in' Asset Liability Analytics is designed to provide timely and actionable insight for managing interest rate and liquidity risk and provides transparency into critical issues.
        </>
      ),
      list: [''],
    },
    {
      title: "Overview",
      desc:
      (
        <>
          valoores in'Asset Liability Analytics provides institutions with new levels of insight by empowering all users in the organization with access to a wide range of intelligence and analysis tailored to individual roles and personalized to specific needs. Dynamic interactive dashboards, ad hoc intuitive reporting, alerting, open access, scenario based what if analysis, and waterfall list creations are all within one fully integrated framework. Information as diverse as individual account level characteristics, indicators of interest rate risk, and compliance information can now be found in one comprehensive, reconciled environment. With in'Asset Liability Analytics, financial services professionals have access to turnkey actionable information that enables financial institutions to measure and meet risk-adjusted performance objectives, cultivate a risk management culture through transparency,and lower the costs of compliance and regulation.


        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
          'Analyze and forecast interest rate risk through deterministic and stochastic simulation results',
          'Monitor Liquidity Gaps,Funding Concentrations,Marketable Assets and Liquidity Ratios on a daily basis',
          'Empower all business users to access granular and actionable ALM insight directly within operational processes',
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
          'Align all roles in the Bank around a single source of enterprise insight',
          'Accelerate deployment with reduced cost and risk',
          'Quickly adapt to changing business needs by leveraging existing technology investments',
        ],
      },
    
  ];
  
  export default inAsset;
  