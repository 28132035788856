import React from 'react'
import AcademyBg from '../../img/academy/academyBg.jpg'

const Paralax = () => {
  return (
    <>
        <div className='academy_paralax d-flex align-items-center justify-content-center' style={{backgroundImage : `url(${AcademyBg})`}}>
            <div className="container d-flex align-items-center justify-content-center">
                <h3 className='text-white text-center' style={{width: '65%'}}>
                    Our vision is to develop and customize<br />
                    practical solutions to Human Resources needs,<br />
                    regardless of the line of business of organizations.
                </h3>
            </div>
        </div>
    </>
  )
}

export default Paralax