import React from 'react'
import './Compliance.css'
import { FaPlay } from 'react-icons/fa';

const Compliance = () => {
  return (
    <div className='compliance-container d-flex align-items-center justify-content-center w-100' style={{margin: '5rem 0'}}>
        <div className='compliance_box mx-4 d-flex flex-column align-items-center justify-content-center primary-bg  p-5 text-white text-center gap-3'>
            <h1 className='text-uppercase'>Compliance</h1>
            <p>VALOORES' groundbreaking compliance softwares fuses real-time monitoring, AI-driven insights, and automated reporting, revolutionizing how your business navigates evolving regulations while leveraging the latest tech to slash risk and supercharge efficiency.</p>
            <div className='rounded-circle play_container border p-4'>
                <FaPlay size={30} fill="#fff"/>
            </div>
        </div>
    </div>
  )
}

export default Compliance