import React from 'react';
import { Link } from 'react-router-dom';
import Axios from './database/axios.jsx';
import ReactHtmlParser from 'react-html-parser';

const Business = () => {

  const fetchedCards = Axios({ path: '/solutions' });
  const fetchedData = Axios({ path: '/section' });
  return (
    
    <>
      {fetchedData.map((solutions) => (
        <>
        {solutions.type === 'solutions' && (
        <div className="title_business mt-5" id="business_scroll">
          <div className="container">
              <div className={solutions.text_container}>
                {ReactHtmlParser(solutions.title)}
                {ReactHtmlParser(solutions.description)}
                    {fetchedCards.map((card, index) => (
                      <div className={solutions.image_container} key={index}>
                        <div className={solutions.class}>
                          <Link target="_blank"  to={card.link}>
                            <img src={require(`../../img/${card.image_path.split('/').pop()}`)} alt="1" />
                          </Link>
                          <Link target="_blank"  to={card.link}>
                            <h4 className='fw-bold mb-3'>{card.title}</h4>
                          </Link>
                          <p>{card.description}</p>
                        </div>
                      </div>
                    ))}
              </div>
          </div>
        </div>
        )}
        </>
      ))}
    </>
  )
}

export default Business