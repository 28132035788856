import React from 'react';
import {Link} from 'react-router-dom';
import AML from '../../img/services/aml.jpg';
import service2 from '../../img/Service2.jpg';
import service3 from '../../img/Service3.jpg';
import service4 from '../../img/Service4.jpg';
import service5 from '../../img/Service5.jpg';
import service6 from '../../img/Service6.jpg';
import service7 from '../../img/Service7.jpg';
import service8 from '../../img/Service8.jpg';
import service9 from '../../img/Service9.jpg';
import service10 from '../../img/Service10.jpg';
import service11 from '../../img/Service11.jpg';
import service12 from '../../img/Service12.jpg';

const Services = () => {
  const service = [
    {
      image: AML,
      title: 'AML',
      description: 'Big data has not only changed the way that banks address AML, but has also heightened the expectations... Read More →',
      link: '../banking/riskandcompliance?variable=Aml'
    },
    {
      image: service2,
      title: 'Fraud Management',
      description: 'Rapidly detect and prevent complex fraud schemes to minimize losses, maximize customer trust and reduce... Read More →',
      link: '/banking/riskandcompliance?variable=infraud'
    },
    {
      image: service3,
      title: 'Regulatory',
      description: 'Opportunities to accelerate the Change Management in the Banking sector. Read More →',
      link: '/banking/riskandcompliance?variable=regulatoryreportingsolution'
    },
    {
      image: service4,
      title: 'Payment',
      description: 'VALOORES in\'Payment provides key services such as: Remittance,Bill Payments, Mobile Top-Up, Corporate...  Read More →',
      link: '/banking/feature?page=financialservices'
    },
    {
      image: service5,
      title: 'Via',
      description: 'Delivers a best-in-class solution that is functionally rich, easily upgradeable, and highly efficient for insurance... Read More →',
      link: '/banking/inAnalytics?variable=inVia'
    },
    {
      image: service6,
      title: 'Merchandising Business Suite',
      description: 'Merchandising Business Suite  The right product, sold for the right price, at the right place & time. Read More →',
      link: '/banking/inAnalytics?variable=inRetail'
    },
    {
      image: service7,
      title: 'Investor Relations Financial Performance (IRFP)',
      description: 'Is a single source platform for a powerful array of deep financial data, analytics, and research information... Read More →',
      link: '/banking'
    },
    {
      image: service8,
      title: 'Profit',
      description: 'VALOORES in\'Profit drives a company\'s profitability. Rich, intelligent & compliant to international standards... Read More →',
      link: '/banking/feature?page=inprofit'
    },
    {
      image: service9,
      title: 'Business Intelligence',
      description: 'Collect information from multiple data sources,in different platforms.  Read More →',
      link: '/Bi'
    },
    {
      image: service10,
      title: 'Campaign',
      description: 'The ongoing regulatory changes that occurred to organizations all over the world due to the economic crisis... Read More →',
      link: '/banking/inMarketing?variable=InCampaignManagement'
    },
    {
      image: service11,
      title: 'KYC Know Your Customer',
      description: 'KYC allows you to assess customer risks; meet regulatory requirements and improve overalls... Read More →',
      link: '/banking/InDataA?variable=InKYC'
    },
    {
      image: service12,
      title: 'IFRS9',
      description: 'Regulatory Tsunami"comes to succeed IAS 39 by introducing new requirements and models... Read More →',
      link: '/banking/riskandcompliance?variable=IFRS9'
    }
  ];
  return (
    <>
    <div className="services_section" id='services_scroll'>
        <div className="container">
            <div className="row">
                <div className="services__title">
                    <h2>A <span>Service </span>For Your Every Need!</h2>
                </div>
                {service.map((serv, index) => (
                <Link target="_blank"  key={index} to={serv.link} className='col-md-4 p-3'>
                  <div className="">
                    <div className="img_bg" style={{ backgroundImage: `url(${serv.image})`}}>
                      <div className="overlay__bg"></div>
                      <div className="services__content">
                        <h4 className='text-white'>{serv.title}</h4>
                        <p>{serv.description}</p>
                      </div>
                    </div>
                  </div>
                </Link>
                ))}
            </div>
        </div>
    </div>
    </>
  )
}

export default Services