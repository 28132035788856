import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from 'react-router-dom';
import geoSlides from '../../geo_slides';

const Carousel = ({ setActiveIndex }) => {
  const [activeIndex, setActiveIndexState] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
    setActiveIndex(activeIndex);
  }, [activeIndex, setActiveIndex]);

  const settings = {
    // autoplay: true,
    // autoplaySpeed: 3000,
    focus: false,
    easing: 'linear',
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveIndexState(next);
    }
  };

  const makeActive = (index) => {
    
      setActiveIndexState(index);
  };

  return (
    <>
      <div className='geo_container' style={{ position: 'relative' }}>
        <Slider {...settings} ref={sliderRef}>
          {geoSlides.map((geo, index) => (
            <div className='geo_content' style={{ position: 'relative' }} key={index}>
              <div className="img_geo-content row" style={{ backgroundImage: `url(${geo.image})` }}>
                <div className="overlay_bg"></div>
                <div className="text_geo-content col-md-4" style={{ top: '20%', left: '2rem' }}>
                  <h1 className='w-75'>Geospatial<br /></h1>
                  <p>{geo.description}</p>
                  {/* <Link target="_blank"  to={geo.link} className='btn btn-primary btn-show'>Show More</Link> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className='geo_thumb d-flex justify-content-center text-white gap-1 align-items-center'>
          {geoSlides.map((geo, index) => (
            <div
              className={index === activeIndex ? 'geo_thumb-box active' : 'geo_thumb-box'}
              onClick={() => makeActive(index)}
              key={index}
            >
              <div className="overlay_bg"></div>
              <span style={{fontSize : '1.5rem'}}>{geo.icon}</span>
              {geo.title}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Carousel;
