const basel = [
    {
        title: "Overview",
        desc: 
        (
            <>
                Established to make global banking more resilient to financial and economic stress, Basel III or the Third Basel Accord is set to dramatically impact the banking landscape. Aimed at tackling risks that were not clearly understood or even entirely disregarded, Basel III is the Basel Committee of Banking Supervision's (BCBS) response to the financial crisis of 2008. The revised Basel rules require banks to enhance their counterparty credit risk management and increase the quality, quantity and transparency of the capital base.
                <br/>
                VALOORES's Basel III solution enables banks to adopt a coordinated approach to manage risk and enhance risk-adjusted benefits. Banks can easily capture interactions between market, credit, liquidity, and operational risk; assess and analyze their risks; and develop a risk framework for efficient day-to-day risk management. The solution helps align data from a bank's trading systems with its risk management systems, providing traders in the front office real-time access to rigorous analytics. This helps banks bring risk management to an operational level and effectively optimize the required capital reserve. Supporting risk assessment and computations based on configurable methodologies and algorithms, the solution helps banks prioritize their response strategies for optimal risk/reward outcomes.
            </>
        ),
        list: [],
    },
    {
        title: "Key Benefits",
        desc: '',
        list:
        [
            "Enables banks to establish an enterprise-wide framework, and a uniform risk taxonomy for managing material risks on an ongoing and consistent basis",
            "Support banks in demonstrating to regulatory bodies, credit agencies and accountants their commitment to implementing sustainable, effective, and reliable risk management programs",
            "Provides detailed financial risk analysis, with a clear outlook on the interrelationship between market, credit, liquidity and operational risk.",
            "Enables banks to manage risks across a wide range of processes and functions such as IT, investments and mortgage assets, liquidity and balance sheet, community investments, and non-credit services",
            "Helps banks adopt a standardized and work-flow driven approach to risk and compliance management, which can be successfully applied to newer risks and potential regulatory requirements",
        ]
    },
    {
        title: "Key Features",
        desc: '',
        list:
        [
            "Enterprise-level solution that provides banks with computational power to not only run sophisticated risk analytics, but also deliver results that ensure more profitable, risk-informed decisions.",
            "Provides risk calculators and risk heat maps for risk analysis and monitoring, helps bring about a more interactive, risk-aware decision making.",
            "Delivers intelligent and content driven features such as access to training content from an expert community, and integration of business processes with regulatory notifications and alerts.",
            "Provides an array of advanced capabilities and functionalities combined with a best practice and scalable approach for making a coordinated effort to comply with Basel III requirements",
        ]
    },
]

export default basel