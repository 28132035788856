import sunset from '../../img/Vservice/sunset.jpg'; 
import banques from '../../img/Vservice/banques.jpg';
import sunflower from '../../img/Vservice/sunflower.jpg'; 
import nature from '../../img/Vservice/nature.jpg'; 
import freephoto from '../../img/Vservice/freephoto.jpg'; 
import brid from '../../img/Vservice/Brid.jpg'; 
import cut from '../../img/Vservice/Cut.jpg'; 
import cutedog from '../../img/Vservice/cutedog.jpg'; 
import polar from '../../img/Vservice/polar.jpg'; 

const servicesContent = [
    {
      id: 1,
      herotext: 'The Fusion of Technology & Data for Financial Synergy',
      title: '',
      description: (
        <>
          <div>
            <h2 className="fw-bold mb-4">Introducing the Valoores Financial Digital Services (VFDS):<br /> Powering Seamless Compliance in a Complex Financial World</h2>
            <h4>The Valoores Regulatory Compliance Suite provides a comprehensive set of solutions for financial services, ensuring global compliance adherence and scalable programs while delivering dynamic support for effective compliance controls against Financial Crime and regulatory reporting requirements.</h4>
          </div>
          <div className='my-5'>
            <h2 className="fw-bold mb-4">Elevating Performance with Next-Generation Solutions</h2>
            <h4>VFDS data processing platform to integrate diverse data streams, enhance compliance with AI-driven analytics, and ensure top-tier information security across all solutions.</h4>
          </div>
        </>
      ),
      backgroundImage: sunset, 
    },
    {
      id: 2,
      herotext: 'Creative Journey',
      title: '',
      description: 'Join us for a transformative experience that will empower you to reach new heights in your personal and professional life.',
      backgroundImage: banques
    },
    {
        id: 3,
        herotext: 'Inspire Change',
        title: 'The Art of Mindfulness',
        description: 'Explore techniques for living in the moment and reducing stress through our guided mindfulness practices and sessions.',
        backgroundImage: sunflower
      },
      {
        id: 4,
        herotext: 'Bold Vision',
        title: 'Navigating Career Changes',
        description: 'Gain insights and tools to successfully transition into your next career phase with expert advice and networking opportunities.',
        backgroundImage: nature
      },
      {
        id: 5,
        herotext: 'Future Forward',
        title: 'Healthy Living Made Simple',
        description: 'Discover practical tips and strategies for maintaining a balanced lifestyle, from nutrition to fitness and mental well-being.',
        backgroundImage: freephoto
      },
      {
        id: 6,
        herotext: 'Unlock Potentia',
        title: 'Building Lasting Relationships',
        description: ' Learn effective communication and relationship-building skills to foster deeper connections in both your personal and professional life.',
        backgroundImage: brid
      },
      {
        id: 7,
        herotext: 'Elevate Success',
        title: 'Innovative Leadership',
        description: ' Transform your leadership style by embracing innovative approaches that inspire teams and drive success.',
        backgroundImage: cut
      },
      {
        
        id: 8,
        herotext: 'Dynamic Growth',
        title: 'Creative Problem Solving',
        description: ' Enhance your critical thinking and creativity with strategies designed to help you tackle challenges in unique ways. ',
        backgroundImage: cutedog
      },
      {
        id: 9,
        herotext: 'Endless Possibilities',
        title: 'Sustainable Living Practices',
        description: '  Explore sustainable habits that can make a positive impact on the environment and your community.',
        backgroundImage: polar
      },
    
  ];
  
  export default servicesContent;
  