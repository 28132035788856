import React from 'react'
import './Sections.css'
import SectionsArray from './sectionsArray';
import Intro from '../Intro/Intro';

const Sections = () => {
  return (
    <div className='container'>
      <Intro title='WHAT SETS US APART'/>
      <div className="vfds_cards d-flex flex-column flex-lg-row justify-content-center gap-3 w-100 container m-0">
        {SectionsArray.map((section, index) => (
         <div key={index} className='vfds_card '>
          <div className='vfds_card-img position-relative' style={{backgroundImage: `url(${section.img})`}}></div>
          <div className="text-content p-3">
            <h3>{section.title}</h3>
            <p>{section.desc}</p>
          </div>
          <div className="overlayEffect"></div>
         </div>
        ))}
      </div>
    </div>
  )
}

export default Sections