import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Counter() {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const [editId, setEditId] = useState('');
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editedFile, setEditedFile] = useState(null);

  const [fetchedData, setFetchedData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadResponse = await axios.post('http://localhost:8800/counter/upload', formData);
        const imagePath = uploadResponse.data.imagePath;
        const iconName = uploadResponse.data.iconName;

        const res = await axios.post('http://localhost:8800/counter/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          imagePath: imagePath,
          iconName: iconName,
        });
      } else {
        console.log('Please select a file.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:8800/counter/show');
      setFetchedData(response.data);
      setIsDataFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (value, value2, value3, path, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(path);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, delete_path) => {
    try {
      const response = await axios.delete('http://localhost:8800/counter/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue4);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/counter/editIcon', formData);
        const imagePath = uploadEditResponse.data.imagePath;
        console.log(imagePath);

        const res = await axios.put('http://localhost:8800/counter/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      } else {
        const res = await axios.put('http://localhost:8800/counter/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>
      <div>
        <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
          <h1>Counter</h1>
          <button className="btn btn-primary" onClick={handleAddModalOpen}>
            Add Counter
          </button>
        </div>
        {isDataFetched && (
          <div className="tableStyle">
            <table className="table">
              <thead className="sticky-header">
                <tr>
                  <th>ID</th>
                  <th>Value</th>
                  <th>Extension</th>
                  <th>Title</th>
                  <th>Icon Path</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {fetchedData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.value}</td>
                    <td>{item.extension}</td>
                    <td>{item.title}</td>
                    <td>{item.icon_path}</td>
                    <td>
                      <button
                        className="btn btn-secondary"
                        to="#"
                        onClick={() =>
                          handleClick(
                            item.value,
                            item.extension,
                            item.title,
                            item.icon_path,
                            item.id
                          )
                        }
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() =>
                          handleDelete(
                            item.id,
                            item.related_id,
                            item.icon_path
                          )
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* EDIT CAROUSEL MODAL */}
        <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleEditModalClose}
        contentLabel="Edit Modal"
        >
            <div className="">
                <div className="">
                    <div className="">
                        <form className='edit_form'>
                            <div>
                                <h5 className="pb-3 px-3">Edit Counter</h5>
                            </div>

                            <div className=" px-4">
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <label htmlFor="CountId" >Counter ID</label>
                                        <input
                                            id="CountId"
                                            type="text"
                                            disabled
                                            readOnly
                                            value={editId}
                                            placeholder="Counter Edit ID"
                                            className='form-control my-3'
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="CounterVal">Counter Value</label>
                                        <input
                                            id="CounterVal"
                                            type="text"
                                            value={editValue}
                                            onChange={handleInputChange}
                                            placeholder="Counter Edit Value"
                                            className='form-control my-3'
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <label for="CounterExt">Counter Extension</label>
                                            <input
                                                id="CounterExt"
                                                type="text"
                                                value={editValue2}
                                                onChange={handleInputChange2}
                                                placeholder="Counter Edit Extension"
                                                className='form-control my-3'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <label htmlFor="CounterTitle">Counter Title</label>
                                            <input
                                                id="CounterTitle"
                                                type="text"
                                                value={editValue3}
                                                onChange={handleInputChange3}
                                                placeholder="Counter Edit Title"
                                                className='form-control my-3'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label htmlFor="CounterIcon">Icon File</label>
                                        <input id="CounterIcon" type="file" onChange={handleFileEdit} 
                                        className='form-control my-3'/>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 d-flex justify-content-end gap-3">
                                <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                                <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </Modal>

        {/* ADD SECTION MODAL */}
        <Modal
          isOpen={isAddModalOpen}
          onRequestClose={handleAddModalClose}
          contentLabel="Add Modal"
        >
          
          <div className="">
            <div className="">
              <div className="">
                <form className='add_form'>
                  <div>
                    <h5 className="pb-3 px-3">Add Counter</h5>
                  </div>

                  <div className=" px-4">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <label htmlFor="addCounterVal">Counter Value</label>
                        <input
                            id="addCounterVal"
                            type="text"
                            value={input1}
                            onChange={(e) => setInput1(e.target.value)}
                            placeholder="Counter Value"
                            className='form-control my-3'
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="addCounterExt">Counter Extension</label>
                        <input
                          id="addCounterExt"
                          type="text"
                          value={input2}
                          onChange={(e) => setInput2(e.target.value)}
                          placeholder="Counter Extension"
                          className='form-control my-3'
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label htmlFor="addCounterTitle">Counter Title</label>
                          <input
                                id="addCounterTitle"
                                type="text"
                                value={input3}
                                onChange={(e) => setInput3(e.target.value)}
                                placeholder="Counter Title"
                                className='form-control my-3'
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <label htmlFor="addCounterIcon">Icon File</label>
                          <input id="addCounterIcon" type="file" onChange={handleFileChange} className='form-control my-3'/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 d-flex justify-content-end gap-3">
                    <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                    <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Counter;
