import React, { useEffect, useState, useRef, useMemo } from "react";
import counter2 from '../../img/counter/counter2.png';
import counter4 from '../../img/counter/counter4.png';

const Counter = () => {
  const counters = useMemo(
    () => [
      {
        pos: 1,
        image: counter2,
        number: 5000,
        description: (
            <>
                Application Data Source <br/> 2022
            </>
        )
      },
      {
        pos: 2,
        image: counter4,
        number: 51.25,
        description:  (
            <>
                Geospatial Technology Market <br/> CAP 2022
            </>
        )
      }
    ],
    []
  );

  const duration = 2000;
  const [counts, setCounts] = useState(Array(counters.length).fill(0));
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const startTime = Date.now();
        const interval = setInterval(() => {
          const elapsed = Date.now() - startTime;
          if (elapsed >= duration) {
            clearInterval(interval);
            setCounts(counters.map(({ number }) => number));
          } else {
            const newCounts = counters.map(({ number }) => Math.min(Math.floor(number * elapsed / duration), number));
            setCounts(newCounts);
          }
        }, 50);
        
        return () => clearInterval(interval);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [counters]);

  return (
    <div ref={ref} className="my-5">
      <div className="counter_title mt-5">
        <div className="container">
          <div className="row d-flex flex-row mb-3">
            <h1>Why choose <span className='fw-bold mb-5'>VCIS?</span></h1>
            <div className='inner_container' >
              {counters.map((slide, index) => (
                <div className="counter_content d-flex" key={index}>
                  <img className='icon-img' src={slide.image} alt="" />
                  <div className='d-flex flex-row align-items-center'>
                    {slide.pos=== 1 ? <><p className='number'>{counts[index]}</p>
                    <span>+</span></> : <><span>$</span><p className='number'>{counts[index]}</p>
                    <span>B</span></>}
                    
                  </div>
                  <div className='counter-desc'>
                    <p>{slide.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Counter;
