import Overview1 from '../../../assets/img/overview1.jpg';
import Overview2 from '../../../assets/img/overview2.jpg';

const sectionsArray = [
    {
        img: Overview1 ,
        title: 'Our Mission',
        desc: 'Our mission is to provide a comprehensive turnkey solutions for developing for our clients for all domains of Geospatial Intelligence.',
    },
    {
        img: Overview2 ,
        title: 'Our Vision',
        desc: 'Produce unique products for our customers by bringing in the most advanced engineering and scientific minds.',
    },
]

export default sectionsArray;