

const InProductManagement = [
    {
      title: "Overview",
      desc:
      (
        <>
      A representation of the way in which the parts of a product fit together and interact, organized in levels of detail based on structure. VALOORES in' Product Structure Management is used to manage changes, testing, and problem resolution for complex products with a large number of interdependent parts and functions.
        </>
      ),
      list: [''],
    },
  
  ];
  
  export default InProductManagement;
  