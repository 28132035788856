
const inModelRiskManagement = [
    {
      title: "Overview",
      desc:
      (
        <>
           Financial institutions use models in critical functions including risk quantification, fraud and money laundering detection, performance management and business development. Recent events have highlighted the need for organizations to build a control framework around the use of models. Regulators are also asking institutions to provide detailed information on the models being used and the governance around use of such models. valoores in' Model Risk Management provides institutions the ability to build a single repository of model information across the organization and improve governance through periodic and structured assessments of risks associated with the use of each model.
        </>
      ),
      list: [''],
    },
    {
      title: "Key features",
      desc:'',
      list:
      [
        'Build a common, enterprise-wide environment to manage information on models used across the organization',
        'Establish a comprehensive, structured framework for periodic risk assessment of models across categories',
        'Address regulatory requirements with on-demand access to key indicators on model risk through pre-built reports and dashboards',
        'Mitigate risks arising out of models through a formal mechanism for tracking issues and actions',
        'Enable an enterprise-wide risk management framework by leveraging other industry-leading applications',
          
      ],
    },
    {
      title: "key Features",
      desc:'',
      list: 
      [
        'Single repository for information on models used across all categories including risk, finance, capital adequacy, AML and fraud',
        'Provides a structured framework for periodic risk assessment of models',
        'Includes capability to create issues and action plans based on risks identified in use of each model',
        'Pre-configured dashboards that provide on-demand access to model information',
        'Ability to enhance pre-configured workflows using workflow management',
      ],
    }
  ];
  
  export default inModelRiskManagement;
  