import React from 'react'
import Banner from '../components/General/Banner/Banner'
import Intro from '../components/General/Intro/Intro'
import Sections from '../components/Industries/Sections/Sections'
import Industry from '../assets/img/industriesBanner.jpg'
import RequestDemo from '../components/General/RequestDemo/RequestDemo'
import LogoColored from '../assets/img/logoColored.png'

const Industries = () => {
  const introContentTitle = (
    <>
      <div className='industrie_page-title d-flex flex-row align-items-center justify-content-center gap-2'>
        <h1 className='m-0 font--poppins' >@</h1>
        <img src={LogoColored} alt="" />
      </div>
    </>
  )

  return (
    <div>
        <Banner title='OUR INDUSTRY FOCUS' desc='Expertise Across Industries' img={Industry} classType='industryClass'/>
        <Intro  desc="We believe in the power of technology to transform Industries. Our tailored solutions empower businesses in diverse sectors to innovate, optimize, and thrive in a rapidly changing landscape."/>
        <Sections />
        <RequestDemo />
    </div>
  )
}

export default Industries