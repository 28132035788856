const valuesArray = [
    {
        title: 'Professionalism',
        desc: "“We attract the highest caliber of engineers and scientists to lead our products and services”",
    },
    {
        title: 'Integrity',
        desc: "“Process Management, Quality Control and Honesty define our business ethics”",
    },
    {
        title: 'Excellence',
        desc: "“We seek to offer the most technologically advanced artificial intelligence integrated system”",
    },
    {
        title: 'Family Values',
        desc: "“We are a family company, going back generations, offering a family atmosphere in all our work environments”",
    },
]

export default valuesArray;