import React from 'react'
import MissionBg from '../img/Mission.jpg'
// import MissionBg1 from '../img/Mission2.jpg'

const Mission = () => {
  console.log('selected')
  return (
    <>
      <div className="row mission_container p-3 d-flex align-items-center">
        <div className="col-md-6">
          <div className="mission_bg">
            <div className="vision_text">
              <h4>Vision</h4>
              <p className='spacing_out'>Valoores' vision is to help people and organizations make the best decisions that will positively impact overall growth and performance.</p>
            </div>
            <div className="mission_text mt-5">
              <h4>Mission</h4>
              <p className='spacing_out'>Our mission is to provide a comprehensive and intuitive solution based on cutting-edge technology, tailored to meet the unique needs of businesses. Our solutions offer interactive tools, allowing users to optimize their operational results, reduce administrative costs, and maintain regulatory compliance and international standards. Our commitment is to deliver a flexible and customizable solution that empowers organizations to achieve your goals efficiently and effectively.</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {/* <div className='mission_img' style={{backgroundImage: `url(${MissionBg1})`}}></div> */}
          <div className='mission_img' style={{backgroundImage: `url(${MissionBg})`}}></div>
        </div>
      </div>
    </>
  )
}

export default Mission