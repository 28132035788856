import React from 'react'
import Webinar1 from '../../img/academy/webinars/webinar1.jpg'
import Webinar2 from '../../img/academy/webinars/webinar2.jpg'
import Webinar3 from '../../img/academy/webinars/webinar3.jpg'
import Webinar4 from '../../img/academy/webinars/webinar4.jpg'
import {BiChevronRight, BiChevronsRight} from 'react-icons/bi'
import {AiOutlineClockCircle} from 'react-icons/ai'
import {Link} from 'react-router-dom'

const Webinars = () => {


    const events = [
        {
            img: Webinar1,
            title: "From Pain to Gain in'Time of Covid-19, Recovery will be Digital. in'Financial Services ...",
            date: 'July 22, 2020',
            link: 'https://webinar.valoores.com/webinar7/',
        },
        {
            img: Webinar2,
            title: "From Pain to Gain in'Time of Covid-19, Recovery will be Digital. in'Financial Services ...",
            date: 'July 8, 2020',
            link: 'https://webinar.valoores.com/webinar6/',
        },
        {
            img: Webinar3,
            title: "Cyber COVID Live Webinar: in'Times of Financial Crimes, and Cybersecurity, with All International Regulators...",
            date: 'May 28, 2020',
            link: 'https://webinar.valoores.com/webinar3/',
        },
        {
            img: Webinar4,
            title: "With COVID-19 in'Financial Regulations, What's Left From IFRS 9, BASEL 3, AML…?",
            date: 'June 24, 2020',
            link: 'https://webinar.valoores.com/webinar5/',
        },
    ]

  return (
    <>
        <div className='container'>
            <div className="webinars-heading text-center my-5">
                <h3>VALOORES Webinars</h3>
                <p>Our webinars span the latest industry trends and are offered live online and on-demand</p>
                <Link to='/' target='_blank'>View All <BiChevronsRight /></Link>
            </div>
            <div className='webinars_container'>
                <div className="webinar_content">
                    <div className="row">
                        {events.map((item, index) =>(
                            <div key={index} className="col-md-6 mb-5">
                                <div className='webinar_content-container d-flex flex-column gap-2'>
                                    <img src={item.img} alt=""  className='w-100'/>
                                    <div className="webinar_content-text p-3">
                                        <h5>{item.title}</h5>
                                        <div>
                                            <p className='p-0 m-0'><AiOutlineClockCircle /> {item.date}</p>
                                            <Link to={item.link} target='_blank'>More <BiChevronRight /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         ))}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Webinars