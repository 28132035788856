import React from 'react'

const Banner = (
    {
        img,
        title,
        description
    }
) => {
  return (
    <>
        <div className='retail__banner' style={{backgroundImage: `url(${img})`}}>
            <div className="overlay"></div>
            <div className="geospatial__content text-center px-4 w-50">
                <h1>{title}</h1>
                <h3>{description}</h3>
            </div>
        </div> 
    </>
  )
}

export default Banner
