import React from 'react'
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
    const opts = {
      height: '320',
      width: '100%',
      playerVars: {
        autoplay: 0,
        mute: 1,
        modestbranding: 1,
        rel: 0,
      },
    };
  
    return <YouTube videoId={videoId} opts={opts} />;
  };

const Intro = () => {
  const videoId2 = 'Dv637iolVkM';

  return (
    <div style={{background: '#ebebeb'}}>
        <div className="container py-5" >
            <div className="row">
                <div className="col-md-6">
                    <VideoPlayer videoId={videoId2}/>
                </div>
                <div className="col-md-6 d-flex flex-column px-5 text-center justify-content-center">
                    <h1 style={{color: '#001d6e'}}>VCIS</h1>
                    <p style={{lineHeight: '3rem'}}>Valoores is the leading provider of Business & Government Solutions, using Geospatial AI Analysis established in 1989 and operates in 17 countries on all continents.</p>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default Intro
