
const IncomeStatement = [
    {
      title: "Overview",
      desc:
      (
        <>
          In’Income Statement enables the user to drill down to the last leaf level while viewing past and present figures and having the ability to view forecasted positions. In’Income Statement organizes all of your accounting data in one place, making it easy to create an accurate income statement. The user is also capable of easily viewing their income statement and other financial reports anytime and from anywhere.
        </>
      ),
      list: [''],
    },
    {
      title: "benefits",
      desc: '',
      list:
       ([
           "Communicate strategy more effectively",
           "Create more frequent, accurate forecasts",
           "Publish financial reports faster",
           "Improve business process efficiency",
           "Information delivery: Offers efficient report distribution to a large and diverse user community",
        ]
        ),
      },
      {
        title: "features",
        desc:'',
        list:
        ([
         "Dynamic consolidation: Provides on-demand access to actual and planning data.",
         "Accounting logic: Supports multiple accounting standards and partial ownership consolidations",
         "Budgeting and planning: Supports rolling forecasts topdown and bottom-up budgeting and budgetcycle seeding",
         "Process management: Eases management of reporting and data entry forms with webbased workflow control.",
         "Data management: A prebuilt data model creates a consistent view and allows true integration with other solutions",
        ]),
    }
  ];
  
  export default IncomeStatement;
  