import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Documents() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [editedFile2, setEditedFile2] = useState(null);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editValue4, setEditValue4] = useState('');
  const [editValue5, setEditValue5] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  //FILES HANDLING
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFile2Change = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleFileEdit2 = (event) => {
    setEditedFile2(event.target.files[0]);
  };

  //INPUTS HANDLING
  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setEditValue3(e.target.value);
  };

  //EDITS HANDLING
  const handleClick = (value, value2, value3, value4, path, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(value3);
    setEditValue4(value4);
    setEditValue5(path);
    setIsEditModalOpen(true);
  };

  //SUBMITS HANDLING
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile && selectedFile2) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('file2', selectedFile2);
        const uploadResponse = await axios.post('http://localhost:8800/documents/upload', formData);
        const imagePath = uploadResponse.data.imagePath;
        const docPath = uploadResponse.data.docPath;

        const res = await axios.post('http://localhost:8800/documents/database', {
          input1: input1,
          input2: input2,
          input3: input3,
          imagePath: imagePath,
          docPath: docPath,
        });
      } else {
        console.log('Please select a file and an image.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8800/documents/show');
        setFetchedData(response.data);
        setIsDataFetched(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (delete_id, delete_rel_id, delete_path, doc_path) => {
    try {
      const response = await axios.delete('http://localhost:8800/documents/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
          doc_path: doc_path,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue5);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/documents/editImage', formData);
        const imagePath = uploadEditResponse.data.imagePath;
        console.log(imagePath);
      }
      if (editedFile2) {
        const formData = new FormData();
        formData.append('file2', editedFile2);
        formData.append('id', editId);
        formData.append('value', editValue);
        formData.append('value2', editValue2);
        formData.append('value3', editValue3);
        formData.append('original_path2', editValue4);

        const res = await axios.put('http://localhost:8800/documents/edit', formData);
        console.log('Value edited:', res.data);
      } else {
        const formData = new FormData();
        formData.append('id', editId);
        formData.append('value', editValue);
        formData.append('value2', editValue2);
        formData.append('value3', editValue3);

        const res = await axios.put('http://localhost:8800/documents/edit', formData);
        console.log('Value edited:', res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  return (
    <> 
      <div>
        <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
          <h1>Documents</h1>
            <button className="btn btn-primary" onClick={handleAddModalOpen}>
              Add Document
            </button>
          </div>
          {isDataFetched && (
            <div className="tableStyle">
              {/* The table wrapper div */}
                <table className="table">
                <thead className="sticky-header">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchedData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.doc_name}</td>
                      <td>{item.doc_desc}</td>
                      <td>{item.doc_type}</td>
                      <td>
                        <button
                          className="btn btn-secondary"
                          to="#"
                          onClick={() =>
                            handleClick(
                              item.doc_name,
                              item.doc_desc,
                              item.doc_type,
                              item.doc_path,
                              item.image_path,
                              item.id
                            )
                          }
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() =>
                            handleDelete(
                              item.id,
                              item.related_id,
                              item.image_path,
                              item.doc_path
                            )
                          }
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      {/* EDIT DOCUMENT MODAL */}
      <Modal
      isOpen={isEditModalOpen}
      onRequestClose={handleEditModalClose}
      contentLabel="Edit Modal"
      >
          <div className="">
            <div className="">
              <div className="">
                <form className='edit_form'>
                    <div>
                        <h5 className="pb-3 px-3">Edit Document</h5>
                    </div>
                    <div className=" px-4">
                      <div className="row mb-2">
                        <div className="col-md-6">
                            <label htmlFor="DocId" >Document ID</label>
                            <input
                                id="DocId"
                                type="text"
                                disabled
                                readOnly
                                value={editId}
                                placeholder="Doc Edit ID"
                                className='form-control my-3'
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="DocName">Document Name</label>
                            <input
                                id="DocName"
                                type="text"
                                value={editValue}
                                onChange={handleInputChange}
                                placeholder="Document Edit Name"
                                className='form-control my-3'
                            />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                            <div className="mb-4">
                                <label for="DocDesc">Document Description</label>
                                <input
                                    id="DocDesc"
                                    type="text"
                                    value={editValue2}
                                    onChange={handleInputChange2}
                                    placeholder="Doc Edit Desc"
                                    className='form-control my-3'
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <label htmlFor="DocType">Document Type</label>
                                <input
                                    id="DocType"
                                    type="text"
                                    value={editValue3}
                                    onChange={handleInputChange3}
                                    placeholder="Doc Edit type"
                                    className='form-control my-3'
                                />
                            </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-4">
                          <label htmlFor="DocFile">Document File</label>
                            <input id="DocFile" type="file" onChange={handleFileEdit2} className='form-control my-3'/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="DocImg">Image File</label>
                            <input id="DocImg" type="file" onChange={handleFileEdit} className='form-control my-3'/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="px-4 d-flex justify-content-end gap-3">
                        <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                        <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                    </div> 
                </form>
              </div>
            </div>
          </div>
      </Modal>

      {/* ADD DOCUMENT MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add Document</h5>
                </div>
                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addDocName">Document Name</label>
                      <input
                        id="addDocName"
                        type="text"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="Document Name"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addDocDesc">Document Description</label>
                      <input
                        id="addDocDesc"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="Document Description"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addDocType">Document Type</label>
                      <input
                        id="addDocType"
                        type="text"
                        value={input3}
                        onChange={(e) => setInput3(e.target.value)}
                        placeholder="Document Type"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addDocFile">Document File</label>
                        <input id="addDocFile" type="file" onChange={handleFile2Change} className='form-control my-3'/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="addDocImg">Image File</label>
                      <input id="addDocImg" type="file" onChange={handleFileChange} className='form-control my-3'/>
                    </div>
                  </div>
                </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                  <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Documents;
