
const inRetail = [
    {
      title: "Overview",
      desc:
      (
        <>
          In a highly competitive market, it is imperative for retailers to stay on-top of business processes in real-time.
          The global trend is to automate, optimize and secure companies' business processes, while giving retailers the opportunity to dedicate their efforts in executing unique business strategies to remain competitive in the global market. The first rule of any technology used in a business is that automation applied to an efficient operation will magnify the efficiency; the second is that automation applied to an inefficient operation will magnify the inefficiency.
          <br />
          <br />
          To help your company overcome the challenges they are facing to fully implement the automation of the business processes we have identified the following goals that have been ranked as top priorities to eliminate the obstacles.
          <br />
          <br />
          Obstacles to eliminate:      
          <br />
        </>
      ),
      list: [
      'Organizing simultaneous tasks',
      'Redundancy',
      'Identifying abnormal cases for working by exception',
      'Time to automate technical aspects of applications',
      'Limited triggers',
    ],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
          'Managing and covering the whole Business Process.',
          'Automating applications fast and reliably',
          'Creating predefined personalized scenarios.',
          'Offering a large choice of triggering events and actions.',
          'Being entirely based on the Business Model.',
        ],
      },
      {
        title: "Key features",
        desc:
        <>
        <ul>
          <li>
          Configuration:
            <ul>
              <li>Activities Definition.</li>
              <li>Organization in a Business Process.</li>
            </ul>
          </li>
          <li>
          Management:
            <ul>
              <li>Process can be simulated.</li>
              <li>Process can be canceled.</li>
              <li>Process can be Active.</li>
            </ul>
          </li>
          <li>Triggered Process tracking.</li>
          <li>
          Administration:
            <ul>
              <li>All parameters are configured in application.</li>
            </ul>
          </li>
        </ul>
        </>,
        list: [''],
      },
      
  ];
  
  export default inRetail;
  