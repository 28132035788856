import React from 'react';
import Carousel from './CustomCarousel';
import { Helmet } from 'react-helmet';

import Paralax from './Paralax';
import Application from '../../Pages/Banking/Application';
import Sections from './Sections'
import dashboardImg from '../../img/dashboardParalax.jpg';
import paralax2 from '../../img/paralax2.jpg';
import Introduction from './Introduction';
import Di3 from '../../img/Digital/hederahashgraph.jpg';
import Di2 from '../../img/Digital/framework01.png';
import SectionContainer from './SectionContainer';


const Digital = () => {
  const secondSectionD = (
    <>
      Digital transformation by the use of technology to radically improve performance or reach of enterprises – is becoming a hot topic for companies across the globe. Executives in all industries are using digital advances such as analytics, mobility, social media and smart embedded devices – and improving their use of traditional technologies to change customer relationships, internal processes, and value propositions. Other executives, seeing how fast digital technology disrupted media industries in the past decade, know they need to pay attention to changes in their industries now.

      VAOORES in’Digital Transformation initiatives are centered on re-envisioning customer experience, operational processes and business models. Companies are changing how functions work, redefining how functions interact, and even evolving the boundaries of the firm.
    </>
  );

  const thirdSectionD = (
    <>
      “Trust between Financial Services, Regulators, corporates, and Individuals is Fundamental. Be it trading, financial contracts, asserting a claim to a product across a supply chain, or sharing information. An entire industry of third-party intermediaries acting as payment processors, auditors, brokers, online marketplaces, and more has emerged from this need for trust. Despite good intentions, intermediaries are typically slow, costly, and manual. Distributed ledgers offer powerful means to establish Trust in an automated way. Hedera is a third generation distributed ledger, offering enterprise-grade performance, security, stability, and governance.”
    </>
  );

  const forthSectionD = (
    <>
      VALOORES is focused on finding ways to leverage innovative technologies to solve regulatory challenges, and to address areas such as regulatory compliance transformation, automation of complex reporting, and risk monitoring and analytics. However, the implications and benefits of Regtech stretch well beyond regulatory applications. For example, many companies are struggling with managing and financing legacy system transformation;<br />
      a critical element of long term growth and sustainability. But oftentimes the same solutions that can help address regulatory issues can also be used to support back-office transformation. Thus, the costs associated with the implementation and use of Regtech should not be evaluated simply by regulatory time/cost savings and risk reduction, but also by the other benefits that can be achieved.
    </>
  );

  return (
    <>
      <Helmet>
        <title>Business Intelligence</title>
      </Helmet>
      <Carousel />
      <Introduction />
      {/* <SectionContainer 
        mediaUrl="https://www.youtube.com/embed/71NGqxCw4Po?&autoplay=1"
        mediaType="video"
        backgroundColor=''
        layout=''
        action = 'section1'
      /> */}
      <SectionContainer 
        title="VALOORES in’Digital Transformation"
        description={secondSectionD}
        image={Di2}
        backgroundColor='#f1f1f1'
        layout=''
        link='https://digital.valoores.com/pdf/digital-transformation.pdf'
        action = 'section1'
      />
     <Sections />
      <SectionContainer 
        title="VALOORES Embraces Hedera Hashgraph for its Regulatory Compliance Life Cycle"
        description={thirdSectionD}
        image={Di3}
        backgroundColor=''
        layout='flex-row-reverse'
        link='https://digital.valoores.com/pdf/digital-transformation.pdf'
        action = 'section1'
      />
      <Paralax 
        img={dashboardImg} 
        title="Powerful Dashboard Visualizations" 
        link='https://Bi.valoores.com/portalbi.php' 
        button='Learn More' 
        laptop='true' 
        height='50rem' 
        top='0%' 
      />
     
     
      <SectionContainer 
        title="VALOORES Embraces Hedera Hashgraph for its Regulatory Compliance Life Cycle"
        description={forthSectionD}
        image={''}
        backgroundColor=''
        layout='flex-row-reverse'
        action = 'section2'
      />
    </>
  );
};

export default Digital;
