import React from 'react';
import Banner from '../Banner';
import BannerImg from '../../img/management/kyc_banner.jpg';
import Banner2Img from '../../img/management/kyc_banner1.jpg';
import KYCArray from './KYCArray';
import ParalaxCase from './ParalaxCase'; 

const CaseKYC = () => {
    return (
        <>
            <Banner
                img={BannerImg}
                title={
                    <div className='text-center'>
                        Know Your Crowd
                    </div>
                }
            />
            
            <div className="container kyc__container my-5">
                <div className="row slice slice-steps">
                    <div className="col-md-6">
                        <h1 className='title-bg'>Unlock the power of VCIS Know Your Customer</h1>
                        <div className="row" style={{lineHeight: '2.5rem'}}>
                            <div className="col-md-6">
                                <p className='title-bg_p'>From pain and missing digital process flows In 2022, reported fraud losses rose to $5.8 billion, an increase of more than 70 percent in a single year. One way to combat the rise in financial fraud and money laundering is to reduce anonymous bank accounts and monitor suspicious activity. For financial organizations, that means knowing who customers are and continuously monitoring for risk factors, a process called "know your customer. " While the programs to meet KYC</p>
                            </div>
                            <div className="col-md-6">
                                <p className='title-bg_p'>
                                    requirements are developed by individual organizations, financial institutions like banks, credit unions, and Fortune 500 financial firms, must comply with complex regulations to verify customer identity. This article explains what KYC requirements are in the U.S., and why KYC in banking matters.
                                    Failing to meet KYC regulations can mean steep fines, an increased risk of fraud, and reduced consumer trust, making KYC compliance critical to businesses in many industries.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-md-6 sm-col-offset-1">
                        <div className="steps-list">
                            <StepItem number="1" title="Risk Assessment for individuals, entities & associated parties" >
                                <p >Help meet your regulatory obligations, make informed decisions, and prevent your business being used to launder the proceeds of financial crime or associated with corrupt practices. KYC (Know Your Customer) is today a significant element in the fight against financial crime and money laundering, and customer identification is the most critical aspect as it is the first step to better perform in the other stages of the process.</p>
                            </StepItem>
                            <StepItem number="2" title="The global anti-money laundering(AML)/CFT">
                                <p>The global anti-money laundering (AML) and countering the financing of terrorism (CFT) landscape raise tremendous stakes for financial institutions.</p>
                            </StepItem>
                            <StepItem number="3" title="The Financial Action Task Force (FATF)">
                                <p>International regulations influenced by standards like The Financial Action Task Force (FATF) are now implemented in national laws encompassing strong directives like AML 4 and 5 and preventive measures like "KYC" for client identification.</p>
                            </StepItem>
                        </div>
                    </div>
                </div>
            </div>
            <ParalaxCase img={Banner2Img} />
            <div className="d-flex flex-row align-items-center flex-wrap justify-content-between gap-3">
                {KYCArray.map((CaseKYC, index) => (
                    <div key={index} className="d-flex flex-column justify-content-center align-items-center my-5">
                        {index % 2 === 0 ?
                            <div className=' px-5'>
                                <div className='mb-3 text-center'>
                                    <h2>{CaseKYC.title}</h2>
                                </div>
                                <div className=" d-flex flex-row justify-content-between gap-3 align-items-center">
                                    <div className='w-50 px-3'>
                                        <p className='column_2'>{CaseKYC.desc}</p>
                                    </div>
                                    <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                                        <img src={CaseKYC.img} className='w-85' alt={CaseKYC.title} />
                                    </div>
                                </div>
                            </div> :
                            <div className=''>
                                <div className='my-4 text-center'>
                                    <h2>{CaseKYC.title}</h2>
                                </div>
                                <div className=" d-flex flex-row justify-content-center align-items-center">
                                    <div className='w-50 d-flex flex-row align-items-center justify-content-center'>
                                        <img src={CaseKYC.img} className='w-85' alt={CaseKYC.title} />
                                    </div>
                                    <div className='w-50 px-3'>
                                        <p className="column_2">{CaseKYC.desc}</p>
                                    </div>
                                </div>
                            </div>}
                        {(index === -1 || index === 1) && <ParalaxCase img={Banner2Img} />}
                    </div>
                ))}
            </div>
        </>
    );
};

const StepItem = ({ number, title, children }) => {
    return (
        <div className="steps-list--item" style={{ display: 'flex', position: 'relative' }}>
            <div className="step-number step-number--green" style={{ border: '10px solid #2a75f8', borderRadius: '50%', flexShrink: 0, height: '5rem', marginRight: '12%', position: 'relative', width: '5rem', zIndex: 2, background:'#fff' }}>
                <span style={{ fontSize: '2.5rem', fontWeight: 600, left: '50%', lineHeight: 1, position: 'absolute', top: '50%', transform: 'translate(-50%,-50%)', zIndex: 3, color: '#2a75f8' }}>{number}</span>
            </div>
            <div>
                <h3 className="step-title mb-4 md:mb-6" style={{ color: '#002e33', fontSize: '2rem', fontWeight: 400, lineHeight: 1, marginTop: '1.0625rem' }}>{title}</h3>
                <div className="title-bg_p">{children}</div>
            </div>
            {number === '3' ? <div className={`steps-list--item-bg hidden`} style={{ height: '100%', left: 0, position: 'absolute', top: '1.5rem', width: '5rem', zIndex: -1 }}></div> : <div className='steps-list--item-bg' style={{ height: '100%', left: 0, position: 'absolute', top: '1.5rem', width: '5rem', zIndex: -1, backgroundColor: '#eff2f2' }}></div> }
        </div>
    );
};

export default CaseKYC;
