import BankingImg from '../../../assets/img/banking.jpg';
import GovernementImg from '../../../assets/img/goverment.jpg';
import RetailImg from '../../../assets/img/retail.jpg';

const sectionsArray = [
    {
        img: BankingImg,
        title: 'Banking, Finance & Insurance',
        desc: "VALOORES' software solutions empower banks, financial institutions, and insurers streamline operations, manage risks, and stay compliant. Our real-time intelligence tools seamlessly integrate with existing systems, turning complex data into insights for detecting suspicious activity, regulatory reporting, and financial analysis. Stay agile, optimize performance, and drive growth with our data-driven solutions designed for today's evolving regulatory landscape.",
    },
    {
        img: GovernementImg,
        title: 'Government & Public Sector',
        desc: "VALOORES' tailored solutions for government and public institutions simplify operations, boost transparency, and optimize service delivery. With advanced analytics and intelligent data tools, we turn complex data into actionable insights, empowering public sector organizations to efficiently manage resources, ensure compliance, and enhance public service outcomes.",
    },
    {
        img: RetailImg,
        title: 'Retail & Supply Chain',
        desc: "VALOORES' cutting-edge retail platform transforms your supply chain with powerful data analytics and automation. Seamlessly syncing with your ERP and inventory systems, it delivers real-time stock visibility and fully automates procurement. By supercharging inventory forecasting, slashing lead times, and enhancing demand planning, our platform drives efficiency, cuts costs, and powers smarter, faster decision-making—keeping your retail operations a step ahead.",
    }
]
export default sectionsArray;