import React, { useState} from 'react';
import Fade from 'react-reveal/Fade';
import { Link,  useLocation  } from 'react-router-dom';
import { CiShare1 } from 'react-icons/ci';
import { BiChevronsRight } from 'react-icons/bi';

const Content = (props) => {
  let [activeItem, setActiveItem] = useState('Key Benefits');
  let [activeItemA, setActiveItemA] = useState('Transaction');
  let [activeItemI, setActiveItemI] = useState('Transaction Monitoring');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const receivedVariable = searchParams.get('variable');
  let content, menuVariable, activeMenu, topMenu, overviewSec;

  const handleItemClick = (index) => {
    setActiveItem(index);
    setActiveItemA(index);
    setActiveItemI(index);
  };


  const AmlArray = ['Key Benefits', 'Key Features', 'Solution Preview'];
  const AmlMenu = ['Transaction', 'Reporting', 'Exploration', 'Processing', 'Management', 'Risk Based Approach'];
  const FraudMenu = ['Transaction Monitoring', 'Real-time scoring', 'Case Management', 'Single Fraud Platform', 'Case Studies', 'Customer Experience'];

  switch(receivedVariable) {

    case 'InDataGovernance':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InKYE':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InKYC':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InSupplierManagement':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InContact':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InFoundation':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InSourcing':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;
    case 'InProductManager':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

    case 'InOrganization':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

    case 'InProductManagement':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

    case 'InChanel':
        content = (
          <>
          
              {props.RiskArray.map((item, index) => (
                <>
                <Fade>
                <div className='py-5'>
                  <h6 style={{textTransform: 'uppercase'}}>{item.title}</h6>
                  {item.desc}
                  <ul>
                    {item.list != '' && 
                      item.list.map((itemList, idx) => (
                        <li key={idx}>{itemList}</li>
                      ))
                    }
                  </ul>
                    
                </div>
                </Fade>
                </>
              
              ))}
          </>
        );
    break;

          default:
            content=(
              <h1>Default</h1>
            )
        break;
      }


    
  return (
    <>
      {content}
    </>
  );
};

export default Content;
