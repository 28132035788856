
const InPricingManagement = [
    {
      title: "Overview",
      desc:
      (
        <>
            One of the most important aspects of companies operations is pricing, whether it is a product or a service that you’re intending to sell, choosing right price can make all the difference especially when it comes to financial institutions. Since the difference between success and failure, is often decided by the tightest of margins.
            <br />
            Our “in’Pricing” solution offers state of the art calculation techniques which delivers swift, accurate and reliable results. Being able to calculate and correctly price credit card fees, Loan interest and fees or any other service is considered a core banking operation where mistakes are not tolerated and there is no margin for error.
            <br />
            Using our Solution, organizations can have a better and easier pricing technique, granting them the ability to focus on other aspects that can be modified or optimized. Organizations tend to invest major resources when it comes to pricing process, Using our application will cut that process short and save the organizations from expenses and efforts which leads to attracting customers and grant companies the competitive edge that is vital in today’s market.
            <br />
        </>
      ),
      list: [''],
    },
    {
      title: "Key features",
      desc:'',
      list:
      [
        'Accurate and fast calculation results',
        'Flexible pricing tool covering all banking products and taking into',
        'Consideration of all related elements and actors',
        'User friendly interface where all the major work is done in the background',
        'Archived data which enable it to be used in future calculation process',
        'An accurate simulation tool enabling the users to predict and have an idea about the estimated prices and results',
          
      ],
    },
    {
      title: "Market Challenges",
      desc:'',
      list: 
      [
        'Too much time to run calculations and set interest rates and fees',
        'Discrepancies resulting from human errors or inaccurate data',
        'Missing one or more elements essential in pricing',
        'Allocating too much resources, especially for several products in need of pricing',
        'Long validation process',
        'No simulation process and lack of foreseeable results',
      ],
    }
  ];
  
  export default InPricingManagement;
  