import React from 'react'



const ParalaxCase = ({img}) => {
  return (
    <>
      <div className="cases__paralax " style={{backgroundImage: `url(${img})`,backgroundAttachment:'fixed'}}></div>
    </>
  )
}

export default ParalaxCase
