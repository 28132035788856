import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

// Set the app element for accessibility
Modal.setAppElement('#root');

function Clients() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [editedFile, setEditedFile] = useState(null);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [editValue, setEditValue] = useState('');
  const [editValue2, setEditValue2] = useState('');
  const [editValue3, setEditValue3] = useState('');
  const [editId, setEditId] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileEdit = (event) => {
    setEditedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const uploadResponse = await axios.post('http://localhost:8800/clients/upload', formData);
        const imagePath = uploadResponse.data.imagePath;
        const iconName = uploadResponse.data.iconName;

        const res = await axios.post('http://localhost:8800/clients/database', {
          input1: input1,
          input2: input2,
          imagePath: imagePath,
          iconName: iconName,
        });
      } else {
        console.log('Please select a file.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:8800/clients/show');
      setFetchedData(response.data);
      setIsDataFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (value, value2, path, id) => {
    setEditId(id);
    setEditValue(value);
    setEditValue2(value2);
    setEditValue3(path);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (delete_id, delete_rel_id, delete_path) => {
    try {
      const response = await axios.delete('http://localhost:8800/clients/deleted', {
        data: {
          delete_ids: delete_id,
          delete_rel_ids: delete_rel_id,
          delete_paths: delete_path,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setEditValue2(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editedFile) {
        const formData = new FormData();
        formData.append('file', editedFile);
        formData.append('original_path', editValue3);
        formData.append('id', editId);

        const uploadEditResponse = await axios.post('http://localhost:8800/clients/editIcon', formData);
        const imagePath = uploadEditResponse.data.imagePath;
        console.log(imagePath);

        const res = await axios.put('http://localhost:8800/clients/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
        });
        console.log('Value edited:', res.data);
      } else {
        const res = await axios.put('http://localhost:8800/clients/edit', {
          id: editId,
          value: editValue,
          value2: editValue2,
          value3: editValue3,
        });
        console.log('Value edited:', res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  return (
    <>

      <div className= "d-flex flex-row justify-content-between align-items-center container mb-4">
        <h1>Clients</h1>
        <button className="btn btn-primary" onClick={handleAddModalOpen}>
          Add Client
        </button>
      </div>
      {isDataFetched && (
        <div className="tableStyle">
          <table className="table">
            <thead className="sticky-header">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Link</th>
                <th>Icon Path</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {fetchedData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td className="truncate">{item.link}</td>
                  <td>{item.icon_path}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      to="#"
                      onClick={() => handleClick(item.name, item.link, item.icon_path, item.id)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => handleDelete(item.id, item.related_id, item.icon_path)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* EDIT CLIENT MODAL */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleEditModalClose}
        contentLabel="Edit Modal"
      >
          <div className="">
              <div className="">
                  <div className="">
                      <form className='edit_form'>
                          <div>
                            <h5 className="pb-3 px-3">Edit Client</h5>
                          </div>
                          <div className=" px-4">
                              <div className="row mb-2">
                                  <div className="col-md-6">
                                      <label htmlFor="ClientId" >Client ID</label>
                                      <input
                                        id="ClientId"
                                        type="text"
                                        disabled
                                        readOnly
                                        value={editId}
                                        placeholder="Client Edit ID"
                                        className='form-control my-3'
                                      />
                                  </div>
                                  <div className="col-md-6">
                                      <label htmlFor="ClientName">Client Name</label>
                                      <input
                                        id="ClientName"
                                        type="text"
                                        value={editValue}
                                        onChange={handleInputChange}
                                        placeholder="Client Edit Name"
                                        className='form-control my-3'
                                      />
                                  </div>
                              </div>

                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label for="ClientLink">Client Link</label>
                                          <input
                                            id="ClientLink"
                                            type="text"
                                            value={editValue2}
                                            onChange={handleInputChange2}
                                            placeholder="Client Link Edit"
                                            className='form-control my-3'
                                          />
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-4">
                                          <label htmlFor="ClientIcon">icon File</label>
                                          <input id="ClientIcon" type="file" onChange={handleFileEdit} 
                                          className='form-control my-3'/>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="px-4 d-flex justify-content-end gap-3">
                            <button className='btn btn-secondary' onClick={handleEditSubmit}>Submit Edit</button>
                            <button className='btn btn-danger' onClick={() => setIsEditModalOpen(false)}>Cancel</button>
                          </div> 
                      </form>
                  </div>
              </div>
          </div>
      </Modal>

      {/* ADD CLIENT MODAL */}
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalClose}
        contentLabel="Add Modal"
      >
        
        <div className="">
          <div className="">
            <div className="">
              <form className='add_form'>
                <div>
                  <h5 className="pb-3 px-3">Add Client</h5>
                </div>

                <div className=" px-4">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label htmlFor="addClientName">Client Name</label>
                      <input
                        id="addClientName"
                        type="text"
                        value={input1}
                        onChange={(e) => setInput1(e.target.value)}
                        placeholder="Client Name"
                        className='form-control my-3'
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="addClientLink">Client Link</label>
                      <input
                        id="addClientLink"
                        type="text"
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                        placeholder="Client Link"
                        className='form-control my-3'
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                      <div className="mb-4">
                        <label htmlFor="addClientIcon">Icon File</label>
                        <input id="addClientIcon" type="file" onChange={handleFileChange} className='form-control my-3'/>
                      </div>
                  </div>
                </div>
                <div className="px-4 d-flex justify-content-end gap-3">
                  <button className='btn btn-secondary' onClick={handleSubmit}>Submit</button>
                  <button className='btn btn-danger' onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Clients;
