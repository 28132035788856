import React from 'react';
import {Link} from 'react-router-dom'
import YouTube from 'react-youtube';
import Axios from '../../Components/indexTrial/database/banking_axios';
import ReactHtmlParser from 'react-html-parser';

const VideoPlayer = ({ videoId }) => {
  const opts = {
    height: '350',
    width: '100%',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      mute: 1,
      rel: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};
  
  const Introduction = () => {
    const fetchedSections = Axios({ path: '/banking_Sections' });

  const videoId = 'lRoRZGMc4cs';
  return (
    <>
    {fetchedSections.map((slide) => (
        <>
        {slide.type === "intro" &&
            <div className={slide.class}>
                <div className="row align-items-center">
                    <div className="col-md-6">
                    <div className = {slide.text_container}>
                        <VideoPlayer videoId={videoId}/>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className={slide.img_container}>
                    <h2>{ReactHtmlParser(slide.title)}</h2>
                    <p>{ReactHtmlParser(slide.description)}</p>
                        <div className="button_div">
                            <Link className="btn btn-show-sec" to={slide.link} target= '_blank'>Request A Demo</Link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        }
        </>
      ))}
    </>
  )
}

export default Introduction