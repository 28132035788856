

const InOrganization = [
    {
      title: "Overview",
      desc:
      (
        <>
      An effective structure facilitates management and clarifies relationships, roles and responsibilities, levels of authority, and supervisory or reporting lines. By reviewing an organization’s structure, a manager will be able to determine which human, financial, and technical resources are available, how they should be allocated, and which resources are lacking. Using an organogram — a graphic representation of an organization’s structure — a manager will be able to define tasks, determine information flow within the organization, and ensure accountability for achieving organizational goals and objectives.
        </>
      ),
      list: [''],
    },
  
  ];
  
  export default InOrganization;
  