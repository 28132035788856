import React, { useEffect, useRef, useState } from 'react'
import Banner from './Banner'
import serviceContent from './serviceContent';
import services from './itemservice';

const ServicesGroup = () => {
  const [selectedService, setSelectedService] = useState({
    title: '', description: '', backgroundImage: '', herotext: ''
  });
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const serviceParagraphsRef = useRef(null);
    
  useEffect(() => {
    if (serviceContent.length > 0) {
      const firstService = serviceContent[0];
      setSelectedServiceId(firstService.id);
      setSelectedService({
        title: firstService.title,
        description: firstService.description,
        backgroundImage: firstService.backgroundImage,
        herotext: firstService.herotext,
      });
    }
  }, []);

  const handleServiceClick = (serviceId) => {
    setSelectedServiceId(serviceId);
    const selected = serviceContent.find((service) => service.id === serviceId);
    if (selected) {
      setSelectedService({
        title: selected.title,
        description: selected.description,
        backgroundImage: selected.backgroundImage,
        herotext: selected.herotext,
      });
    }
    
    // Scroll to the serviceParagraphs section
    serviceParagraphsRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div>
        <Banner selectedService = {selectedService} setSelectedService={setSelectedService} handleServiceClick = {handleServiceClick} selectedServiceId={selectedServiceId} services = {services}/>
        <div className="d-flex flex-column text-left mt-4 p-3 container" ref={serviceParagraphsRef}>
          <h2 >{selectedService.title}</h2>
          <p>{selectedService.description}</p>
      </div>
    </div>
  )
}

export default ServicesGroup