const InCard = [
    {
      overview: "VALOORES in'Cards Merchant is a 24/7 comprehensive and modular card ,POS and ATM management system that supports multiple card types and can grow with a business, allowing a bank to quickly set up the thousands of parameters that drive a card, POS and ATM system, and quickly launch new products with innovative features. Helping manage multiple relationships with cardholders, the solution enables banks to track customers with multiple accounts and cards of different types. It also supports the running of loyalty programs to promote card usage and drive retention. This solution is workflow-enabled and with its case management tool provides easy access for smooth, efficient and fast resolution of operations management.",
      benefits: [
        ["Portfolio Risk Analysis: Customer Balance Movement, Over-limit Indicator." , "Cross Sell and Loyalty: New Product suggestions, Reward by Card Type.", "Sales Representatives Performance: Target Realization per Branch.","Delinquencies: Default Patterns, Late Payments." , "Boost revenues: Grow card usage by increasing confidence in security, building card/brand loyalty, and providing faster access to balance information.", "Customer Acquisition Analysis: Acquisition Trend, Inactivity Rate.","Access rich records of every customer transaction." , "Deliver more value to top customers and identify new acquisition opportunities.", "Identify opportunities to improve queues, wait times and transaction sequences.", "Analyze transaction mix profitability by channel, device segment and down to individual self-service locations.", "Improve the placement and service offerings of various devices and banking applications.","Manage cash positions for each device, and assess the impact of any failures or outages." , "Perform on-demand reporting and ad hoc queries.", "Continuously update and refresh your customer transaction data.", "Blend transaction data with complementary data such as BIN ranges, demographics or competitive information."]
      ],
      features: [
        ["Graphical and customized interface that displays your current business position and status in terms of sales performance, credit quality, revenues.", "Alerts and signals as a pro-active approach, such as alert that immediately notifies you if certain incident occurred (threshold alert, approval rate per employee, ratio of cards rejection.", "Reports that allow you to access cardholder information (to monthly activation reports and profitability analysis reports…). Our reliable reports help you manage cardholders and your portfolio in order to achieve ultimate performance."]
      ]
    },
  ] 

  export default InCard