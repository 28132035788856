import React from 'react';
import './Hero.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fraud from '../../../assets/vids/fraud1.mp4'
import Fade from 'react-awesome-reveal';


const Hero = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings} className='hero-container'>
        <div className='container d-flex align-items-center justify-content-lg-start justify-content-md-center text-lg-start text-md-center w-100 text-white'>
            <div className='hero-container__content d-flex flex-column align-items-lg-start align-items-md-center'>
              <Fade cascade >
                <h1 className='text-white'>You have the VALUE,<br />
                we bring you the added<br/> VALUE</h1>
                <h2 className='mt-5 fw-light w-lg-50 w-md-100 text-white'>VALOORES is one of The Leading provider in Enterprise Business Solutions and Business Intelligence Decision Making.</h2>
              </Fade>
            </div>
            <div className="overlay"></div>
            <div className="position-absolute turning_globe" >
                <video className='video_section1' src={Fraud} muted loop autoPlay/>
            </div>  
        </div>
        <div style={{ backgroundColor: '#000', height: '900px' }}>
            <h3>2</h3>
        </div>
    </Slider>
  );
};

export default Hero;
