const baselCapital = [
    {
        title: "Overview",
        desc: "VALOORES Financial Services in'Basel Regulatory Capital is an out-of-the-box, scalable solution covering Pillar I requirements across Basel II and Basel III guidelines including RWA, Capital Ratios, Capital Buffers, Leverage Ratio, CVA computations, and supplementary leverage ratio of the bank.",
        list: [
            "Single, unified data model and infrastructure",
            "Complete and pre-built solution covering requirements across Basel II and Basel III",
            "Pre-built, unified analytical reporting environment with comprehensive coverage",
            "Out-of-the-box, pre-configured reports and templates",
            "Ensures compliance through a transparent, complete audit trail for supervisory review",
        ],
    },
    {
        title: "Key Benefits",
        desc: '',
        list:
        [
            "Quickly react to regulatory updates in multiple jurisdictions",
            "Meet regulatory audit requirements & quickly uptake regulatory changes",
            "Obtain a complete, analytical view of regulatory capital",
            "Provide one version of the analytical 'truth' to business users",
            "Gain a complete understanding of the bank's capital, risk, and liquidity position",
        ]
    },
    {
        title: "Key Features",
        desc: '',
        list:
        [
            "Single, unified data model and infrastructure",
            "Complete and pre-built solution covering requirements across Basel II and Basel III",
            "Complies with standardized and advanced approaches and risk data aggregation, with full auditability",
            "Pre-built, unified analytical reporting environment with comprehensive coverage",
            "Out-of-the-box, pre-configured reports and templates",
            "Ensures compliance through a transparent and complete audit trail for supervisory review",
            "Comprehensive modeling framework",
        ]
    },
]

export default baselCapital;