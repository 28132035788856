import React from 'react'
import RetailImg from '../../img/ai/retailImg.jpg'
import Fade from 'react-reveal/Fade';

const retail_list = [
    {
        item: 
        <>
            <span className='fw-bold'>Personalized Marketing:</span> AI analyzes data to suggest tailored product recommendations, boosting sales and fostering loyalty
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Seamless Support:</span> AI chatbots offer instant assistance, improving response times and enhancing the customer experience.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Efficient Inventory:</span> AI forecasts demand, automates replenishment, and minimizes stockouts, reducing costs and improving efficiency.
        </>
    },
    {
        item: 
        <>
            <span className='fw-bold'>Fraud Detection:</span> AI swiftly identifies and prevents fraud, protecting customers and maintaining trust.
        </>
    },
]
const Retail = () => {
  return (
    <>
        <div className="container my-5">
            <div className="row">
                <Fade left>
                <div className="col-md-6 retail_bg" style={{backgroundImage : `url(${RetailImg})`}}>
                </div>
                </Fade>
                <div className="col-md-6">
                    <Fade right>
                        <div className='mb-3'>
                            <h1 className='mb-3'>AI-Driven Retail Solutions: Efficiency, Personalization, and Security</h1>
                            <p>Our AI-powered retail solutions revolutionize the industry by enhancing efficiency, personalizing marketing strategies, providing seamless customer support, optimizing inventory management, and detecting fraudulent activities.</p>
                        </div>
                    </Fade>
                    <ul className='p-0' style={{listStyle: "none"}}>
                        {retail_list.map((list, index) => (
                            <Fade>
                                <li className='mb-2' key={index}>{list.item}</li>
                            </Fade>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default Retail