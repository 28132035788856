import kyc1 from '../../img/management/kyc1.jpg'
import kyc2 from '../../img/management/kyc2.jpg'
import kyc3 from '../../img/management/kyc3.jpg'
import kyc4 from '../../img/management/kyc4.jpg'
import kyc5 from '../../img/management/kyc5.jpg'

const KYCArray=[

    {
        title:'Why is the KYC process important?',
        desc:'KYC procedures defined by banks involve all the necessary actions to ensure their customers are real, assess, and monitor risks. These client-onboarding processes help prevent and identify money laundering, terrorism financing, and other illegal corruption schemes. KYC process includes ID card verification, face verification, document verification such as utility bills as proof of address, and biometric verification. Banks must comply with KYC regulations and anti-money laundering regulations to limit fraud. KYC compliance responsibility rests with the banks. In case of failure to comply, heavy penalties can be applied.eKYC also refers to capturing information from IDs (OCR mode), the extraction of digital data from government-issued smart IDs (with a chip) with a physical presence, or the use of certified digital identities and facial recognition for online identity verification.',
        img: kyc1 ,
    },
    {
        title:'Customer onboarding can then be done via mobile.eKYC is considered more and more feasible as its accuracy is improving by utilizing Artificial Intelligence (AI).',
        desc:[
            (
            <div>
               
The KYC policy is a mandatory framework for banks and financial institutions used for the customer identification process. Its origin stems from the 2001 Title III of the Patriot Act to provide various tools to prevent terrorist activities. To comply with international regulations against money laundering and terrorist financing, reinforced Know Your Customer procedures need to be implemented in the first stage of any business relationship when enrolling a new customer.<br/>
Banks usually frame their KYC policies incorporating the following four key elements:
                    <ul className='list-disk'>
                    <li> Customer Policy</li>
                    <li>Customer Identification Procedures (data collection, identification, verification, politically exposed person/sanctions lists check) aka Customer Identification Program (CIP)</li>
                    <li>Risk assessment and management (due diligence, part of the KYC process)</li>
                    <li>Ongoing monitoring and record-keeping</li>
                    </ul>
                </div>
            )
        ],
        img: kyc2 ,
    },
    {
        title:'From visual ID check to digital verification',
        desc:[
            (
            <div>
                For some, this is still primarily a paper-based check with KYC forms to fill. For others, it's a digital process that involves verifying that an identity document is genuine or even going further to authenticate the document holder through additional biometric checks such as facial or fingerprint checks. A digital ID verification process enables a bank to automatically capture customer demographic data, which can be integrated into enterprise systems like CRM to:
                    <ul className='list-disk'>
                    <li> Streamline the customer onboarding process,</li>
                    <li>Conduct further due diligence and risk assessment,</li>
                    <li>Review for PEPs (Politically Exposed Persons).</li>
                    </ul>
                    Financial institutions must also maintain records on transactions and Information obtained through the Customer Due Diligence measures. These requirements should apply to all new customers and existing customers based on materiality and risk. Financial institutions must also maintain records on transactions and Information obtained through the Customer Due Diligence measures. These requirements should apply to all new customers and existing customers based on materiality and risk.
                </div>
            )
        ],
        img: kyc3,
    },
    {
        title:[
            (
            <div>

            Enhanced Due Diligence (aka EDD) is a KYC process that implements a more profound analysis to provide higher identity assurance<br/>
                    Financial Crime vs KYC: What’s the difference?
                </div>
            )
        ],
        desc:'The difference between Financial Crimes (anti-money laundering, Fraud, Terrorism finance) and KYC (Know Your Customer) is that FC refers to the framework of legislation and regulation financial institutions must follow to prevent money laundering. The KYC process is a key part of the overall AML framework and specifically requires organizations to know who they do business with and verify customer identity.Financial institutions are responsible for developing their own KYC processes. However, AML legislation can vary by jurisdiction or country, which means financial institutions must establish KYC procedures that comply with each set of AML standards',
        img: kyc4 ,
    },
    {
        title:[
            (
            <div>
               
               eKYC – Online Customer Onboarding<br/>
               and Identity Verification
                </div>
            )
        ],
        desc:'The difference between Financial Crimes (anti-money laundering, Fraud, Terrorism finance) and KYC (Know Your Customer) is that FC refers to the framework of legislation and regulation financial institutions must follow to prevent money laundering. The KYC process is a key part of the overall AML framework and specifically requires organizations to know who they do business with and verify customer identity. Financial institutions are responsible for developing their own KYC processes. However, AML legislation can vary by jurisdiction or country, which means financial institutions must establish KYC procedures that comply with each set of AML standards',
        img: kyc5 ,
    },
    
]

export default KYCArray;