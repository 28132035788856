const testimonials = [
    {
        title: 'Raed H. Charafeddine, First Vice-Governor at Banque du Liban',
        paragraph: '“Thank you so very much for the heart and mind that you have put into my website. It is vivid in every detail of its work. This is not only my opinion, it is that of a communications expert. Mr Ramsay Najjar, a friend who happened to talk to me yesterday.”',
    },
    {
        title: 'Ramsay G. Najjar, Strategic Communication Consultancy',
        paragraph: '“Congratulations on the revamped website! We enjoyed browsing it! Overall, the website is modern in its look and feel, whereby you succeeded in adding dynamism, as we previously recommended. Indeed, the selected design is reflective and reminiscent of news websites and blogs that are consulted daily by users. When it comes to content, the categorization of information makes it easier for the user to access the content and enjoy it.”',
    },
    {
        title: 'Khaled Bohsali, Director at Banque Du LIBAN',
        paragraph: '“I would like to thank you for the first iteration of Riad Salamé’s website. The user can easily feel the touch of excellence through the smooth colors and the attractive design.”',
    },
]

export default testimonials;