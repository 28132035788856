import InKernel1 from '../../../../img/inkernel/inKernel1.jpg'
import InKernel2 from '../../../../img/inkernel/inKernel2.jpg'
import InKernel3 from '../../../../img/inkernel/inKernel3.jpg'
import InKernel4 from '../../../../img/inkernel/inKernel4.jpg'
import InKernel5 from '../../../../img/inkernel/inKernel5.jpg'

const InKernel = [
    {
      img: InKernel1,
      title: "in'System",
      desc: 
      (
        <>
          Managers and Administrators worldwide are always looking for ways to increase productivity and minimize the margin of error and the time effort.
          <br /> in'System is one of many tools under our VALOORES core suite,...
        </>
      ),
      link: "../banking/inKernel?variable=inSystem",
      button: 'yes'
    },
    {
      img: InKernel2,
      title: "in'Workflow",
      desc:
      (
        <>
          In a highly competitive market, it is imperative for retailers to stay on-top of business processes in real-time.
          <br /> The global trend is to automate, optimize and secure...
        </>
      ),
      link: "../banking/inKernel?variable=inWorkflow",
      button: 'yes'
    },
    {
      img: InKernel3,
      title: "in'Multilanguage",
      desc: "In our global economy, companies are expanding every day, and it's crucial that your company extends its reach; as a result each company needs to implement their own language...",
      link: "../banking/inKernel?variable=inMultilanguage",
      button: 'yes'
    },
    {
      img: InKernel4,
      title: "in'Reporting",
      desc: "With VALOORES powerful analytics and intuitive web interface, banks' personnel can create insightful reports that help making data-driven decisions that ultimately have a positive impact on their bottom line....",
      link: "../banking/inKernel?variable=inReporting",
      button: 'yes'
    },
    {
      img: InKernel5,
      title: "in'Integration",
      desc: "VALOORES in' Integration is a comprehensive data integration platform that covers all data integration requirements: from high-volume, high-performance batch loads, to event-driven, trickle-feed...",
      link: "../banking/inKernel?variable=inIntegration",
      button: 'yes'
    },
  ]

  export default InKernel