import AI from '../../../assets/img/ai.jpg'
import BigData from '../../../assets/img/bigdata.jpg'
import Geospatial from '../../../assets/img/geospatial.jpg'

const SectionsArray = [
    {
        img: BigData,
        title: "An Advanced Approach",
        desc: "for Decision Making using the latest Data Analytics and Business Intelligence models",
    },
    {
        img: AI,
        title: "A Revolutionary Integration",
        desc: "of the latest Artificial Intelligence, Machine Learning, and Cloud Computing technology",
    },
    {
        img: Geospatial,
        title: "A Dynamic Approach",
        desc: "for customer identification, verification and assessment via the power of Biometrics.",
    },
]

export default SectionsArray;