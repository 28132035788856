import React from 'react'
// import {Link} from 'react-router-dom'
// import Fade from 'react-reveal/Fade';
import Fade from 'react-reveal/Fade';
// import Zoom from 'react-reveal/Zoom';
import {RiSingleQuotesL, RiSingleQuotesR} from 'react-icons/ri'
import Aml1 from '../../../img/Aml/aml1.jpg';
import Kyc from '../../../img/Aml/Kyc.jpg';
import Screening from '../../../img/Aml/Screening.jpg';
import RiskAssessment from '../../../img/Aml/RiskAssessment.jpg';
import TransactionMonitoring from '../../../img/Aml/TransactionMonitoring.jpg';
import Regulatory from '../../../img/Aml/Regulatory.jpg';
import Demo1 from '../../../img/Aml/demo1.jpg';
import { Link } from 'react-router-dom';


const dataRole = [
  {
    title: 'KYC',
    desc: 'A powerful, adaptable tool that streamlines customer data collection, identification and  verification. Ensures compliance with international and local regulations across all financial services providers, from banks to crypto platforms.',
    img: Kyc,
  },
  {
    title: 'SCREENING',
    desc: 'Real-time protection with advanced name matching and automated retroactive screening, ensuring compliance with evolving regulations, allowing seamless investigation and analysis of potential hits, integrating easily with any system.',
    img: Screening,
  },
  {
    title: 'Customer Risk Assessment per a Risk-Based Approach (RBA)',
    desc: 'Dynamic, real-time assessment of AML/CFT risks enabling detection of unusual patterns such as transaction velocity, collusion, and dark web suspicions. A sophisticated logic adaptable to evolving threats, while allowing risk-level overrides to align with regulatory demands.',
    img: RiskAssessment,
  },
  {
    title: 'TRANSACTION MONITORING',
    desc: 'An advanced risk-scoring and AI-driven model enabling detection of  suspicious activities in real-time, offering flexible customization of rules and parameters.  With AI capabilities, our solution  continuously refines risk assessments, reduces false positives and ensures accurate alerts.',
    img: TransactionMonitoring,
  },
  {
    title: 'REGULATORY REPORTING',
    desc: 'Offers customizable templates, multi-jurisdictional support and advanced submission models for seamless compliance. With cloud deployment, smart analytics, and strong data governance, our solution enables automation, cost reduction, and powerful reporting.',
    img: Regulatory,
  },
]

const Retail = () => { 



  return (
    <>
    {/* Introduction */}
      <div className="retail__content-all mx-5 mt-5">
        <h4>
          Fintechs, crypto exchanges, virtual assets service providers, payment service providers, e-wallet providers and more… <br/>
        </h4>
        <h2 className='fst-italic text-center'>
          <RiSingleQuotesL />
          VFDS is adaptable to any financial products, services & customer profilesExplore our KYC, Screening, Transaction Monitoring and Customer Risk Assessment
          <RiSingleQuotesR />
        </h2>
        <hr className='my-5' style={{opacity: '0.2', height: '0.3rem', backgroundColor: '#000'}}/>
        <div className='row my-5'>
          <div className="col-md-6">
            <h1 className='mb-3'>What Sets Us Apart</h1>
            <ul className='d-flex flex-column gap-1' style={{fontSize: '1.32rem'}}>
              <li>An advanced approach for Decision Making using the latest <span className='fw-bold'>Data Analytics</span> and <span className='fw-bold'>Business Intelligence</span> models</li>
              <li>A revolutionary integration of the latest <span className='fw-bold'>Artificial Intelligence, Machine Learning, and Cloud Computing</span> technology</li>
              <li>A dynamic approach for customer identification, verification and assessment via the power of <span className='fw-bold'>Biometrics</span></li>
            </ul>
          </div>
          <div className="col-md-6">
            <img src={Aml1} alt="" className='w-100' />
            {/* <div className='w-100' style={{backgroundImage: `url(${Aml1})`, height:'auto', backgroundSize: 'cover', backgroundPosition: 'center center'}}></div> */}
          </div>
        </div>
          <hr className='my-5' style={{opacity: '0.2', height: '0.3rem', backgroundColor: '#000'}}/>
          <div className='text-center w-100 my-5'>
            <h1 className='mb-4'>COMPLIANCE</h1>  
            <h3 style={{lineHeight: '3.5rem'}}>VALOORES' groundbreaking compliance softwares fuses real-time monitoring, AI-driven insights, and automated reporting, revolutionizing how your business navigates evolving regulations while leveraging the latest tech to slash risk and supercharge efficiency.</h3>
          </div>
          <hr className='my-5' style={{opacity: '0.2', height: '0.3rem', backgroundColor: '#000'}}/>
          <h1 className='my-5 text-center'>SOLUTIONS</h1>
          <div className="dataRole_section d-flex flex-column">
            {dataRole.map((data, index) => (
              <div key={index} className={`dataRole_section-part my-5 d-flex justify-content-between ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                {index % 2 === 0 ? <Fade left>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade> : <Fade right>{<div className="p-4 w-40 dataRole_section-part_img" style={{backgroundImage : `url(${data.img})`, flex : '2'}}></div>}</Fade>}
              {/* <div className="seperator"></div> */}
              <div className="p-4 w-40 my-3" style={{ flex : '2'}}>
                <h3 className='fw-bold'>{data.title}</h3>
                <h4 style={{lineHeight: '2.5rem'}}>{data.desc}</h4>
              </div>
            </div>
            ))}
          </div>
          <hr className='my-5' style={{opacity: '0.2', height: '0.3rem', backgroundColor: '#000'}}/>
          <div className='my-5 border d-flex flex-row align-items-center' style={{height: '20rem', borderRadius: '25px', boxShadow: '0 0rem 3rem rgba(0, 0, 0, 0.2)'}}>
              <div className="px-5 d-flex flex-column align-items-start gap-3"  style={{width: '65%'}}>
                <h1>Ready to Improve Your Online<br/>  Customer Experience?</h1>
                <div className='d-flex flex-row align-items-center gap-3' style={{width: '90%'}}>
                  <input type="text" className='form-control' placeholder='Enter Your Email...' style={{width: '65%'}}/>
                  <Link className='w-auto btn btn-primary' style={{width: '35%'}}> Request A Demo</Link>
                </div>
              </div>
              <div></div>
                <div className='position-relative' style={{width: '35%', height: '100%', backgroundImage: `url(${Demo1})`, backgroundPosition: 'center center', backgroundSize: 'cover', zIndex: '2'}}>
                  <div className=' position-absolute right-0 h-100 w-100' style={{background: "linear-gradient(to bottom, transparent, #1d5d8994)", zIndex: '4'}}></div>
                </div>
          </div>
      </div>
    </>
  )
}

export default Retail