

const InFoundation = [
    {
      title: "Overview",
      desc:
      (
        <>
      In an effort to effectively address regulatory and competitive pressures, financial institutions are turning to sophisticated analytical systems for business decision making. All business lines including Marketing, Risk and Finance need specialized analytical platforms that address unique needs of each business, but at the same time analytical outputs cannot be disconnected from cross-functional business process needs. Implementing analytical systems that drive line of business needs while catering to the enterprise cross-functional analytical needs is extremely challenging.
      <br/>
      The VALOORES in'Data Foundation allows institutions to quickly implement specialized Finance, Risk & Marketing analytical applications in order to drive line of business control and performance. Cross-functional needs are supported by leveraging the unified and conformed reporting data model with proven coverage from real-world use in analytics. Complex data sourcing needs are addressed out-of the box by the comprehensive data platform that manages sourcing and provisioning for all VALOORES Analytical Applications.
      <br/>
      When coupled with VALOORES Exadata infrastructure, the VALOORES in'Data Foundation helps line of business heads and business aligned IT departments drive business satisfaction to even greater levels, with unprecedented performance, responsiveness, scalability and manageability.
        </>
      ),
      list: [''],
    },
    {
        title: "Key Benefits",
        desc: ' ',
        list: [
            "Fully supports all key analytical use cases for Financial Services in Risk, Compliance, Customer Insight and Performance Management.",
            "Immediately deploy new and advanced analytics with unprecedented ease.",
            "Simplify ETL and achieve the shortest possible load times and batch windows",
           " Provide the fastest possible query performance across all business domains",
           " Eliminate accuracy and consistency issues with contextual data quality checks",
           " Eliminate inconsistencies across ledgers, books and marts for confident and auditable reporting",
          "  Provide the capability to handle highvolume, what-if computations across all business domains to support",
          "  Easily meet emerging or changing cross-functional business and regulatory mandates",
           " Reduce the complexity and cost of maintaining multiple data marts",
           " Enables consistency, transparency and auditability across all analytical domains",
          "  Ensures compliance with BIS Principles for effective risk data aggregation and risk reporting",
          
        ],
      },
      {
        title: "Key features",
        desc:'',
        list:
        [
           "End-use driven and pre-defined physical data model for data sourcing and reporting.",
           "Proven, unified and comprehensive data model for reporting and business intelligence based on deep domain experience in Global Tier I institutions",
          " Conformed results data model allows results from multiple business areas to be combined, enabling cross-functional analytics throughout the enterprise.",
          " Common staging area architecture.",
          " Thousands of pre-built data quality checks contextualized to end use",
          " Fully integrated capabilities for data quality, metadata and reconciliation with GL",
          " Enables sophisticated analytical capabilities",
          " Open, extensible platform also supports external analytical engines, applications and models developed internally or from third parties",
            
        ],
      },
    
  ];
  
  export default InFoundation;
  